import * as React from 'react';
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import {
	Box,
	Button,
	Icon,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../../../state/globalState';
import serviceFactory from '../../../../services/serviceFactory';
import LoadingPopup, { LoadingPopupProps } from '../../../../popups/loadingPopup/LoadingPopup';
import { WebUtils } from '../../../../utils/utils';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import { TestKey } from '../FlightTestSection';
import { FlightFileData } from '../../../../services/calibration/ICalibrationService';
import colors from '../../../../themes/colors.scss?export';
import { Link } from '@redskytech/framework/996';
import { ITestCriteria } from '../../../../utils/testCriteria';

interface LoadFlightLogProps {
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	FLIGHT_LOG_DATA = 'flightLogDataUploaded'
}

const LoadFlightLog: React.FC<LoadFlightLogProps> = (props) => {
	const TEST_NAME: TestKey = 'loadFlightLog';
	const testerShimStatus = useRecoilValue(globalState.testerShimStatus);
	const [flightTestFileList, setFlightTestFileList] = useRecoilState<FlightFileData[] | undefined>(
		globalState.flightTestFileData
	);
	const socketioService = serviceFactory.get('SocketioService');
	const testFixtureService = serviceFactory.get('TestFixtureService');
	const [loadingPopupId, setLoadingPopupId] = useState<number | undefined>(undefined);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [testResults, setTestResults] = useRecoilState<TestResult[]>(globalState.testResults);
	const [terminatedTestCode, setTerminatedTestCode] = useRecoilState<1 | 2 | undefined>(
		globalState.terminatedTestCode
	);
	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);

	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.FLIGHT_LOG_DATA, res ? res.data[FormKeys.FLIGHT_LOG_DATA] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			])
		]);
	}, [res]);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	useEffect(() => {
		const dataGeneratedResult = testResults.find((res) => res.testName === 'generateData');
		if (dataGeneratedResult) {
			if (isSubmitted) {
				setIsSubmitted(false);
				if (loadingPopupId) {
					popupController.closeById(loadingPopupId);
					setLoadingPopupId(undefined);
				}
				if (dataGeneratedResult.passed) {
					const control = formGroup.clone().get(FormKeys.FLIGHT_LOG_DATA);
					control.value = true;
					setFormGroup(formGroup.clone().update(control));
					testFixtureService.validateFlightTest(
						formGroup,
						TEST_NAME,
						undefined,
						undefined,
						props.testCriteria
					);
				} else {
					rsToastify.error(
						`Error processing flight log data. ${JSON.stringify(dataGeneratedResult.error)}`,
						'Error Processing FlightLog'
					);
				}
			}
		}
	}, [testResults, props.testCriteria]);

	useEffect(() => {
		if (!terminatedTestCode && terminatedTestCode !== 1) return;
		if (loadingPopupId) {
			popupController.closeById(loadingPopupId);
			setLoadingPopupId(undefined);
		}
		setTestResults((prev) => {
			return [
				...prev,
				{
					testName: 'generateData',
					passed: false,
					continuable: true,
					timeStamp: new Date().toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
						hour12: true
					}),
					error: 'Error: Script failure.'
				}
			];
		});
		setTerminatedTestCode(undefined);
	}, [terminatedTestCode]);

	function startScript(file: File) {
		if (testerShimStatus.status !== 'CONNECTED_DATA') {
			rsToastify.error('Tester shim is not connected.', 'Tester Shim Not Connected');
			if (fileInput.current) {
				fileInput.current.value = '';
			}
			return;
		}
		try {
			setLoadingPopupId(popupController.open<LoadingPopupProps>(LoadingPopup, {}));
			socketioService.runFlightTestScript(file);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Error Generating Data');
			if (fileInput.current) {
				fileInput.current.value = '';
			}
			popupController.close(LoadingPopup);
			setLoadingPopupId(undefined);
		}
	}

	const fileInput = useRef<HTMLInputElement | null>(null);

	function handleFileInput(e: React.ChangeEvent<HTMLInputElement>) {
		if (!e.target.files) return;
		if (e.target.files.length === 0) {
			// if this was empty then the user cancelled
			return;
		}
		const file = e.target.files[0];
		startScript(file);
		// clear the file input, this resets the onchange
		// event so that we can use the same file twice
		e.target.value = '';
		setIsSubmitted(true);
	}

	function handleFileLaunch(file: FlightFileData) {
		if (testerShimStatus.status !== 'CONNECTED_DATA') {
			rsToastify.error('Tester shim is not connected.', 'Tester Shim Not Connected');
			return;
		}
		socketioService.launchFlightLogTab(file.filePath);
	}

	function handlePruneFlightLogList(file: FlightFileData) {
		setFlightTestFileList((prev) => {
			return prev?.filter((f) => f.fileName !== file.fileName);
		});
	}

	function getFlightLogFileElements() {
		return flightTestFileList?.map((file, index) => {
			if (!file.fileName.match(/\.(txt|log|blog|json|html)$/)) {
				return null;
			}
			return (
				<Box key={`${file.fileName}_${index}`} display={'flex'} alignItems={'center'} gap={8}>
					<Link
						path={file.filePath}
						onClick={() => {
							handleFileLaunch(file);
						}}
					>
						<Box flexGrow={1}>{file.fileName}</Box>
					</Link>
					<Icon
						iconImg={'icon-delete'}
						color={colors.accentError}
						cursorPointer={true}
						onClick={() => {
							handlePruneFlightLogList(file);
						}}
					/>
				</Box>
			);
		});
	}

	return (
		<Box className={'rsLoadFlightLog'}>
			<input
				ref={fileInput}
				type={'file'}
				style={{ display: 'none' }}
				accept={'.txt, .log, .blog, .json'}
				onChange={handleFileInput}
			/>

			<Button
				look={'containedPrimary'}
				onClick={() => {
					fileInput.current?.click();
				}}
				mt={16}
			>
				Add Flight Log
			</Button>
			<Box mt={16}>
				<Box display={'flex'} flexDirection={'column'} gap={8}>
					{getFlightLogFileElements()}
				</Box>
			</Box>
		</Box>
	);
};
export default LoadFlightLog;
