import { Service } from '../Service';
import globalState, { getRecoilExternalValue, setRecoilExternalValue } from '../../state/globalState';
import { KitResult } from './IKitService';
import { RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import { WebUtils } from '@redskytech/framework/utils';
import { HardwareIdDecoded } from '../assembly/AssemblyService';

interface AuditSummary {
	auditErrorCount: number;
	auditErrors: string[];
	testErrorCount: number;
	testErrors: string[];
}

export default class KitService extends Service {
	async validateKitCheckout(formGroup: RsFormGroup, assemblyKey: HardwareIdDecoded, auditResults: AuditSummary) {
		const _controls = formGroup.getControls();

		const prevResult = getRecoilExternalValue(globalState.kitResults).findIndex(
			(prevRes) =>
				prevRes.HWID.partNumber === assemblyKey.partNumber &&
				prevRes.HWID.serialNumber === assemblyKey.serialNumber &&
				prevRes.HWID.hardwareRevision === assemblyKey.hardwareRevision
		);

		const isValid = await formGroup.isValid(true);
		setRecoilExternalValue(globalState.kitResults, (prevState) => {
			const updatedState = [...prevState];
			if (prevResult < 0) {
				const result: KitResult = {
					HWID: assemblyKey,
					data: formGroup.toModel(),
					timeStamp: new Date().toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
						hour12: true
					}),
					auditErrorCount: auditResults.auditErrorCount,
					auditErrors: auditResults.auditErrors,
					testErrorCount: auditResults.testErrorCount,
					testErrors: auditResults.testErrors,
					completed: isValid
				};
				return [...updatedState, result];
			}
			updatedState[prevResult] = {
				...updatedState[prevResult],
				data: formGroup.toModel(),
				timeStamp: new Date().toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
					hour12: true
				}),
				auditErrorCount: auditResults.auditErrorCount,
				auditErrors: auditResults.auditErrors,
				testErrorCount: auditResults.testErrorCount,
				testErrors: auditResults.testErrors,
				completed: isValid
			};
			return updatedState;
		});
	}

	async lookupKitByNumber(kitNumber: string): Promise<Api.V1.Part.By.Numbers.Get.Res | undefined> {
		try {
			// TODO: Add query here.
			console.log('KitService.lookupKitByNumber', kitNumber);
			//return await ApiRequestV1.getKitByNumber({
			//	kitNumber
			//});
		} catch (e: any) {
			if (e.response?.status === 404) return undefined;
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error looking up part by numbers'), 'Lookup Error');
		}
		return undefined;
	}
}
