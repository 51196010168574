import * as React from 'react';
import { useState } from 'react';
import { Page } from '@redskytech/framework/996';
import './MagTestPage.scss';
import type { HardwareIdDecoded } from '../../services/assembly/AssemblyService.js';
import AssemblyTypeSelectorSection from '../assemblePage/sections/AssemblyTypeSelectorSection.js';
import { rsToastify } from '@redskytech/framework/ui/index.js';
import { vehiclePartNumbers } from '../../services/assembly/assembly.data.js';
import MagTestSection from './MagTestSection.js';
import { ApiRequestV1 } from '../../generated/apiRequests.js';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MagTestPageProps {}

const MagTestPage: React.FC<MagTestPageProps> = (_props) => {
	const [hwid, setHwid] = useState<HardwareIdDecoded | undefined>();

	function renderMagTestSection() {
		if (!hwid) {
			return (
				<AssemblyTypeSelectorSection
					onAssemblyTypeSelected={async (assemblyType, hwid) => {
						if (!vehiclePartNumbers.includes(hwid.partNumber)) {
							rsToastify.error('Part number is not a vehicle type.', 'Invalid Part Number');
							return;
						}

						// Lets see if the part exists
						try {
							await ApiRequestV1.getPartByNumbers({
								partNumber: hwid.partNumber,
								serialNumber: hwid.serialNumber
							});
						} catch (error) {
							rsToastify.error('Part not found.', 'Invalid Part Number');
							return;
						}

						setHwid(hwid);
					}}
					pageTitle={'Magnetometer Test'}
					labelText={'Scan the hardware identifier of the assembly you want to test.'}
				/>
			);
		}

		return <MagTestSection hwid={hwid} onDone={() => setHwid(undefined)} />;
	}

	return <Page className={'rsMagTestPage'}>{renderMagTestSection()}</Page>;
};
export default MagTestPage;
