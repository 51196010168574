import * as React from 'react';
import './AuditPopup.scss';
import { Box, Button, Label, Popup, popupController, PopupProps } from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import { AuditErrorSection } from '../../components/auditBanner/AuditBanner';

export interface AuditPopupProps extends PopupProps {
	title: string;
	onConfirm: () => void;
	auditErrors: AuditErrorSection[];
	descriptor?: string;
	message?: string;
	onCancel?: () => void;
	confirmButtonText?: string;
	closeButtonText?: string;
	disableAutoClose?: boolean;
}

const ConfirmPopup: React.FC<AuditPopupProps> = (props) => {
	const fullProps = popupController.convertProps(props);

	function handleCancel() {
		if (props.onCancel) props.onCancel();
		if (!props.disableAutoClose) popupController.closeById(fullProps.popupId);
	}

	function handleConfirm() {
		if (props.onConfirm) props.onConfirm();
		if (!props.disableAutoClose) popupController.closeById(fullProps.popupId);
	}

	function renderAuditErrors(auditErrors: AuditErrorSection[]) {
		return [...auditErrors].map((auditError) => {
			return (
				<Box key={Math.random() * 1000}>
					<Label variant={'body1'} weight={'bold'}>
						{auditError.group} {props.descriptor && `${props.descriptor}`}
					</Label>
					<ul className={'bulletList'}>
						{auditError.errors.map((error, index) => (
							<li key={index}>
								<Label variant={'body1'} weight={'regular'}>
									{error}
								</Label>
							</li>
						))}
					</ul>

					{/* {auditError.errors.join(', ')} */}
				</Box>
			);
		});
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsAuditPopup'}>
				<PopupHeader title={props.title} onClose={handleCancel} />
				<Box className={'container'}>{renderAuditErrors(props.auditErrors)}</Box>
				{props.message && (
					<>
						<div className={'dividerLine'}></div>
						<Label variant={'body1'} weight={'bold'}>
							{props.message}
						</Label>
					</>
				)}
				<Box display={'flex'} gap={16} mt={24}>
					<Button look={'outlinedPrimary'} onClick={handleCancel} fullWidth>
						{props.closeButtonText || 'Cancel'}
					</Button>
					<Button look={'containedPrimary'} onClick={handleConfirm} fullWidth>
						{props.confirmButtonText || 'Confirm'}
					</Button>
				</Box>
			</Box>
		</Popup>
	);
};
export default ConfirmPopup;
