import * as React from 'react';
import './VideoTestPage.scss';
import { Page } from '@redskytech/framework/996';
import { Box, Button, rsToastify } from '@redskytech/framework/ui';
import { useState } from 'react';
import RtspVideoPlayer from '../../components/rtspVideoPlayer/RtspVideoPlayer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface VideoTestPageProps {}

const VideoTestPage: React.FC<VideoTestPageProps> = (_props) => {
	const [playVideo1, setPlayVideo1] = useState<boolean>(false);
	const [playVideo2, setPlayVideo2] = useState<boolean>(false);

	function handleDisconnectedVideo1() {
		setPlayVideo1(false);
		rsToastify.error('Video 1 was disconnected or unable to connected', 'Error Playing Video');
	}

	function handleDisconnectedVideo2() {
		setPlayVideo2(false);
		rsToastify.error('Video 2 was disconnected or unable to connected', 'Error Playing Video');
	}

	return (
		<Page className={'rsVideoTestPage'}>
			<Box display={'flex'} gap={16}>
				<Button look={'containedPrimary'} onClick={() => setPlayVideo1(true)} disabled={playVideo1}>
					Start Video 1
				</Button>
				<Button look={'containedPrimary'} onClick={() => setPlayVideo1(false)} disabled={!playVideo1}>
					Stop Video 1
				</Button>
				<Button look={'containedPrimary'} onClick={() => setPlayVideo2(true)} disabled={playVideo2}>
					Start Video 2
				</Button>
				<Button look={'containedPrimary'} onClick={() => setPlayVideo2(false)} disabled={!playVideo2}>
					Stop Video 2
				</Button>
			</Box>

			<Box display={'flex'} gap={32} mt={32}>
				<RtspVideoPlayer
					url={'rtsp://10.50.15.1:8554/stream'}
					play={playVideo1}
					onDisconnected={handleDisconnectedVideo1}
					onClick={(x, y) => {
						console.log('Video 1 clicked at', x, y);
					}}
					xRes={500}
					yRes={350}
				/>
				<RtspVideoPlayer
					url={'rtsp://127.0.0.1:8554/video0'}
					play={playVideo2}
					onDisconnected={handleDisconnectedVideo2}
					onClick={(x, y) => {
						console.log('Video 2 clicked at', x, y);
					}}
					xRes={500}
					yRes={350}
				/>
			</Box>
		</Page>
	);
};

export default VideoTestPage;
