import { RsDataTable, RsDataTableProps } from '@redskytech/framework/ui';
import * as React from 'react';

export type GroupDataTablesProps = RsDataTableProps<any>;

const GroupDataTable: React.FC<GroupDataTablesProps> = (props) => {
	const { data } = props.data;

	const [expandedRows, setExpandedRows] = React.useState<any>([]);

	return (
		<RsDataTable
			className="rsGroupDataTable"
			{...props}
			value={data}
			expandedRows={expandedRows}
			onRowToggle={(e) => setExpandedRows(e.data)}
		>
			{props.children}
		</RsDataTable>
	);
};

export default GroupDataTable;
