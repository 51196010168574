import * as React from 'react';
import './AssemblyAuditCheck.scss';
import { Box, Icon, IconProps, popupController, rsToastify } from '@redskytech/framework/ui';
import { useEffect, useState } from 'react';
import colors from '../../themes/colors.scss?export';
import useIsMounted from '../../customHooks/useIsMounted';
import AuditBanner, { AuditErrorSection } from '../auditBanner/AuditBanner';
import AuditPopup, { AuditPopupProps } from '../../popups/auditPopup/AuditPopup';
import {
	AssemblyAuditResult,
	AssemblyTreeTestAuditResult,
	processPartFamilyTreeAudit
} from '../../utils/generateAuditList';
import { StringUtils } from '../../utils/utils';
export interface AssemblyAuditCheckProps {
	partId: number;
	testAuditData?: AssemblyTreeTestAuditResult;
	mode: 'NOTICE' | 'POPUP' | 'BOTH' | 'ICON' | 'ICON_SUMMARY';
	auditStats?: (value: number, errorList: string[]) => void;
}

export type AuditStatus = 'ERROR' | 'SUCCESS' | 'WARNING' | 'INFO' | 'PENDING';

export const AuditIconMap: { [key in AuditStatus]: IconProps } = {
	ERROR: { iconImg: 'icon-fail', color: colors.accentErrorDark },
	SUCCESS: { iconImg: 'icon-check-circle', color: colors.accentSuccess },
	WARNING: { iconImg: 'icon-warning', color: colors.accentWarning },
	INFO: { iconImg: 'icon-info', color: colors.accentInfo },
	PENDING: { iconImg: 'icon-pending', color: colors.neutralGrey700 }
};

const AssemblyAuditCheck: React.FC<AssemblyAuditCheckProps> = (props) => {
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const [clearPopup, setClearPopup] = useState<boolean>(false);
	const [popupOpenSelected, setPopupOpenSelected] = useState<boolean>(false);
	const isMounted = useIsMounted();
	const [iconLevel, setIconLevel] = useState<AuditStatus>('PENDING');
	const [testAuditData, setTestAuditData] = React.useState<AssemblyTreeTestAuditResult | undefined>(undefined);

	useEffect(() => {
		if (!testAuditData) return;
		if (!props.auditStats) return;
		let incompleteAssemblyList: string[] = [];
		testAuditData.assemblyInfo.forEach((assemblyInfo) => {
			incompleteAssemblyList = [...incompleteAssemblyList, ...assemblyInfo.missingAssemblies];
		});
		props.auditStats(incompleteAssemblyList.length, incompleteAssemblyList);
	}, [testAuditData]);

	useEffect(() => {
		// recursive function to check the assembly of a part
		if (props.partId <= 0) return;

		async function checkAssembly(partIdToCheck: number) {
			const partAudit = await processPartFamilyTreeAudit(partIdToCheck);
			if (!partAudit) {
				rsToastify.error('Error getting part audit information', 'Error');
				return;
			}
			setTestAuditData(partAudit);
		}

		if (props.testAuditData) {
			setTestAuditData(props.testAuditData);
		} else {
			checkAssembly(props.partId);
		}
	}, [isMounted, props.partId]);

	useEffect(() => {
		if (!testAuditData) return;
		if (testAuditData.allAssembliesPresent || clearPopup) return;
		if (['NOTICE', 'ICON', 'ICON_SUMMARY'].includes(props.mode)) return;
		const popupId = popupController.open<AuditPopupProps>(AuditPopup, {
			title: 'Assembly Incomplete',
			descriptor: 'Missing',
			auditErrors: createMessageFromAuditList(testAuditData.assemblyInfo),
			message: `Do you still want to test this assembly?`,
			confirmButtonText: 'Continue',
			closeButtonText: 'Cancel',
			onConfirm: () => {
				setClearPopup(true);
			},
			onCancel: () => {
				setClearPopup(true);
				window.open('/assembly/details?pi=' + props.partId, '_blank');
			}
		});
		return () => {
			if (popupId) popupController.closeById(popupId);
		};
	}, [clearPopup, testAuditData, props.mode]);

	useEffect(() => {
		if (!testAuditData) return;
		if (testAuditData.allAssembliesPresent && testAuditData.assemblyIssueCount === 0) setIconLevel('SUCCESS');
		else if (testAuditData.assemblyIssueCount > 0) setIconLevel('ERROR');
		else setIconLevel('PENDING');
	}, [testAuditData]);

	useEffect(() => {
		if (!testAuditData) return;
		if (!testAuditData.allAssembliesPresent || !popupOpenSelected) return;
		if (['NOTICE', 'POPUP'].includes(props.mode)) return;
		const popupId = popupController.open<AuditPopupProps>(AuditPopup, {
			title: 'Assembly Incomplete',
			auditErrors: createMessageFromAuditList(testAuditData.assemblyInfo),
			message: `Select View Details to go to the Assembly Page.`,
			confirmButtonText: 'View Details',
			closeButtonText: 'Cancel',
			onConfirm: () => {
				setPopupOpenSelected(false);
				window.open('/assembly/details?pi=' + props.partId, '_blank');
			},
			onCancel: () => {
				setPopupOpenSelected(false);
			}
		});
		return () => {
			if (popupId) popupController.closeById(popupId);
		};
	}, [popupOpenSelected, testAuditData, props.mode]);

	function createMessageFromAuditList(auditList: AssemblyAuditResult[] | undefined) {
		if (!auditList) return [];
		const outputObjects: AuditErrorSection[] = [];
		for (const audit of auditList) {
			const newoutputObject: AuditErrorSection = { group: audit.assemblyName, errors: [] };
			if (audit.missingAssemblies.length > 0) {
				audit.missingAssemblies.forEach((assembly) => {
					newoutputObject.errors.push(StringUtils.convertCamelCaseToHuman(assembly));
				});
			}

			if (newoutputObject.errors.length > 0) outputObjects.push(newoutputObject);
		}

		return outputObjects.reverse();
	}

	if (props.mode === 'NOTICE' || props.mode === 'BOTH') {
		return (
			<Box className={'rsAssemblyAuditCheck'}>
				{testAuditData && !testAuditData.allAssembliesPresent && (
					<AuditBanner
						level={'warn'}
						buttons={[]}
						disableAudio={true}
						disabled={false}
						id={'auditCheck'}
						position={0}
						text={`Missing Children:`}
						auditErrors={createMessageFromAuditList(testAuditData.assemblyInfo)}
					/>
				)}
			</Box>
		);
	} else if (props.mode === 'POPUP' && testAuditData && !testAuditData.allAssembliesPresent && !clearPopup) {
		return <Box className={'rsAssemblyAuditCheck'} />;
	} else if (props.mode === 'ICON') {
		return (
			<Box className={'rsAssemblyAuditCheck'}>
				<Icon {...AuditIconMap[iconLevel]} />
			</Box>
		);
	}
	if (props.mode === 'ICON_SUMMARY') {
		return (
			<Box
				className={'rsAssemblyAuditCheck'}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => {
					setIsHovered(false);
				}}
				onClick={() => {
					if (testAuditData && testAuditData?.assemblyIssueCount > 0) {
						setPopupOpenSelected(true);
					}
				}}
			>
				<Icon
					cursorPointer={testAuditData && testAuditData?.assemblyIssueCount > 0}
					{...AuditIconMap[iconLevel]}
				/>
				{testAuditData && testAuditData?.assemblyIssueCount ? `${testAuditData?.assemblyIssueCount}` : ''}
				{isHovered && testAuditData && testAuditData?.assemblyIssueCount > 0 && (
					<Box className={'toolTip'}>
						<Box className={'container'}>
							<AuditBanner
								level={'warn'}
								buttons={[]}
								disableAudio={true}
								disabled={false}
								id={'auditCheck'}
								position={0}
								text={`Missing Children:`}
								auditErrors={createMessageFromAuditList(testAuditData.assemblyInfo)}
							/>
						</Box>
					</Box>
				)}
			</Box>
		);
	} else return <Box className={'rsAssemblyAuditCheck'} />;
};

export default AssemblyAuditCheck;
