import { Box, Button, Label, Paper, Popup, PopupProps } from '@redskytech/framework/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import KitCheckoutPdf from '../../components/kitCheckoutPdf/KitCheckoutPdf';

import AssemblyService, { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import { KitGroupType } from '../../services/kit/IKitService';
import { AuditTestResultSummary, processPartFamilyTreeAudit } from '../../utils/generateAuditList';
import { generatePdf } from '../../utils/pdfUtil';
import { StringUtils } from '../../utils/utils';
import './PdfPopup.scss';

export interface PdfPopupProps extends PopupProps {
	summaryData: { group: KitGroupType; idList: HardwareIdDecoded[] }[];
	serialNumber: string;
	salesOrder: string;
}

const PdfPopup: React.FC<PdfPopupProps> = (props) => {
	const assemblyService = new AssemblyService();
	const [groupedSummaryData, setGroupedSummaryData] = useState<
		{ testGroup: AuditTestResultSummary[]; name: string; group?: KitGroupType; idList?: HardwareIdDecoded[] }[]
	>([]);

	useEffect(() => {
		(async function fetchSummaryData() {
			const summaries: {
				testGroup: AuditTestResultSummary[];
				name: string;
				group?: KitGroupType;
				idList?: HardwareIdDecoded[];
			}[] = [];
			if (!props.summaryData) return;

			for (const group of props.summaryData) {
				const groupTypeOptions: Partial<KitGroupType>[] = ['TABLET', 'FCU_ANTENNA', 'PAYLOAD', 'VEHICLE'];

				if (groupTypeOptions.includes(group.group)) {
					await Promise.all(
						group.idList.map(async (item) => {
							if (!item.partId) return;

							const familyTreeAuditResult = await processPartFamilyTreeAudit(item.partId);

							if (!familyTreeAuditResult) return;

							// Accumulate all of the summary data items from the family tree audit result tests into a single array
							let firstItem = true;
							for (const subassemblyItem of familyTreeAuditResult.testInfo) {
								const summaryData = [];
								for (const testEvent of subassemblyItem.testInfo) {
									// sanitize the test names to be human readable
									testEvent.tests.forEach((test) => {
										test.testName = StringUtils.convertCamelCaseToHuman(test.testName);
									});

									summaryData.push({
										group: testEvent.group,
										tests: testEvent.tests
									});
								}

								if (firstItem) {
									// Show the parent assembly
									summaries.push({
										name: `${
											subassemblyItem.assemblyPartNumber
										} - ${assemblyService.getLabelFromPartNumber(
											subassemblyItem.assemblyPartNumber
										)}, ${subassemblyItem.assemblySerialNumber}`,
										testGroup: [...summaryData]
									});
								} else {
									// Mark this as a subassembly item
									summaries.push({
										name: `${item.partNumber}:${item.serialNumber} Subassembly ${subassemblyItem.assemblyPartNumber}:${subassemblyItem.assemblySerialNumber}`,
										testGroup: [...summaryData]
									});
								}

								firstItem = false;
							}
						})
					);
				}
			}
			setGroupedSummaryData(summaries);
		})();
	}, [props.summaryData]);

	return (
		<Popup {...props}>
			<Paper className="rsPdfPopup">
				<Box display="flex" justifyContent="space-between" borderBottom={'1px solid black'}>
					<Label variant="h1" weight="bold">
						Kit Checkout
					</Label>
					<Button
						look="containedPrimary"
						onClick={() => {
							generatePdf(
								document.querySelector('.rsKitCheckoutPdf') as HTMLElement,
								props.serialNumber,
								groupedSummaryData
							);
						}}
					>
						Download PDF
					</Button>
				</Box>
				<KitCheckoutPdf
					serialNumber={props.serialNumber}
					salesOrder={props.salesOrder}
					summaries={groupedSummaryData}
					kitCheckoutGroup={props.summaryData}
				/>
			</Paper>
		</Popup>
	);
};

export default PdfPopup;
