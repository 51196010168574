import * as React from 'react';
import './FlightTestSection.scss';
import FcuGpsValidation from './flightTests/FcuGpsValidation';
import RadioRangeTesting from './flightTests/RadioRangeTesting';
import FcuCameraInspection from './flightTests/FcuCameraInspection';

import FlightTestCriteria from '../flightTestCriteria/fcuFlightTestCriteria.json';
import { extractTestCriteriaList } from '../../../utils/testCriteria';

export type FcuPreFlightTestKey = 'cameraInspection' | 'gpsValidation';

export type FcuFlightTestKey = 'radioRangeTesting';

export type FcuTestKey = FcuPreFlightTestKey | FcuFlightTestKey;

const fcuTestKeys: FcuTestKey[] = ['cameraInspection', 'gpsValidation', 'radioRangeTesting'];

type AssertAllKeys<T extends readonly string[]> = T[number] extends FcuTestKey ? true : never;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const assertAllKeys: AssertAllKeys<typeof fcuTestKeys> = true;

export { fcuTestKeys };

export const fcuPreFlightTestData: { [key in FcuPreFlightTestKey]: JSX.Element } = {
	cameraInspection: (
		<FcuCameraInspection testCriteria={extractTestCriteriaList(FlightTestCriteria, 'CameraInspection')} />
	),
	gpsValidation: <FcuGpsValidation testCriteria={extractTestCriteriaList(FlightTestCriteria, 'GpsValidation')} />
};

export const fcuInFlightTestData: { [key in FcuFlightTestKey]: JSX.Element } = {
	radioRangeTesting: (
		<RadioRangeTesting testCriteria={extractTestCriteriaList(FlightTestCriteria, 'RadioRangeTesting')} />
	)
};

export const fcuFlightTestData = {
	...fcuPreFlightTestData,
	...fcuInFlightTestData
};

// Adjust the flightTestData object to include the appropriate test list based on the current HWID
