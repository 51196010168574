import { AxiosError } from 'axios';
import { HttpClient } from '@redskytech/framework/utils';
import serviceFactory from '../services/serviceFactory';
import { AuthTokens } from '../services/user/UserService';
import { rsToastify } from '@redskytech/framework/ui';
import { WebUtils } from './utils';
import globalState, { getRecoilExternalValue } from '../state/globalState';

export enum HttpStatusCode {
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	METHOD_NOT_ALLOWED = 405,
	ALREADY_EXISTS = 409,
	CONFLICT = 409,
	VERSION_OUT_OF_DATE = 418, // Technically this is the I'm a teapot code that was a joke.
	SERVER_ERROR = 500,
	SERVICE_UNAVAILABLE = 503,
	NETWORK_CONNECT_TIMEOUT = 599
}

const http = new HttpClient({
	baseURL: '/api/v1',
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		Accept: 'application/json, text/plain, */*',
		'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT, PATCH'
	}
});

const reAuthCheckIgnoredUrls = ['/user/re-auth'];

function requiresReAuthorization(authTokens: AuthTokens): boolean {
	// Check if token is about to expire within the next 5 minutes
	const tokenExpiresOn = new Date(authTokens.tokenExpiresOn);
	const now = new Date();
	const fiveMinutes = 5 * 60 * 1000;
	return tokenExpiresOn.getTime() - now.getTime() < fiveMinutes;
}

http.addRequestIntercepter(async (requestConfig) => {
	const authTokens = getRecoilExternalValue<AuthTokens | undefined>(globalState.authTokens);
	if (
		authTokens &&
		!reAuthCheckIgnoredUrls.includes(requestConfig.url || '') &&
		requiresReAuthorization(authTokens)
	) {
		console.log('Re-authenticating...before sending request to: ' + requestConfig.url || '');
		// Token has expired, try to refresh it
		try {
			const userService = serviceFactory.get('UserService');
			const newAuthTokens = await userService.refreshToken(authTokens);
			requestConfig.headers!['x-auth-token'] = newAuthTokens.token;
		} catch (e) {
			console.error(e);
		}
		console.log('Finished re-authenticating...');
	} else if (authTokens) {
		requestConfig.headers!['x-auth-token'] = authTokens.token;
	}

	return requestConfig;
});

http.addResponseIntercepter(
	(response) => {
		return response;
	},
	(error) => {
		if (error.isAxiosError) {
			const axiosError = error as AxiosError;
			if (axiosError.response) {
				if (
					axiosError.response.status === HttpStatusCode.UNAUTHORIZED ||
					axiosError.response.status === HttpStatusCode.FORBIDDEN
				) {
					const userService = serviceFactory.get('UserService');
					userService.logout();
				} else if (axiosError.response.status === HttpStatusCode.SERVER_ERROR) {
					rsToastify.error(WebUtils.getRsErrorMessage(axiosError, 'Unknown Error'), 'Server Error');
				}
			}
		}
		throw error;
	}
);

export default http;
