import { Box, popupController, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import { Column } from 'primereact/column';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import TableSearchHeader from '../../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import StatusChip from '../../../components/statusChip/StatusChip';
import useDataTableFilters from '../../../customHooks/useDataTableFilters';
import useIsMounted from '../../../customHooks/useIsMounted';
import TestResultsPopup, { TestResultsPopupProps } from '../../../popups/testResultsPopup/TestResultsPopup';
import serviceFactory from '../../../services/serviceFactory';
import { DateUtils, StringUtils, WebUtils } from '../../../utils/utils';
import './TestSummaryTable.scss';
import GroupDataTable from '../../../components/groupDataTable/GroupDataTable';
import { AssemblyTreeTestAuditResult, processPartFamilyTreeAudit } from '../../../utils/generateAuditList';

interface TestSummaryTableProps {
	partId: number;
	testAuditData?: AssemblyTreeTestAuditResult;
	partNumber: string | undefined;
	customHeader?: string;
}

const FieldKeys = {
	NAME: 'testResult.testName',
	CREATED_ON: 'testResult.createdOn',
	STATUS: 'testResult.status'
};

export interface SummaryData {
	testName: string;
	status: 'PASS' | 'FAIL' | 'INCOMPLETE';
	createdOn: string;
	testResultId: number;
}

const TestSummaryTable: React.FC<TestSummaryTableProps> = (props) => {
	const [groupedSummaryData, setGroupedSummaryData] = useState<any[]>([]);
	const [currentPageQuery, setCurrentPageQuery] = useState<RedSky.PageQuery>({
		page: 1,
		perPage: 100
	});
	const assemblyService = serviceFactory.get('AssemblyService');
	const initialFilters: DataTableFilterMeta = {};
	const initialFiltersFormGroup: RsFormGroup = new RsFormGroup([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { globalSearch } = useDataTableFilters(initialFilters, initialFiltersFormGroup, getUpdatedFilters);
	const isMounted = useIsMounted();

	useEffect(() => {
		if (!props.partId || !props.partNumber) return;

		getData(currentPageQuery).catch(console.error);
	}, [props.partId, props.partNumber]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	function getUpdatedFilters(field: string, value: any) {
		return {};
	}

	async function getData(pageQuery: RedSky.PageQuery): Promise<void> {
		setCurrentPageQuery(pageQuery);
		try {
			if (!isMounted || !props.partId || !props.partNumber) return;
			let familyTreeAuditResult: AssemblyTreeTestAuditResult | undefined = undefined;
			if (props.testAuditData) {
				familyTreeAuditResult = props.testAuditData;
			} else {
				familyTreeAuditResult = await processPartFamilyTreeAudit(props.partId);
			}
			if (!familyTreeAuditResult) return;
			const summaries: any[] = [];
			// Accumulate all of the summary data items from the family tree audit result tests into a single array
			const firstItem = true;

			// The first item in the return is the item itself
			const subassemblyItem = familyTreeAuditResult.testInfo[0];
			const summaryData = [];
			if (subassemblyItem) {
				for (const testEvent of subassemblyItem.testInfo) {
					// sanitize the test names to be human readable
					testEvent.tests.forEach((test) => {
						test.testName = StringUtils.convertCamelCaseToHuman(test.testName);
					});

					summaryData.push({
						group: testEvent.group,
						tests: testEvent.tests
					});
				}

				if (pageQuery.sortBy && pageQuery.sortOrder) {
					for (const summary of summaryData) {
						summary.tests.sort((a, b) => {
							if (pageQuery.sortBy === FieldKeys.NAME) {
								return pageQuery.sortOrder === 'ASC'
									? a.testName.localeCompare(b.testName)
									: b.testName.localeCompare(a.testName);
							}
							if (pageQuery.sortBy === FieldKeys.CREATED_ON) {
								return pageQuery.sortOrder === 'ASC'
									? a.createdOn.localeCompare(b.createdOn)
									: b.createdOn.localeCompare(a.createdOn);
							}
							if (pageQuery.sortBy === FieldKeys.STATUS) {
								return pageQuery.sortOrder === 'ASC'
									? a.status.localeCompare(b.status)
									: b.status.localeCompare(a.status);
							}
							return 0;
						});
					}
				}

				if (firstItem) {
					// Show the parent assembly
					summaries.push({
						name: `${subassemblyItem.assemblyPartNumber} - ${assemblyService.getLabelFromPartNumber(
							subassemblyItem.assemblyPartNumber
						)}, ${subassemblyItem.assemblySerialNumber}`,
						testGroup: [...summaryData]
					});
				}
			}
			setGroupedSummaryData(summaryData);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function handleShowDetails(summaryData: SummaryData) {
		popupController.open<TestResultsPopupProps>(TestResultsPopup, {
			testName: summaryData.testName,
			testResultId: summaryData.testResultId
		});
	}

	function rowExpansionTemplate(data: any) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { partId, partNumber, ...filteredProps } = props;
		return (
			//Has to be DataTable to work with Expansion. For some reason RsDataTable doesn't work with Expansion - Alex
			<DataTable {...filteredProps} value={data.tests}>
				<Column field="testName" header="Test Name" body={(testResult: SummaryData) => testResult.testName} />
				<Column
					sortable
					field="createdOn"
					header="Created On"
					body={(summaryData: SummaryData) => {
						if (!summaryData.createdOn) return 'N/A';
						return (
							<Box className="clickableDate" onClick={() => handleShowDetails(summaryData)}>
								{DateUtils.displayDate(summaryData.createdOn)}
							</Box>
						);
					}}
				/>
				<Column
					sortable
					field="status"
					header="Status"
					body={(summaryData: SummaryData) => {
						if (summaryData.status === 'PASS') return <StatusChip status={'SUCCESS'} />;
						if (summaryData.status === 'FAIL') return <StatusChip status={'ERROR'} />;
						return <StatusChip status={'PENDING'} />;
					}}
				/>
			</DataTable>
		);
	}

	return (
		<Box className={'rsTestSummaryTable'}>
			<GroupDataTable
				data={{ data: groupedSummaryData, total: groupedSummaryData.length }}
				getData={getData}
				rowExpansionTemplate={rowExpansionTemplate}
				header={<TableSearchHeader placeholder={''} title={props.customHeader || 'Test Results Summary'} />}
			>
				<Column expander />
				<Column field="group" header="Test" />
				<Column
					field="status"
					header="Status"
					body={(testGroup) => {
						if (testGroup.tests.some((test: SummaryData) => test.status === 'FAIL'))
							return <StatusChip status={'ERROR'} />;
						else if (testGroup.tests.some((test: SummaryData) => test.status === 'INCOMPLETE'))
							return <StatusChip status={'PENDING'} />;
						else return <StatusChip status={'SUCCESS'} />;
					}}
				/>
			</GroupDataTable>
		</Box>
	);
};

export default TestSummaryTable;
