import * as React from 'react';
import { Box, InputText, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { ITestCriteria } from '../../../../utils/testCriteria';

interface MagnetometerValidationProps {
	absHeadingErrorMax: number;
	direction: 'North' | 'East' | 'South' | 'West';
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	HEADING_VALUE = 'Heading'
}

const directionToCardinalDegMap: { [key in MagnetometerValidationProps['direction']]: number } = {
	North: 0,
	East: 90,
	South: 180,
	West: 270
};

const MagnetometerValidation: React.FC<MagnetometerValidationProps> = (props) => {
	const TEST_NAME: TestKey | string = `magnetometerValidation${props.direction}`;
	const testFixtureService = ServiceFactory.get('TestFixtureService');

	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults, TEST_NAME]);
	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<string>(
				`${props.direction.toLowerCase()}${FormKeys.HEADING_VALUE}`,
				res ? res.data[`${props.direction.toLowerCase()}${FormKeys.HEADING_VALUE}`] : '',
				[new RsValidator(RsValidatorEnum.CUSTOM, '', validationForDirection)]
			)
		]);
	}, [res, props.direction, props.absHeadingErrorMax]);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	useEffect(() => {
		setFormGroup(defineFormGroup());
	}, [props.testCriteria, props.direction, props.absHeadingErrorMax]);

	function validationForDirection(control: RsFormControl<IRsFormControl>) {
		if (props.direction === 'North') {
			// Special case for north to account for wrapping around 360
			// also handle a negative value just in case

			return (
				testFixtureService.validateResultInRange(control, 360 - props.absHeadingErrorMax, 360) ||
				testFixtureService.validateResultInRange(control, 0, props.absHeadingErrorMax, true)
			);
		}

		return testFixtureService.validateResultInRange(
			control,
			directionToCardinalDegMap[props.direction] - props.absHeadingErrorMax,
			directionToCardinalDegMap[props.direction] + props.absHeadingErrorMax
		);
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	return (
		<Box className={'rsMagnetometerValidation'} display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={16}>
			<InputText
				inputMode={'text'}
				type={'text'}
				control={formGroup.get(`${props.direction.toLowerCase()}${FormKeys.HEADING_VALUE}`)}
				updateControl={handleUpdateControl}
				onBlur={() => {
					testFixtureService.validateFlightTest(
						formGroup,
						TEST_NAME as TestKey,
						true,
						undefined,
						props.testCriteria
					);
				}}
			/>
		</Box>
	);
};
export default MagnetometerValidation;
