import { Page } from '@redskytech/framework/996';
import { rsToastify } from '@redskytech/framework/ui/index.js';
import * as React from 'react';
import { useState } from 'react';
import { ApiRequestV1 } from '../../generated/apiRequests.js';
import { fcuPartNumbers, payloadPartNumbers, vehiclePartNumbers } from '../../services/assembly/assembly.data.js';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import AssemblyTypeSelectorSection from '../assemblePage/sections/AssemblyTypeSelectorSection';
import './FlightTestPage.scss';
import FlightTestSection from './sections/FlightTestSection';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FlightTestPageProps {}

const FlightTestPage: React.FC<FlightTestPageProps> = (_props) => {
	const [hwid, setHwid] = useState<HardwareIdDecoded | undefined>();

	function renderFlightTestSection() {
		if (!hwid) {
			return (
				<AssemblyTypeSelectorSection
					onAssemblyTypeSelected={async (assemblyType, hwid) => {
						if (
							!payloadPartNumbers.includes(hwid.partNumber) &&
							!vehiclePartNumbers.includes(hwid.partNumber) &&
							!fcuPartNumbers.includes(hwid.partNumber)
						) {
							rsToastify.error(
								'Part number is not a vehicle, or fcu, or payload type.',
								'Invalid Part Number'
							);
							return;
						}

						// Lets see if the part exists
						try {
							await ApiRequestV1.getPartByNumbers({
								partNumber: hwid.partNumber,
								serialNumber: hwid.serialNumber
							});
						} catch (error) {
							rsToastify.error('Part not found.', 'Invalid Part Number');
							return;
						}
						setHwid(hwid);
					}}
					pageTitle={'Flight Test'}
					labelText={'Scan the hardware identifier of the assembly you want to test.'}
				/>
			);
		}

		return (
			<FlightTestSection
				hwid={hwid}
				onDone={() => {
					setHwid(undefined);
				}}
			/>
		);
	}

	return <Page className={'rsFlightTestPage'}>{renderFlightTestSection()}</Page>;
};
export default FlightTestPage;
