import * as React from 'react';
import {
	Box,
	LabelInputText,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { ITestCriteria, validateTestCriteria } from '../../../../utils/testCriteria';

interface VehicleHeightSensorTestingProps {
	testKey: TestKey;
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	HOB_READING = 'hobReading',
	AGL_READING = 'aglReading',
	SENSOR_DELTA = 'sensorDeltaHobMinusAgl'
}

const VehicleHeightSensorTesting: React.FC<VehicleHeightSensorTestingProps> = (props) => {
	const TEST_NAME: TestKey = props.testKey;
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);
	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<string>(FormKeys.HOB_READING, res ? res.data[FormKeys.HOB_READING] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.HOB_READING
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.HOB_READING}`);
						return false;
					}
					return validateTestCriteria(FormKeys.HOB_READING, control.value, props.testCriteria);
				})
			]),

			new RsFormControl<string>(FormKeys.AGL_READING, res ? res.data[FormKeys.AGL_READING] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.AGL_READING
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.AGL_READING}`);
						return false;
					}
					return validateTestCriteria(FormKeys.AGL_READING, control.value, props.testCriteria);
				})
			]),
			new RsFormControl<string>(FormKeys.SENSOR_DELTA, res ? res.data[FormKeys.SENSOR_DELTA] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.SENSOR_DELTA
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.SENSOR_DELTA}`);
						return false;
					}
					return validateTestCriteria(FormKeys.SENSOR_DELTA, control.value, props.testCriteria);
				})
			])
		]);
	}, [props.testCriteria, res]);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup);

	// Inside WindSpeed component
	useEffect(() => {
		setFormGroup(defineFormGroup());
	}, [defineFormGroup]);

	useEffect(() => {
		const formGroupClone = formGroup.cloneDeep();
		const hobReading = formGroupClone.get(FormKeys.HOB_READING).value;
		const aglReading = formGroupClone.get(FormKeys.AGL_READING).value;
		let hobValue: number;
		let aglValue: number;
		if (typeof hobReading === 'number') {
			// control.value is a number
			hobValue = hobReading;
		} else if (typeof hobReading === 'string') {
			// control.value is a string
			hobValue = parseFloat(hobReading);
		} else {
			hobValue = NaN;
		}
		if (typeof aglReading === 'number') {
			// control.value is a number
			aglValue = aglReading;
		} else if (typeof aglReading === 'string') {
			// control.value is a string
			aglValue = parseFloat(aglReading);
		} else {
			aglValue = NaN;
		}

		if (!isNaN(hobValue) && !isNaN(aglValue)) {
			const sensorDelta = hobValue - aglValue;
			const sensorDeltaControl = formGroupClone.get(FormKeys.SENSOR_DELTA);
			sensorDeltaControl.value = sensorDelta.toString();
			handleUpdateControl(sensorDeltaControl);
		}
	}, [formGroup.get(FormKeys.HOB_READING).value, formGroup.get(FormKeys.AGL_READING).value]);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME, undefined, undefined, props.testCriteria);
	}

	return (
		<Box className={'rsVehicleHeightSensorTesting'} display={'flex'} flexDirection={'column'} gap={16}>
			<Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={16}>
				<LabelInputText
					labelTitle={'HOB Value'}
					inputMode={'text'}
					type={'text'}
					control={formGroup.get(FormKeys.HOB_READING)}
					updateControl={handleUpdateControl}
				/>
				<LabelInputText
					labelTitle={'AGL Value'}
					inputMode={'text'}
					type={'text'}
					control={formGroup.get(FormKeys.AGL_READING)}
					updateControl={handleUpdateControl}
				/>
			</Box>
		</Box>
	);
};
export default VehicleHeightSensorTesting;
