import * as React from 'react';
import { Box, Checkbox, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { ITestCriteria } from '../../../../utils/testCriteria';

interface PayloadHighSpeedEngagementProps {
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	PROD_ENGAGEMENT = 'confirmProductionEngagementNotification',
	ENGAGEMENT_30_1 = 'performHighSpeedEngage30Deg1Ir',
	ENGAGEMENT_30_2 = 'performHighSpeedEngage30Deg2Ir',
	ENGAGEMENT_30_3 = 'performHighSpeedEngage30Deg3Eo'
}

const PayloadHighSpeedEngagement: React.FC<PayloadHighSpeedEngagementProps> = (props) => {
	const TEST_NAME: TestKey = 'highSpeedEngagements';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);
	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.ENGAGEMENT_30_1, res ? res.data[FormKeys.ENGAGEMENT_30_1] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			]),
			new RsFormControl<boolean>(FormKeys.ENGAGEMENT_30_2, res ? res.data[FormKeys.ENGAGEMENT_30_2] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			]),
			new RsFormControl<boolean>(FormKeys.ENGAGEMENT_30_3, res ? res.data[FormKeys.ENGAGEMENT_30_3] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			]),
			new RsFormControl<boolean>(FormKeys.PROD_ENGAGEMENT, res ? res.data[FormKeys.PROD_ENGAGEMENT] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			])
		]);
	}, [res]);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	useEffect(() => {
		setFormGroup(defineFormGroup());
	}, [props.testCriteria, defineFormGroup]);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME, undefined, undefined, props.testCriteria);
	}

	return (
		<Box className={'rsHighSpeedEngagement'}>
			<Checkbox
				labelText={'Verify R1GCS notification stating "Production Engagement Enabled"'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.PROD_ENGAGEMENT)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Performed 30\u00B0 High Speed Engagement #1 using IR video feed'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.ENGAGEMENT_30_1)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Performed 30\u00B0 High Speed Engagement #2 using IR video feed'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.ENGAGEMENT_30_2)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Performed 30\u00B0 High Speed Engagement #3 using EO video feed'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.ENGAGEMENT_30_3)}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default PayloadHighSpeedEngagement;
