import * as React from 'react';
import './FlightTestSection.scss';
import VerifyEsadReady from './flightTests/VerifyEsadReady';
import VehicleHeightSensorTesting from './flightTests/VehicleHeightSensorTesting';
import LoadFlightLog from './flightTests/LoadFlightLog';
import HighSpeedBoxFlight from './flightTests/HighSpeedBoxFlight';
import PayloadVideoQuality from './flightTests/PayloadVideoQuality';
import CameraInspection from './flightTests/CameraInspection';
import MagSpin from './flightTests/MagSpin';
import WindSpeed from './flightTests/WindSpeed';
import RunPreflight from './flightTests/RunPreflight';
import HighSpeedEngagements from './flightTests/HighSpeedEngagement';
import LoadVideo from './flightTests/LoadVideo';
import MinWattsProfileFlight from './flightTests/MinWattsProfileFlight';
import ReviewPlottingResults from './flightTests/ReviewPlottingResults';
import MagCorrectionValidation from './flightTests/MagCorrectionValidation';
import VehicleRadioRangeTesting from './flightTests/VehicleRadioRangeTesting';

import FlightTestCriteria from '../flightTestCriteria/vehicleFlightTestCriteria.json';
import { extractTestCriteriaList } from '../../../utils/testCriteria';

export type VehiclePreFlightTestKey = 'windSpeed' | 'cameraInspection';

export type VehicleFlightTestKey =
	| 'runPreflight'
	| 'magSpin'
	| 'verifyEsadTransitionToWam'
	| 'magCorrectionValidation'
	| 'tenMeterHeightSensorTesting'
	| 'eightyMeterHeightSensorTesting'
	| 'radioRangeTesting'
	| 'minWattsProfileFlight'
	| 'highSpeedBoxFlight'
	| 'highSpeedEngagements';

export type VehiclePostFlightTestKey = 'loadVideo' | 'loadFlightLog' | 'reviewPlottingResults' | 'payloadVideoQuality';

export type VehiclePostFlightTestKeyNoVideo = 'loadFlightLog' | 'reviewPlottingResults' | 'payloadVideoQuality';

export type VehicleTestKey = VehiclePreFlightTestKey | VehicleFlightTestKey | VehiclePostFlightTestKey;

const vehicleTestKeys: VehicleTestKey[] = [
	'windSpeed',
	'cameraInspection',
	'runPreflight',
	'magSpin',
	'verifyEsadTransitionToWam',
	'magCorrectionValidation',
	'tenMeterHeightSensorTesting',
	'eightyMeterHeightSensorTesting',
	'radioRangeTesting',
	'minWattsProfileFlight',
	'highSpeedBoxFlight',
	'highSpeedEngagements',
	'loadVideo',
	'loadFlightLog',
	'reviewPlottingResults',
	'payloadVideoQuality'
];

type AssertAllKeys<T extends readonly string[]> = T[number] extends VehicleTestKey ? true : never;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const assertAllKeys: AssertAllKeys<typeof vehicleTestKeys> = true;

export { vehicleTestKeys };

export const vehiclePreFlightTestData: { [key in VehiclePreFlightTestKey]: JSX.Element } = {
	windSpeed: <WindSpeed testCriteria={extractTestCriteriaList(FlightTestCriteria, 'WindSpeed')} />,
	cameraInspection: (
		<CameraInspection testCriteria={extractTestCriteriaList(FlightTestCriteria, 'CameraInspection')} />
	)
};

export const vehicleInFlightTestData: { [key in VehicleFlightTestKey]: JSX.Element } = {
	runPreflight: (
		<RunPreflight
			key={'vehPreflight'}
			targetPayloadType={'TRAINER_PAYLOAD_ASSEMBLY'}
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'RunPreflight')}
		/>
	),
	magSpin: <MagSpin testCriteria={extractTestCriteriaList(FlightTestCriteria, 'MagSpin')} />,
	verifyEsadTransitionToWam: (
		<VerifyEsadReady testCriteria={extractTestCriteriaList(FlightTestCriteria, 'VerifyEsadReady')} />
	),
	magCorrectionValidation: (
		<MagCorrectionValidation
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'MagCorrectionValidation')}
		/>
	),
	tenMeterHeightSensorTesting: (
		<VehicleHeightSensorTesting
			testKey={'tenMeterHeightSensorTesting'}
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'TenMeterHeightSensorTesting')}
		/>
	),
	eightyMeterHeightSensorTesting: (
		<VehicleHeightSensorTesting
			testKey={'eightyMeterHeightSensorTesting'}
			testCriteria={extractTestCriteriaList(FlightTestCriteria, 'EightyMeterHeightSensorTesting')}
		/>
	),
	radioRangeTesting: (
		<VehicleRadioRangeTesting testCriteria={extractTestCriteriaList(FlightTestCriteria, 'RadioRangeTesting')} />
	),
	minWattsProfileFlight: (
		<MinWattsProfileFlight testCriteria={extractTestCriteriaList(FlightTestCriteria, 'MinWattsProfileFlight')} />
	),
	highSpeedBoxFlight: (
		<HighSpeedBoxFlight testCriteria={extractTestCriteriaList(FlightTestCriteria, 'HighSpeedBoxFlight')} />
	),
	highSpeedEngagements: (
		<HighSpeedEngagements testCriteria={extractTestCriteriaList(FlightTestCriteria, 'HighSpeedEngagements')} />
	)
};

export const vehiclePostFlightTestData: { [key in VehiclePostFlightTestKey]: JSX.Element } = {
	loadVideo: <LoadVideo testCriteria={extractTestCriteriaList(FlightTestCriteria, 'LoadVideo')} />,
	loadFlightLog: <LoadFlightLog testCriteria={extractTestCriteriaList(FlightTestCriteria, 'LoadFlightLog')} />,
	reviewPlottingResults: (
		<ReviewPlottingResults testCriteria={extractTestCriteriaList(FlightTestCriteria, 'ReviewPlottingResults')} />
	),
	payloadVideoQuality: (
		<PayloadVideoQuality testCriteria={extractTestCriteriaList(FlightTestCriteria, 'PayloadVideoQuality')} />
	)
};

export const vehiclePostFlightTestDataNoVideo: { [key in VehiclePostFlightTestKeyNoVideo]: JSX.Element } = {
	loadFlightLog: <LoadFlightLog testCriteria={extractTestCriteriaList(FlightTestCriteria, 'LoadFlightLog')} />,
	reviewPlottingResults: (
		<ReviewPlottingResults testCriteria={extractTestCriteriaList(FlightTestCriteria, 'ReviewPlottingResults')} />
	),
	payloadVideoQuality: (
		<PayloadVideoQuality testCriteria={extractTestCriteriaList(FlightTestCriteria, 'PayloadVideoQuality')} />
	)
};

export const vehicleFlightTestData = {
	...vehiclePreFlightTestData,
	...vehicleInFlightTestData,
	...vehiclePostFlightTestData
};

export const vehicleFlightTestDataNoVideo = {
	...vehiclePreFlightTestData,
	...vehicleInFlightTestData,
	...vehiclePostFlightTestDataNoVideo
};

// Adjust the flightTestData object to include the appropriate test list based on the current HWID
