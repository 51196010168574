import { useEffect } from 'react';
import router from '../utils/router';
import { popupController } from '@redskytech/framework/ui';
import ConfirmPopup, { ConfirmPopupProps } from '../popups/confirmPopup/ConfirmPopup';

export default function useWarnOnUnsavedChanges(hasUnsavedChanges: boolean) {
	// Check for beforeunload event
	useEffect(() => {
		if (!hasUnsavedChanges) return;
		function onBeforeUnload(e: BeforeUnloadEvent) {
			e.preventDefault();
			e.returnValue = 'Are you sure you want to leave without saving your changes?';
		}

		window.addEventListener('beforeunload', onBeforeUnload, { capture: true });
		return () => {
			window.removeEventListener('beforeunload', onBeforeUnload, { capture: true });
		};
	}, [hasUnsavedChanges]);

	useEffect(() => {
		const id = router.subscribeToBeforeRouterNavigate(() => {
			return new Promise((resolve) => {
				if (!hasUnsavedChanges) {
					resolve(false);
					return;
				}
				popupController.open<ConfirmPopupProps>(ConfirmPopup, {
					title: 'Unsaved Changes',
					message: 'Are you sure you want to leave without saving your changes?',
					confirmButtonText: 'Leave',
					closeButtonText: 'Stay',
					onConfirm: () => {
						resolve(false);
					},
					onCancel: () => {
						resolve(true);
					}
				});
			});
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
		};
	}, [hasUnsavedChanges]);
}
