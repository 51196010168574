import * as React from 'react';
import './PartAssemblySection.scss';
import { Box, Button, Label, popupController, RsFormControl, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import PageHeader from '../../../components/pageHeader/PageHeader';
import serviceFactory from '../../../services/serviceFactory';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import { WebUtils } from '../../../utils/utils';
import ConfirmPopup, { ConfirmPopupProps } from '../../../popups/confirmPopup/ConfirmPopup';
import { Link } from '@redskytech/framework/996';
import SelectableInputText from '../../../components/selectableInputText/SelectableInputText';
import ChecklistSection from './ChecklistSection';
import NotesSection from './NotesSection';
import {
	assemblyStructure,
	baseParts,
	centerStagePartNumbers,
	ngAllowedPartNumbers,
	partAssemblies,
	assemblyValStructure
} from '../../../services/assembly/assembly.data';
import cloneDeep from 'lodash.clonedeep';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { useEffect, useState } from 'react';
import { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';
import classNames from 'classnames';
import InfoImagePopup, { InfoImagePopupProps } from '../../../popups/infoImagePopup/InfoImagePopup';
import {
	AssemblyInfo,
	AssemblyOrBasePartInfo,
	BasePartInfo,
	BasePartType,
	PartAssemblyType
} from '../../../services/assembly/IAssemblyService';
import { CheckListResult } from './ChecklistSection';

interface PartAssemblySectionProps {
	hwid: HardwareIdDecoded;
	onDone: () => void;
}

interface AssemblyPageState {
	assemblyInfo: AssemblyInfo | undefined;
	assemblyInputs: AssemblyOrBasePartInfo[];
	valInfo: AssemblyInfo | undefined;
	valInputs: AssemblyOrBasePartInfo[];
	mainControl: RsFormControl<string>;
	formGroup: RsFormGroup;
	valFormGroup: RsFormGroup;
	checklistFormGroup: RsFormGroup;
	notesControl: RsFormControl<string>;
}

interface ParentRelationship {
	parentId: string | undefined;
	childId: string | undefined;
	mustReassign: boolean;
}

const PartAssemblySection: React.FC<PartAssemblySectionProps> = (props) => {
	const [selectedInputGroup, setSelectedInputGroup] = React.useState<string>('0');
	const [selectedInputKey, setSelectedInputKey] = React.useState<string>('0');
	const [inherentlyModified, setInherentlyModified] = React.useState<boolean>(false);
	const [parentCleanup, setParentCleanup] = React.useState<ParentRelationship>({
		parentId: '',
		childId: '',
		mustReassign: false
	});
	const [state, setState] = useState<AssemblyPageState[]>([
		{
			assemblyInfo: undefined,
			assemblyInputs: [],
			valInfo: undefined,
			valInputs: [],
			mainControl: new RsFormControl<string>('main0', ''),
			formGroup: new RsFormGroup([]),
			valFormGroup: new RsFormGroup([]),
			checklistFormGroup: new RsFormGroup([]),
			notesControl: new RsFormControl<string>('notes', '')
		}
	]);
	const assemblyService = serviceFactory.get('AssemblyService');

	useEffect(() => {
		if (!props.hwid) return;

		const discoveredPartAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(props.hwid.partNumber);

		if (!discoveredPartAssemblyType) {
			rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
			return;
		}

		const assemblyComponents = assemblyStructure[discoveredPartAssemblyType];
		if (!assemblyComponents) {
			rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
			return;
		}

		const valComponents = assemblyValStructure[discoveredPartAssemblyType];
		if (!valComponents) {
			rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
			return;
		}

		// If Discovered Part is a Military Assembly type, then Let's render the top level Military Select Box
		if (discoveredPartAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
			// Then lookup the child part with the same everything, but make a Commercial Vehicle Assembly.
			let commercialHardwareId = {
				partNumber: partAssemblies.AIR_VEHICLE_ASSEMBLY.partNumbers[0],
				hardwareRevision: props.hwid.hardwareRevision,
				serialNumber: props.hwid.serialNumber
			};
			let commercialDefault = true;
			const wrapperAssemblyComponents = assemblyStructure['AIR_VEHICLE_ASSEMBLY'];
			const wrapperValAssemblyComponents = assemblyValStructure['AIR_VEHICLE_ASSEMBLY'];

			(async () => {
				await lookupPart(discoveredPartAssemblyType, props.hwid, assemblyComponents, valComponents, 0).catch(
					(e) => {
						rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error looking up assembly.'), 'Error');
						return;
					}
				);
			})();
			(async () => {
				const res = await assemblyService.lookupPartByNumbers(props.hwid.partNumber, props.hwid.serialNumber);
				if (res) {
					if (res.children.length > 0) {
						// if air vehicle partNumber is in that list, then we update the commercial HardwareId
						res.children.forEach((child) => {
							if (child.partNumber === partAssemblies.AIR_VEHICLE_ASSEMBLY.partNumbers[0]) {
								commercialHardwareId = {
									partNumber: child.partNumber,
									hardwareRevision: child.hardwareRev,
									serialNumber: child.serialNumber
								};
								commercialDefault = false;
							}
						});
					}
				}

				setInherentlyModified(commercialDefault);

				await lookupPart(
					'AIR_VEHICLE_ASSEMBLY',
					commercialHardwareId,
					wrapperAssemblyComponents,
					wrapperValAssemblyComponents,
					1
				).catch((e) => {
					rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error looking up assembly.'), 'Error');
					return;
				});
			})();

			// Then we do duplicated work to make the wrapper state here, as well as set up the separate form.
		} else {
			(async () => {
				await lookupPart(discoveredPartAssemblyType, props.hwid, assemblyComponents, valComponents, 0).catch(
					(e) => {
						rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error looking up assembly.'), 'Error');
						return;
					}
				);
			})();
		}
	}, [props.hwid]);

	// are any of the members of state modified in the array? // extend this.
	const areFormsModified =
		state[0].formGroup.isModified() ||
		state[1]?.formGroup.isModified() ||
		state[0].valFormGroup.isModified() ||
		state[1]?.valFormGroup.isModified() ||
		state[0].checklistFormGroup.isModified() ||
		inherentlyModified ||
		!state[0].notesControl.isAtInitialValue();

	function handleInputClick(key: string) {
		const keys = key.split('_');
		setSelectedInputKey(keys[1]);
		setSelectedInputGroup(keys[0]);
	}

	function handleDiscard() {
		if (!areFormsModified) {
			props.onDone();
			return;
		}

		popupController.open<ConfirmPopupProps>(ConfirmPopup, {
			title: 'Discard Changes?',
			message: 'Are you sure you want to discard your unsaved changes?',
			confirmButtonText: 'Discard',
			onConfirm: () => {
				props.onDone();
			}
		});
	}

	async function lookupPart(
		partAssemblyType: PartAssemblyType | BasePartType,
		hwid: HardwareIdDecoded,
		componentList: (PartAssemblyType | BasePartType)[],
		valList: (PartAssemblyType | BasePartType)[],
		assemblyDepth = 0
	) {
		// Here we need to go and look up into the database to see if this assembly has already been built

		const res = await assemblyService.lookupPartByNumbers(hwid.partNumber, hwid.serialNumber);

		const inputs: AssemblyOrBasePartInfo[] = [];
		componentList.forEach((component) => {
			if (partAssemblies[component as PartAssemblyType])
				inputs.push(cloneDeep(partAssemblies[component as PartAssemblyType] as AssemblyOrBasePartInfo));
			else if (baseParts[component as BasePartType])
				inputs.push(cloneDeep(baseParts[component as BasePartType] as AssemblyOrBasePartInfo));
		});

		const valInputs: AssemblyOrBasePartInfo[] = [];

		valList.forEach((component) => {
			if (partAssemblies[component as PartAssemblyType])
				valInputs.push(cloneDeep(partAssemblies[component as PartAssemblyType] as AssemblyOrBasePartInfo));
			else if (baseParts[component as BasePartType])
				valInputs.push(cloneDeep(baseParts[component as BasePartType] as AssemblyOrBasePartInfo));
		});

		// If this is a Military air vehicle, then we preemptively set a default initial value before looking things up.
		// If we look it up and find it then we will use that number instead.
		if (partAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
			const vehicleInputs = inputs.filter((input) => {
				return input.partNumbers.includes(partAssemblies['AIR_VEHICLE_ASSEMBLY'].partNumbers[0]);
			});
			vehicleInputs[0].initialValue = `PN1:${partAssemblies['AIR_VEHICLE_ASSEMBLY'].partNumbers[0]},REV1:${hwid.hardwareRevision},SN1:${hwid.serialNumber}`;
		}

		if (!res) {
			createAssemblyControls(
				hwid,
				inputs,
				valInputs,
				partAssemblies[partAssemblyType as PartAssemblyType],
				{ checklist: [] },
				'',
				assemblyDepth
			);
			return;
		}

		// Check if there is a bad parent relationship that needs to be removed before proceeding.
		res.parent.forEach((parent) => {
			if (parent.partNumber === partAssemblies.MILITARY_AIR_VEHICLE_ASSEMBLY.partNumbers[0]) {
				const reassign =
					parent.serialNumber !== props.hwid.serialNumber ||
					parent.hardwareRev !== props.hwid.hardwareRevision ||
					parent.partNumber !== props.hwid.partNumber;
				setParentCleanup({ childId: res?.id.toString(), parentId: parent.id, mustReassign: reassign });
				if (reassign) {
					rsToastify.warning(
						`This Commercial Vehicle is already associated with a Military vehicle. If you proceed, the Commercial Vehicle will be reassigned to this Military Vehicle.`,
						'Vehicle Reassignment Required'
					);
					return;
				}
			}
		});

		res.children.forEach((child) => {
			const associatedInputs = inputs.filter((input) => {
				return (
					input.partNumbers.includes(child.partNumber) &&
					(input.initialValue === '' || input.initialValue === undefined)
				);
			});
			if (associatedInputs.length === 0) {
				rsToastify.error(`Unable to find associated input for part number ${child.partNumber}.`, 'Error');
				return;
			}

			associatedInputs[0].initialValue = `PN1:${child.partNumber},REV1:${child.hardwareRev},SN1:${child.serialNumber}`;
		});

		let assemblyTasksChecked: CheckListResult = { checklist: [] };
		if (res.assemblyTasksChecked && res.assemblyTasksChecked !== '')
			assemblyTasksChecked = JSON.parse(res.assemblyTasksChecked.replace(/^"(.*)"$/, '$1'));

		assemblyTasksChecked.checklist.forEach((child) => {
			const associatedInputs = valInputs.filter((input) => {
				return input.label.includes(child.description.replace('Verified ', ''));
			});
			if (associatedInputs.length === 0) {
				return;
			}
			if (associatedInputs.length > 1) {
				return;
			}
			associatedInputs[0].initialValue = child.details;
		});

		createAssemblyControls(
			hwid,
			inputs,
			valInputs,
			partAssemblies[partAssemblyType as PartAssemblyType],
			assemblyTasksChecked,
			res.notes || '',
			assemblyDepth
		);
	}

	async function handleSave(): Promise<boolean> {
		for (let currentState = 0; currentState < state.length; currentState++) {
			const children: CustomTypes.AssemblyData[] = [];
			for (let index = 0; index < state[currentState].assemblyInputs.length; index++) {
				const control = state[currentState].formGroup.get<string>(index.toString());
				const input = state[currentState].assemblyInputs[index];
				if (!control.value) continue;

				const decoded = assemblyService.decodeHardwareId(control.value);
				if (!decoded) {
					rsToastify.error(`Please fix the errors in the form before saving.`, 'Invalid Inputs');
					return false;
				}

				if (!input.partNumbers.includes(decoded.partNumber)) {
					rsToastify.error(
						`The input (${input.label}) has an invalid part number. Please fix.`,
						'Invalid Part Number'
					);
					return false;
				}

				children.push({
					partNumber: decoded.partNumber,
					hardwareRevision: decoded.hardwareRevision,
					serialNumber: decoded.serialNumber,
					name: input.label
				});
			}

			const assemblyTasksChecked: CheckListResult = { checklist: [] };
			// make a json array of the checklist items as keys and the values as true or false

			for (let index = 0; index < state[currentState].assemblyInfo!.checklist.length; index++) {
				const control = state[currentState].checklistFormGroup.get<boolean>(index.toString());
				const checklistItemName = state[currentState].assemblyInfo!.checklist[index];
				assemblyTasksChecked.checklist.push({ description: checklistItemName, checked: control.value });
			}
			for (let index = 0; index < state[currentState].valInputs.length; index++) {
				const control = state[currentState].checklistFormGroup.get<boolean>(
					(state[currentState].assemblyInfo!.checklist.length + index).toString()
				);
				const checklistItemName = `Verified ${state[currentState].valInputs[index].label}`;
				assemblyTasksChecked.checklist.push({
					description: checklistItemName,
					checked: control.value,
					details: `${state[currentState].valFormGroup.get(index.toString()).value}`
				});
			}

			const notes = state[0].notesControl.value;

			const assemblyDecoded = assemblyService.decodeHardwareId(state[currentState].mainControl.value)!;
			const assemblyRequest: CustomTypes.AssemblyRequest = {
				partNumber: assemblyDecoded.partNumber,
				hardwareRevision: assemblyDecoded.hardwareRevision,
				serialNumber: assemblyDecoded.serialNumber,
				name: state[currentState].assemblyInfo!.label,
				assemblyTasksChecked: `"${JSON.stringify(assemblyTasksChecked)}"`,
				notes,
				children
			};

			// This code warns if a nested military vehicle assembly is going to be reassigned
			if (
				currentState === 1 &&
				props.hwid.partNumber === partAssemblies.MILITARY_AIR_VEHICLE_ASSEMBLY.partNumbers[0] &&
				parentCleanup.mustReassign
			) {
				try {
					const childId = parentCleanup.childId;
					const parentId = parentCleanup.parentId;
					await ApiRequestV1.deletePartMap({ childId: Number(childId), parentId: Number(parentId) });
					rsToastify.success('Subassembly relationship Updated.', 'Vehicle Parent Updated');
				} catch (e) {
					rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
				}
			}

			try {
				await ApiRequestV1.postPartAssemble(assemblyRequest);
				rsToastify.success('Successfully saved assembly!', 'Saved Assembly');
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error saving assembly.'), 'Assembly Error');
				return false;
			}
		}
		// cleanup the state
		const tmpState = cloneDeep(state);
		for (let currentState = 0; currentState < state.length; currentState++) {
			tmpState[currentState].notesControl.updateInitialValue();
			tmpState[currentState].formGroup = tmpState[currentState].formGroup.cloneDeep().updateInitialValues();
			tmpState[currentState].valFormGroup = tmpState[currentState].valFormGroup.cloneDeep().updateInitialValues();
			tmpState[currentState].checklistFormGroup = tmpState[currentState].checklistFormGroup
				.cloneDeep()
				.updateInitialValues();
		}
		setState(tmpState);
		return true;
	}

	async function handleSaveAndClose() {
		if (!(await handleSave())) return;
		props.onDone();
	}

	function createAssemblyControls(
		hwid: HardwareIdDecoded,
		inputs: AssemblyOrBasePartInfo[],
		valInputs: AssemblyOrBasePartInfo[],
		assemblyInfo: AssemblyInfo,
		assemblyTasksChecked: CheckListResult,
		notes: string,
		assemblyDepth = 0
	) {
		const mainControl = new RsFormControl<string>(
			`main${assemblyDepth}_0`,
			`PN1:${hwid.partNumber},REV1:${hwid.hardwareRevision},SN1:${hwid.serialNumber}`,
			[]
		);
		const controls: RsFormControl<string>[] = [];
		inputs.forEach((input, index) => {
			controls.push(new RsFormControl<string>(index.toString(), input.initialValue || '', []));
		});

		const valControls: RsFormControl<string>[] = [];
		valInputs.forEach((input, index) => {
			valControls.push(new RsFormControl<string>(index.toString(), input.initialValue || '', []));
		});

		const checkListControls: RsFormControl<boolean>[] = [];
		assemblyInfo.checklist.forEach((_, index) => {
			let isChecked = false;
			if (index < assemblyTasksChecked.checklist.length) {
				isChecked = assemblyTasksChecked.checklist[index].checked;
			}
			checkListControls.push(new RsFormControl<boolean>(index.toString(), isChecked, []));
		});
		valInputs.forEach((_, index) => {
			let isChecked = false;
			if (index + assemblyInfo.checklist.length < assemblyTasksChecked.checklist.length) {
				isChecked = assemblyTasksChecked.checklist[index + assemblyInfo.checklist.length].checked;
			}
			checkListControls.push(
				new RsFormControl<boolean>((index + assemblyInfo.checklist.length).toString(), isChecked, [])
			);
		});

		const tmpState = cloneDeep(state);

		tmpState[assemblyDepth] = {
			mainControl,
			assemblyInfo,
			valInfo: undefined,
			assemblyInputs: inputs,
			valInputs: valInputs,
			formGroup: new RsFormGroup(controls),
			valFormGroup: new RsFormGroup(valControls),
			checklistFormGroup: new RsFormGroup(checkListControls),
			notesControl: new RsFormControl<string>(assemblyDepth === 0 ? 'notes' : 'notes1', notes, [])
		};
		setState((prevState) => {
			const currentState = cloneDeep(prevState);
			currentState[assemblyDepth] = tmpState[assemblyDepth];
			return currentState;
		});
		setSelectedInputKey('0');
		setSelectedInputGroup('0');
		return;
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>, stateIndex = 0) {
		setState((prevState) => {
			const tmpState = cloneDeep(prevState);
			tmpState[Number(stateIndex)].formGroup = tmpState[Number(stateIndex)].formGroup.clone().update(control);
			return tmpState;
		});
	}

	function handleUpdateValControl(control: RsFormControl<IRsFormControl>, stateIndex = 0) {
		setState((prevState) => {
			const tmpState = cloneDeep(prevState);
			tmpState[Number(stateIndex)].valFormGroup = tmpState[Number(stateIndex)].valFormGroup
				.clone()
				.update(control);
			return tmpState;
		});
	}

	function handleClearAll() {
		popupController.open<ConfirmPopupProps>(ConfirmPopup, {
			title: 'Clear All?',
			message: 'Are you sure you want to clear all inputs?',
			onConfirm: () => {
				for (let currentState = 0; currentState < state.length; currentState++) {
					state[currentState].assemblyInputs.forEach((input, index) => {
						const control = state[currentState].formGroup.getCloneDeep(`${index}`);
						control.value = '';
						handleUpdateControl(control, currentState);
					});
				}
			}
		});
	}

	function handleInfoClick(imageUrl: string | undefined, partName: string | undefined) {
		if (!imageUrl || !partName) return;
		popupController.open<InfoImagePopupProps>(InfoImagePopup, {
			imageUrl,
			partName
		});
	}

	function renderTopLevelField() {
		if (!state[0]?.assemblyInfo) return <></>;

		return (
			<>
				<Link path={state[0].assemblyInfo.workInstructionsLink} external target={'blank'}>
					<Label variant={'body1'} weight={'regular'} className={'workInstructionsLink'}>
						Work Instructions
					</Label>
				</Link>
				<Box className={'main'}>
					<SelectableInputText
						labelTitle={state[0].assemblyInfo.label || ''}
						control={state[0].mainControl}
						isSelected={false}
						isFixed
						onClick={() => {}}
						onInputInfoClick={() =>
							handleInfoClick(state[0]?.assemblyInfo?.image, state[0]?.assemblyInfo?.label)
						}
						updateControl={() => {}}
						onBlurOrEnter={async (_value, _enterPressed) => {
							return 'VALID';
						}}
					/>
				</Box>
				<Box className={'dividerLine'} />
				<Button look={'outlinedPrimary'} className={'clearBtn'} onClick={handleClearAll}>
					CLEAR ALL
				</Button>
			</>
		);
	}

	function cleanupValidation(input: AssemblyInfo | BasePartInfo, stateIndex: number, match: boolean) {
		setState((prevState) => {
			const tmpState = prevState;
			// get the index of the control in the checklist
			let checklistIndex = state[stateIndex].valInputs.findIndex((check) => {
				return check.label === input.label;
			});
			if (checklistIndex === undefined || checklistIndex === -1) {
				rsToastify.error('Unable to find checklist item.', 'Error');
				return tmpState;
			}
			checklistIndex =
				checklistIndex +
				(state[stateIndex].assemblyInfo ? state[stateIndex].assemblyInfo!.checklist.length : 0);

			const newUpdatedControl = tmpState[stateIndex].checklistFormGroup.get(checklistIndex.toString());
			newUpdatedControl.value = match;
			// this updates the form state, but not the actual check box, do that too
			tmpState[0].checklistFormGroup = tmpState[stateIndex].checklistFormGroup.clone().update(newUpdatedControl);

			return tmpState;
		});
	}
	function verifyValidationPart(input: AssemblyInfo | BasePartInfo, stateIndex: number, index: number) {
		return async (value: string, enterPressed: boolean) => {
			// Clearing out is ok
			if (!value) {
				cleanupValidation(input, stateIndex, false);
				return 'VALID';
			}

			// find the state index that this key belongs to

			const assemblyService = serviceFactory.get('AssemblyService');
			let hardwareIdDecoded = assemblyService.decodeHardwareId(value);

			// process third party HWIDs
			if (!hardwareIdDecoded && 'onInput' in input && input.onInput !== undefined) {
				value = input.onInput(value);
				hardwareIdDecoded = assemblyService.decodeHardwareId(value);

				setTimeout(() => {
					const updatedFormState = state[stateIndex].valFormGroup.cloneDeep();
					const updatedControl = updatedFormState.getCloneDeep(index.toString());
					updatedControl.value = value;
					updatedFormState.update(updatedControl);
					setState((prevState) => {
						const tmpState = cloneDeep(prevState);
						tmpState[stateIndex].formGroup = updatedFormState;
						return tmpState;
					});
				}, 200);
			}

			if (!hardwareIdDecoded) {
				rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
				cleanupValidation(input, stateIndex, false);

				return 'Invalid Hardware ID';
			}

			if (!input.partNumbers.includes(hardwareIdDecoded.partNumber)) {
				rsToastify.error(`Invalid Part number entered for (${input.label}).`, 'Invalid Part Number');
				cleanupValidation(input, stateIndex, false);

				return `Invalid Part number entered for (${input.label}).`;
			}

			// in the specific case of the parent being an NG payload, we need to check if the FragPayloadnumbers Centerstage part number is the same as the one entered here.
			if (
				centerStagePartNumbers.includes(hardwareIdDecoded.partNumber) &&
				ngAllowedPartNumbers.includes(props.hwid.partNumber)
			) {
				// first get the value of the payload field from the inputs. This is the parent of the centerstage part number
				const payloadField = state[0].formGroup.get('0').value;
				if (!payloadField) {
					rsToastify.error('Unable to retrieve Parent Assembly for Check.', 'Invalid Hardware ID');
					cleanupValidation(input, stateIndex, false);

					return 'Invalid Hardware ID';
				}
				const payloadDecoded = assemblyService.decodeHardwareId(payloadField.toString());
				if (!payloadDecoded) {
					rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
					cleanupValidation(input, stateIndex, false);

					return 'Invalid Hardware ID';
				}
				const ngParentAssembly = await assemblyService.lookupPartByNumbers(
					payloadDecoded.partNumber,
					payloadDecoded.serialNumber
				);
				if (!ngParentAssembly) {
					rsToastify.error('Unable to retrieve Parent Assembly for Check.', 'Invalid Hardware ID');
					cleanupValidation(input, stateIndex, false);

					return 'Invalid Hardware ID';
				}

				// look through the children of the parent assembly for the Centerstage part number
				const centerStageChild = ngParentAssembly.children.find((child) => {
					return centerStagePartNumbers.includes(child.partNumber.toString());
				});

				if (!centerStageChild) {
					rsToastify.error(
						'Unable to find Centerstage Part Number in Parent Assembly.',
						'Invalid Hardware ID'
					);
					cleanupValidation(input, stateIndex, false);

					return 'Invalid Hardware ID';
				}

				if (centerStageChild.partNumber !== hardwareIdDecoded.partNumber) {
					rsToastify.error(
						'Centerstage Part Number does not match the Parent Assembly Centerstage',
						'Invalid Hardware ID'
					);
					cleanupValidation(input, stateIndex, false);

					return 'Hardware ID does not match Expected Part Number';
				}
				if (centerStageChild.serialNumber !== hardwareIdDecoded.serialNumber) {
					rsToastify.error(
						`Centerstage Serial Number does not match the Parent Assembly Centerstage`,
						'Invalid Hardware ID'
					);
					cleanupValidation(input, stateIndex, false);

					return 'Hardware ID does not match Expected Part Number';
				}
			}

			if (enterPressed) {
				let nextGroup = Number(stateIndex);
				let nextKey = Number(index) + 1;
				if (nextKey >= state[Number(stateIndex)].assemblyInputs.length) {
					nextKey = 0;
					nextGroup += 1;
					if (nextGroup >= state.length) {
						nextGroup = 0;
					}
				}
				setSelectedInputGroup(nextGroup.toString());
				setSelectedInputKey(nextKey.toString());
				// If this item happens to be a child of the 0 group and there is a 1 group, then we need to redraw the 1 group
				if (state.length > 1 && state[0].assemblyInputs.includes(input) && stateIndex === 0) {
					// Re lookup the part with the new part Number
					const wrapperAssemblyComponents = assemblyStructure['AIR_VEHICLE_ASSEMBLY'];
					const wrapperValComponents = assemblyValStructure['AIR_VEHICLE_ASSEMBLY'];
					lookupPart(
						'AIR_VEHICLE_ASSEMBLY',
						hardwareIdDecoded,
						wrapperAssemblyComponents,
						wrapperValComponents,
						1
					).catch((e) => {
						rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error looking up assembly.'), 'Error');
						cleanupValidation(input, stateIndex, false);

						return 'Invalid Hardware ID';
					});
				}
			}
			// If you get to here, then it was a valid entry and we can update the check box
			cleanupValidation(input, stateIndex, true);
			return 'VALID';
		};
	}
	function verifyAndUpdatePart(input: AssemblyInfo | BasePartInfo, stateIndex: number, index: number) {
		return async (value: string, enterPressed: boolean) => {
			// Clearing out is ok
			if (!value) return 'VALID';

			// find the state index that this key belongs to

			const assemblyService = serviceFactory.get('AssemblyService');
			let hardwareIdDecoded = assemblyService.decodeHardwareId(value);

			// process third party HWIDs
			if (!hardwareIdDecoded && 'onInput' in input && input.onInput !== undefined) {
				value = input.onInput(value);
				hardwareIdDecoded = assemblyService.decodeHardwareId(value);

				setTimeout(() => {
					const updatedFormState = state[stateIndex].formGroup.cloneDeep();
					const updatedControl = updatedFormState.getCloneDeep(index.toString());
					updatedControl.value = value;
					updatedFormState.update(updatedControl);
					setState((prevState) => {
						const tmpState = cloneDeep(prevState);
						tmpState[stateIndex].formGroup = updatedFormState;
						return tmpState;
					});
				}, 200);
			}

			if (!hardwareIdDecoded) {
				rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
				return 'Invalid Hardware ID';
			}

			if (!input.partNumbers.includes(hardwareIdDecoded.partNumber)) {
				rsToastify.error(`Invalid Part number entered for (${input.label}).`, 'Invalid Part Number');
				return `Invalid Part number entered for (${input.label}).`;
			}

			if (enterPressed) {
				let nextGroup = Number(stateIndex);
				let nextKey = Number(index) + 1;
				if (nextKey >= state[Number(stateIndex)].assemblyInputs.length) {
					nextKey = 0;
					nextGroup += 1;
					if (nextGroup >= state.length) {
						nextGroup = 0;
					}
				}
				setSelectedInputGroup(nextGroup.toString());
				setSelectedInputKey(nextKey.toString());
				// If this item happens to be a child of the 0 group and there is a 1 group, then we need to redraw the 1 group
				if (state.length > 1 && state[0].assemblyInputs.includes(input) && stateIndex === 0) {
					// Re lookup the part with the new part Number
					const wrapperAssemblyComponents = assemblyStructure['AIR_VEHICLE_ASSEMBLY'];
					const wrapperValComponents = assemblyValStructure['AIR_VEHICLE_ASSEMBLY'];
					lookupPart(
						'AIR_VEHICLE_ASSEMBLY',
						hardwareIdDecoded,
						wrapperAssemblyComponents,
						wrapperValComponents,
						1
					).catch((e) => {
						rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error looking up assembly.'), 'Error');
						return 'Invalid Hardware ID';
					});
				}
			}
			return 'VALID';
		};
	}

	// render the Children. This is the main part of the assembly
	function renderModifiableChildInputs() {
		if (state.length === 0) return null;

		const elements: JSX.Element[] = [];

		state.forEach((thisState, stateIndex) => {
			const baseIndex = thisState.assemblyInputs.length;
			const checklistBase = thisState.assemblyInfo ? thisState.assemblyInfo.checklist.length : 0;
			thisState.assemblyInputs?.forEach((input, index) => {
				elements.push(
					<SelectableInputText
						key={`${stateIndex.toString()}_${index.toString()}_${thisState.assemblyInfo}`}
						labelTitle={input.label}
						control={thisState.formGroup.get(index.toString())}
						isSelected={`${selectedInputGroup}_${selectedInputKey}` === `${stateIndex}_${index}`}
						onClick={() => {
							handleInputClick(`${stateIndex.toString()}_${index.toString()}_${thisState.assemblyInfo}`);
						}}
						onInputInfoClick={input.image ? () => handleInfoClick(input.image!, input.label) : undefined}
						updateControl={(control) => {
							handleUpdateControl(control, stateIndex);
						}}
						onBlurOrEnter={verifyAndUpdatePart(input, stateIndex, index)}
					/>
				);
			});

			thisState.valInputs?.forEach((input, index) => {
				elements.push(
					<SelectableInputText
						key={`${stateIndex.toString()}_${(baseIndex + index).toString()}_${thisState.assemblyInfo}`}
						labelTitle={input.label}
						control={thisState.valFormGroup.get(index.toString())}
						isSelected={
							`${selectedInputGroup}_${selectedInputKey}` === `${stateIndex}_${baseIndex + index}`
						}
						onClick={() => {
							handleInputClick(
								`${stateIndex.toString()}_${(baseIndex + index).toString()}_${thisState.assemblyInfo}`
							);
						}}
						onInputInfoClick={input.image ? () => handleInfoClick(input.image!, input.label) : undefined}
						updateControl={(control) => {
							handleUpdateValControl(control, stateIndex);
						}}
						onBlurOrEnter={verifyValidationPart(input, stateIndex, baseIndex + index)}
						isValidated={
							thisState.checklistFormGroup.get<boolean>((checklistBase + index).toString()).value
						}
						showError={!thisState.checklistFormGroup.get<boolean>((checklistBase + index).toString()).value}
					/>
				);
			});
		});

		return <>{elements}</>;
	}

	function renderRightHeaderNode() {
		if (!state[0].assemblyInfo) return null;
		return (
			<Box display={'flex'} gap={8}>
				<Button look={'outlinedPrimary'} onClick={handleDiscard}>
					{areFormsModified ? 'Discard' : 'Done'}
				</Button>
				{areFormsModified && (
					<>
						<Button look={'outlinedPrimary'} onClick={handleSave}>
							Save
						</Button>
						<Button look={'containedPrimary'} onClick={handleSaveAndClose}>
							Save & Close
						</Button>
					</>
				)}
			</Box>
		);
	}

	return (
		<>
			<PageHeader
				title={`Assemble ${state[0].assemblyInfo?.label || ''}`}
				isModified={!!state[0].assemblyInfo && areFormsModified}
				rightNode={renderRightHeaderNode()}
			/>

			<Box className={classNames('rsPartAssemblySection', { large: state[0].assemblyInputs.length > 6 })}>
				{renderTopLevelField()}
				{renderModifiableChildInputs()}
				<ChecklistSection
					checklist={[...(state[0].assemblyInfo ? state[0].assemblyInfo.checklist : [])]}
					checklistFormGroup={state[0] ? state[0].checklistFormGroup : new RsFormGroup([])}
					updateChecklistControl={(updatedControl) => {
						// Do something about restricting update to only the checklist form group

						if (
							Number(updatedControl.key) <
							(state[0].assemblyInfo ? state[0].assemblyInfo.checklist.length : 0)
						) {
							setState((prevState) => {
								const tmpState = prevState;
								tmpState[0].checklistFormGroup = tmpState[0].checklistFormGroup
									.clone()
									.update(updatedControl);
								return tmpState;
							});
						}
					}}
				/>
				<NotesSection
					notesControl={state[0].notesControl}
					updateNotesControl={(updatedControl) => {
						setState((prevState) => {
							const tmpState = prevState;
							tmpState[0].notesControl = updatedControl;
							return tmpState;
						});
					}}
				/>
			</Box>
		</>
	);
};

export default PartAssemblySection;
