import * as React from 'react';
import './TestItem.scss';
import { Box, Icon, Img, Label } from '@redskytech/framework/ui';
import { StatusIconMap, TestStatus } from '../../../pages/testDetailsPage/TestDetailsPage';
import { useEffect, useState } from 'react';
import { useOnClickOutsideRef } from '@redskytech/framework/hooks';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import spinner from './spinner.gif';
import { ObjectUtils } from '../../../utils/utils';
import { PythonTest, TestPrompt, TestResult } from '../../../services/testFixture/ITestFixtureService';

interface TestItemProps {
	test: PythonTest;
	isSelected: boolean;
	isRunning: boolean;
	onRunOptionClick: (option: RunOption) => void;
	onTestItemClick: () => void;
	scrollbarWidth: number;
	isMenuDisabled: boolean;
}

export type RunOption = 'only' | 'to' | 'from';

const TestItem: React.FC<TestItemProps> = (props) => {
	const [status, setStatus] = useState<TestStatus>('PENDING');
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const testItemRef = useOnClickOutsideRef<HTMLDivElement>(() => {
		setIsMenuOpen(false);
	});
	const testPrompts = useRecoilValue<TestPrompt[]>(globalState.testPrompts);
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);

	useEffect(() => {
		setStatus(getStatus());
	}, [testPrompts, testResults]);

	function getStatus(): TestStatus {
		const hasPrompt = props.isRunning && ObjectUtils.isArrayWithData(testPrompts);
		const result = testResults.find((testResult) => testResult.testName.split('_')[0] === props.test.testName);
		if (result) {
			return result.passed ? 'SUCCESS' : 'ERROR';
		}
		if (hasPrompt) {
			const isInfoOnly = testPrompts.every((prompt) => prompt.level === 'info');
			return isInfoOnly ? 'PENDING' : 'WARNING';
		}
		return 'PENDING';
	}

	function handleRunOptionClick(option: RunOption, event: React.MouseEvent) {
		event.stopPropagation();
		setIsMenuOpen(false);
		setIsHovered(false);
		props.onRunOptionClick(option);
	}

	function renderRunOptionsIcon() {
		if (!isHovered || props.isMenuDisabled) return <></>;
		return (
			<Icon
				className={`runOptionsIcon ${isMenuOpen ? 'open' : ''}`}
				iconImg={'icon-more-horizontal'}
				onClick={(event) => {
					event.stopPropagation();
					setIsMenuOpen(!isMenuOpen);
				}}
				fontSize={24}
			/>
		);
	}

	function renderRunOptionsMenu() {
		if (!isMenuOpen) return <></>;

		let topPosition = 12;
		if (testItemRef.current) {
			topPosition = testItemRef.current.getBoundingClientRect().top + 12;
			if (testItemRef.current.getBoundingClientRect().bottom + 100 > window.innerHeight) topPosition -= 112;
		}

		return (
			<Box
				className={'moreMenu'}
				top={topPosition}
				left={user?.isMenuExpanded ? 470 - props.scrollbarWidth : 300 - props.scrollbarWidth}
			>
				<Label
					variant={'body1'}
					weight={'regular'}
					onClick={(event) => {
						handleRunOptionClick('only', event);
					}}
				>
					Run this test only
				</Label>
				<Label
					variant={'body1'}
					weight={'regular'}
					onClick={(event) => {
						handleRunOptionClick('to', event);
					}}
				>
					Run up to this test
				</Label>
				<Label
					variant={'body1'}
					weight={'regular'}
					onClick={(event) => {
						handleRunOptionClick('from', event);
					}}
				>
					Run from this test
				</Label>
			</Box>
		);
	}

	function renderIcon() {
		if (props.isRunning && status !== 'WARNING') {
			return <Img src={spinner} width={50} height={50} alt={'pending'} />;
		}
		if (status === 'PENDING') return <></>;
		return <Icon {...StatusIconMap[status]} fontSize={24} />;
	}

	return (
		<Box
			elementRef={testItemRef}
			className={`rsTestItem ${props.isSelected ? 'active' : ''}`}
			display={'flex'}
			justifyContent={'space-between'}
			alignItems={'center'}
			padding={'16px 20px'}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => {
				setIsHovered(false);
				setIsMenuOpen(false);
			}}
			onClick={props.onTestItemClick}
		>
			<Label variant={'body1'} weight={props.isRunning ? 'bold' : 'regular'}>
				{props.test.label}
			</Label>
			<Box display={'flex'} gap={22}>
				{renderIcon()}
				{renderRunOptionsIcon()}
			</Box>
			{renderRunOptionsMenu()}
		</Box>
	);
};
export default TestItem;
