import * as React from 'react';
import {
	Box,
	Checkbox,
	LabelSelect,
	popupController,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { PartAssemblyType } from '../../../../services/assembly/IAssemblyService';
import WarningPopup, { WarningPopupProps } from '../../../../popups/warningPopup/WarningPopup';
import { ITestCriteria, validateTestCriteria } from '../../../../utils/testCriteria';

interface LiveRunPreflightProps {
	targetPayloadType: PartAssemblyType;
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	PAYLOAD_TYPE = 'r1gcsPayloadTypeMatchesExpected',
	LOADED_PAYLOAD = 'payloadType',
	PAYLOAD_HWID_MATCH = 'payloadTypeMatchesHwid',
	PROD_ENGAGEMENT = 'confirmProductionEngagementNotification'
}

const LiveRunPreflight: React.FC<LiveRunPreflightProps> = (props) => {
	const TEST_NAME: TestKey = 'runPreflight';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const payloadOptions = useMemo<{ label: string; value: string }[]>(() => {
		return [
			{ label: 'Inert Forward Frag', value: 'INERT_FRAG_PAYLOAD_ASSEMBLY' },
			{ label: 'Inert Penetrator', value: 'INERT_PENETRATOR_PAYLOAD_ASSEMBLY' },
			{ label: 'Forward Frag', value: 'FRAG_PAYLOAD_ASSEMBLY' },
			{ label: 'Penetrator', value: 'PENETRATOR_PAYLOAD_ASSEMBLY' }
		];
	}, []);

	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);

	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.PAYLOAD_TYPE, res ? res.data[FormKeys.PAYLOAD_TYPE] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.PAYLOAD_TYPE
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.PAYLOAD_TYPE}`);
						return false;
					}
					return validateTestCriteria(FormKeys.PAYLOAD_TYPE, control.value, props.testCriteria);
				})
			]),
			new RsFormControl<boolean>(
				FormKeys.PAYLOAD_HWID_MATCH,
				res ? res.data[FormKeys.PAYLOAD_HWID_MATCH] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						// Check that the form key has a test criteria
						const testCriteria = props.testCriteria.find(
							(criteria) => criteria.fieldName === FormKeys.PAYLOAD_HWID_MATCH
						);
						if (!testCriteria) {
							console.error(`Test criteria not found for ${FormKeys.PAYLOAD_HWID_MATCH}`);
							return false;
						}
						return validateTestCriteria(FormKeys.PAYLOAD_HWID_MATCH, control.value, props.testCriteria);
					})
				]
			),
			new RsFormControl<string>(FormKeys.LOADED_PAYLOAD, res ? res.data[FormKeys.LOADED_PAYLOAD] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return control.value === props.targetPayloadType;
				})
			]),
			new RsFormControl<boolean>(FormKeys.PROD_ENGAGEMENT, res ? res.data[FormKeys.PROD_ENGAGEMENT] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.PROD_ENGAGEMENT
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.PROD_ENGAGEMENT}`);
						return false;
					}
					return validateTestCriteria(FormKeys.PROD_ENGAGEMENT, control.value, props.testCriteria);
				})
			])
		]);
	}, [props.testCriteria, res, props.targetPayloadType]);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	useEffect(() => {
		setFormGroup(defineFormGroup);
	}, [defineFormGroup]);

	useEffect(() => {
		if (formGroup.get(FormKeys.LOADED_PAYLOAD).value === '') return;
		if (formGroup.get(FormKeys.LOADED_PAYLOAD).value === props.targetPayloadType) return;

		//find the payload options object that has the value of the selected payload
		const selectedPayload = payloadOptions.find((payload) => payload.value === props.targetPayloadType);

		const popupId = popupController.open<WarningPopupProps>(WarningPopup, {
			title: 'Payload Mismatch',
			message: `Test being executed with incorrect payload, expected ${selectedPayload?.label}.`,
			confirmButtonText: 'Continue',
			onConfirm: () => {}
		});
		return () => {
			if (popupId) popupController.closeById(popupId);
		};
	}, [formGroup.get(FormKeys.LOADED_PAYLOAD).value, props.targetPayloadType]);

	useEffect(() => {
		const formGroupClone = formGroup.cloneDeep();
		const selectedPayload = formGroupClone.get(FormKeys.LOADED_PAYLOAD).value;
		const payloadTypeControl = formGroupClone.get(FormKeys.PAYLOAD_TYPE);
		payloadTypeControl.value = selectedPayload === props.targetPayloadType;
		handleLabelControl(payloadTypeControl);
		const payloadHwidControl = formGroupClone.get(FormKeys.PAYLOAD_HWID_MATCH);
		payloadHwidControl.value = selectedPayload === props.targetPayloadType;
		handleLabelControl(payloadHwidControl);

		testFixtureService.validateFlightTest(formGroup, TEST_NAME, undefined, undefined, props.testCriteria);
	}, [formGroup.get(FormKeys.LOADED_PAYLOAD).value, props.testCriteria]);

	function handleLabelControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function handleCheckboxControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME, undefined, undefined, props?.testCriteria);
	}

	return (
		<Box className={'rsLiveRunPreflight'} display={'flex'} flexDirection={'column'} gap={16}>
			<LabelSelect<{ label: string; value: string }>
				isCreatable={false}
				className={'locationSelect'}
				labelTitle={'Select Payload Type Observed in R1GCS'}
				placeholder={'Select'}
				options={payloadOptions}
				control={formGroup.get(FormKeys.LOADED_PAYLOAD)}
				updateControl={handleLabelControl}
			/>
			<Checkbox
				labelText={'Verify R1GCS notification stating "Production Engagement Enabled"'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.PROD_ENGAGEMENT)}
				updateControl={handleCheckboxControl}
			/>
		</Box>
	);
};
export default LiveRunPreflight;
