import { useEffect, useState } from 'react';
import globalState, { clearPersistentState } from '../state/globalState';
import serviceFactory from '../services/serviceFactory';
import { AuthTokens } from '../services/user/UserService';
import { useRecoilValue } from 'recoil';

export enum LoginStatus {
	UNKNOWN,
	LOGGED_OUT,
	LOGGED_IN
}

export default function useLoginState() {
	const [loginStatus, setLoginStatus] = useState<LoginStatus>(LoginStatus.UNKNOWN);
	const authTokens = useRecoilValue<AuthTokens | undefined>(globalState.authTokens);
	const userService = serviceFactory.get('UserService');
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);

	useEffect(() => {
		if (loginStatus === LoginStatus.UNKNOWN) return;
		if (!authTokens) setLoginStatus(LoginStatus.LOGGED_OUT);
		else if (user) setLoginStatus(LoginStatus.LOGGED_IN);
	}, [authTokens, user]);

	useEffect(() => {
		async function initialStartup() {
			if (!authTokens) {
				setLoginStatus(LoginStatus.LOGGED_OUT);
				return;
			}

			if (new Date(authTokens.tokenExpiresOn) < new Date()) {
				// Token has expired, try to refresh it
				try {
					const newAuthTokens = await userService.refreshToken(authTokens);
					await userService.onAfterLogin(newAuthTokens);
					setLoginStatus(LoginStatus.LOGGED_IN);
				} catch (e) {
					clearPersistentState();
					setLoginStatus(LoginStatus.LOGGED_OUT);
				}
			} else {
				// Token is still valid
				await userService.onAfterLogin(authTokens);
				setLoginStatus(LoginStatus.LOGGED_IN);
			}
		}
		initialStartup().catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return loginStatus;
}
