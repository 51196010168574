import * as React from 'react';
import { Box } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState, useMemo } from 'react';
import EvaluationItem, {
	EvaluationGroup,
	EvaluationOptions
} from '../../../../components/evaluationItem/EvaluationItem';
import ServiceFactory from '../../../../services/serviceFactory';
import { ITestCriteria } from '../../../../utils/testCriteria';

interface PayloadVerifyEsadReadyProps {
	testCriteria: ITestCriteria[];
}

type PayloadVerifyEsadEvaluationGroup = EvaluationGroup & {
	armEsad1ReadyToArmed: EvaluationOptions | undefined;
	disarmEsad1ArmedToReady: EvaluationOptions | undefined;
	armEsad2ReadyToArmed: EvaluationOptions | undefined;
	disarmEsad2ArmedToReady: EvaluationOptions | undefined;
	armEsad3ReadyToArmed: EvaluationOptions | undefined;
	disarmEsad3ArmedToReady: EvaluationOptions | undefined;
};

type EvaluationKey = keyof PayloadVerifyEsadEvaluationGroup;

const PayloadVerifyEsadReady: React.FC<PayloadVerifyEsadReadyProps> = (props) => {
	const TEST_NAME: TestKey = 'verifyEsadReady';
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const [evaluations, setEvaluations] = useState<PayloadVerifyEsadEvaluationGroup>({
		armEsad1ReadyToArmed: res ? res.data['armEsad1ReadyToArmed'] : undefined,
		disarmEsad1ArmedToReady: res ? res.data['disarmEsad1ArmedToReady'] : undefined,
		armEsad2ReadyToArmed: res ? res.data['armEsad2ReadyToArmed'] : undefined,
		disarmEsad2ArmedToReady: res ? res.data['disarmEsad2ArmedToReady'] : undefined,
		armEsad3ReadyToArmed: res ? res.data['armEsad3ReadyToArmed'] : undefined,
		disarmEsad3ArmedToReady: res ? res.data['disarmEsad3ArmedToReady'] : undefined
	});

	useEffect(() => {
		testFixtureService.validateEvaluationTest(TEST_NAME, evaluations, undefined, props.testCriteria);
	}, [evaluations, props.testCriteria]);

	function handleEvaluationClick(evaluation: EvaluationOptions, key: EvaluationKey) {
		setEvaluations((prevState) => {
			const updatedState = { ...prevState };
			updatedState[key] = evaluation;
			return updatedState;
		});
	}
	return (
		<Box className={'rsVerifyEsadReady'} display={'flex'} flexDirection={'column'} gap={16}>
			<EvaluationItem
				label={'Arm #1: ESAD transition from “Ready” to “Armed” VSI shows red'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'armEsad1ReadyToArmed');
				}}
				evaluation={evaluations.armEsad1ReadyToArmed}
			/>
			<EvaluationItem
				label={'Disarm #1: ESAD transition from “Armed” to “Ready” VSI shows green'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad1ArmedToReady');
				}}
				evaluation={evaluations.disarmEsad1ArmedToReady}
			/>
			<EvaluationItem
				label={'Arm #2: ESAD transition from “Ready” to “Armed” VSI shows red'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'armEsad2ReadyToArmed');
				}}
				evaluation={evaluations.armEsad2ReadyToArmed}
			/>
			<EvaluationItem
				label={'Disarm #2: ESAD transition from “Armed” to “Ready” VSI shows green'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad2ArmedToReady');
				}}
				evaluation={evaluations.disarmEsad2ArmedToReady}
			/>{' '}
			<EvaluationItem
				label={'Arm #3: ESAD transition from “Ready” to “Armed” VSI shows red'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'armEsad3ReadyToArmed');
				}}
				evaluation={evaluations.armEsad3ReadyToArmed}
			/>
			<EvaluationItem
				label={'Disarm #3: ESAD transition from “Armed” to “Ready” VSI shows green'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad3ArmedToReady');
				}}
				evaluation={evaluations.disarmEsad3ArmedToReady}
			/>
		</Box>
	);
};
export default PayloadVerifyEsadReady;
