import * as React from 'react';
import './UpdateSettingsPopup.scss';
import { Box, Button, Label, Popup, popupController, PopupProps } from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import router from '../../utils/router';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';

export type UpdateSettingsPopupProps = PopupProps;

const UpdateSettingsPopup: React.FC<UpdateSettingsPopupProps> = (props) => {
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	let message = 'This test needs more information. Please contact your administrator to update the settings.';
	if (user?.role === 'admin') {
		message =
			'This test needs more information. Go to the settings page and make sure all the fields are filled out.';
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsUpdateSettingsPopup'}>
				<PopupHeader title={'Update Settings'} />
				<Label variant={'body1'} weight={'regular'} mt={24}>
					{message}
				</Label>
				<Box display={'flex'} gap={16} mt={24}>
					<Button
						look={'containedPrimary'}
						onClick={() => {
							// Only redirect to settings if you are supposed to be able to be there.
							popupController.close(UpdateSettingsPopup);
							if (user?.role === 'admin') {
								router.navigate('/settings');
							} else {
								router.navigate('/assembly/list');
							}
						}}
						fullWidth
					>
						{user?.role === 'admin' ? 'Settings' : 'Close'}
					</Button>
				</Box>
			</Box>
		</Popup>
	);
};
export default UpdateSettingsPopup;
