import * as React from 'react';
import './TestResultsPopup.scss';
import { Box, Label, Popup, popupController, PopupProps, rsToastify } from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import { useEffect, useState } from 'react';
import StatusChip from '../../components/statusChip/StatusChip';
import { WebUtils } from '../../utils/utils';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { TestResult } from '../../services/testFixture/ITestFixtureService';
import ResultsList from '../../components/resultsList/ResultsList';
import { CalibrationOutput } from '../../services/calibration/ICalibrationService';
import serviceFactory from '../../services/serviceFactory';

export interface TestResultsPopupProps extends PopupProps {
	testResultId: number;
	testName: string;
}

const TestResultsPopup: React.FC<TestResultsPopupProps> = (props) => {
	const [testResultDetails, setTestResultDetails] = useState<Api.V1.TestResult.Get.Res | undefined>();
	const [localHasPassed, setLocalHasPassed] = useState<boolean>(true);

	useEffect(() => {
		(async function () {
			try {
				const res = await ApiRequestV1.getTestResult({ id: props.testResultId });
				// Now we need to use the testName to look up all the tests that were supposed to be run
				const testFixtureService = serviceFactory.get('TestFixtureService');
				const expectedTestNames = testFixtureService.getListOfTestsByTestName(props.testName);
				// Now we need to make sure we have a result for each of those tests
				const testResults = res.resultData as TestResult[];
				const missingTests = expectedTestNames.filter((testName) => {
					return !testResults.find((testResult) => testResult.testName === testName);
				});
				// Now we need to add a result for each of the missing tests
				missingTests.forEach((testName) => {
					testResults.push({
						testName: testName,
						hasNotRan: true,
						continuable: false,
						passed: false,
						timeStamp: 'N/A'
					});
				});

				// Of the test results we received, excluding those that have status incomplete, we need to see if any of them failed
				setLocalHasPassed(
					testResults.filter((testResult) => !testResult.hasNotRan).every((testResult) => testResult.passed)
				);
				setTestResultDetails(res);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Test Result Fetch Error'), 'Server Error');
			}
		})();
	}, [props.testResultId]);

	function renderTestResults() {
		if (!testResultDetails)
			return (
				<Label variant={'subheader2'} weight={'regular'}>
					Loading...
				</Label>
			);
		if (!testResultDetails.resultData) {
			return (
				<Label variant={'subheader2'} weight={'regular'} textAlign={'center'}>
					No Data
				</Label>
			);
		}
		const results = testResultDetails.resultData as TestResult[] | CalibrationOutput[];
		return <ResultsList results={results} showRawData showTestCriteria />;
	}

	function renderStatusChip() {
		if (!testResultDetails) return;
		if (testResultDetails.status === 'PASS') return <StatusChip status={'SUCCESS'} />;
		if (testResultDetails.status === 'FAIL') return <StatusChip status={'ERROR'} />;
		if (testResultDetails.status === 'INCOMPLETE')
			return (
				<Box display={'flex'} gap={8}>
					<StatusChip status={'PENDING'} />
					{localHasPassed ? <StatusChip status={'SUCCESS'} /> : <StatusChip status={'ERROR'} />}
				</Box>
			);
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick className={'rsTestResultsPopupContainer'}>
			<Box className={'rsTestResultsPopup'}>
				<PopupHeader
					title={
						<Box display={'flex'} gap={8}>
							<Label variant={'subheader1'} weight={'medium'}>
								{props.testName}
							</Label>
							{renderStatusChip()}
						</Box>
					}
					onClose={() => {
						popupController.close(TestResultsPopup);
					}}
				/>
				<Box className={'testResultList'}>{renderTestResults()}</Box>
			</Box>
		</Popup>
	);
};

export default TestResultsPopup;
