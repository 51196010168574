import * as React from 'react';
import './UserListPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../components/pageHeader/PageHeader';
import { DataTableRowClickEventParams, DataTableFilterMeta } from 'primereact/datatable';
import { useState } from 'react';
import router from '../../utils/router';
import ListDataTable from '../../components/listDataTable/ListDataTable';
import TableSearchHeader from '../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import ColumnHeader from '../../components/listDataTable/columnHeader/ColumnHeader';
import { StringUtils, WebUtils } from '../../utils/utils';
import {
	Button,
	InputText,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	Select
} from '@redskytech/framework/ui';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import useIsMounted from '../../customHooks/useIsMounted';
import CreateUserPopup, { CreateUserPopupProps } from '../../popups/createUserPopup/CreateUserPopup';
import useDataTableFilters from '../../customHooks/useDataTableFilters';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserListPageProps {}

const FieldKeys = {
	FIRST_NAME: 'user.firstName',
	LAST_NAME: 'user.lastName',
	EMAIL: 'user.email',
	ROLE: 'user.role'
};

const UserListPage: React.FC<UserListPageProps> = (_props) => {
	const [users, setUsers] = useState<RedSky.RsPagedResponseData<Api.V1.User.Paged.Get.Res[]>>({
		data: [],
		total: 0
	});
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentQuery, setCurrentQuery] = useState<RedSky.PageQuery>({
		page: 1,
		perPage: 10
	});
	const initialFiltersFormGroup: RsFormGroup = new RsFormGroup([
		new RsFormControl<string>(FieldKeys.FIRST_NAME, ''),
		new RsFormControl<string>(FieldKeys.EMAIL, ''),
		new RsFormControl<string>(FieldKeys.ROLE, '')
	]);
	const initialFilters: DataTableFilterMeta = {
		[FieldKeys.FIRST_NAME]: {
			operator: 'OR',
			constraints: [{ value: null, matchMode: 'contains' }]
		},
		[FieldKeys.LAST_NAME]: {
			operator: 'AND',
			constraints: [{ value: null, matchMode: 'contains' }]
		},
		[FieldKeys.EMAIL]: {
			operator: 'AND',
			constraints: [{ value: null, matchMode: 'contains' }]
		},
		[FieldKeys.ROLE]: {
			operator: 'AND',
			constraints: [{ value: null, matchMode: 'equals' }]
		}
	};
	const {
		filters,
		activeFilters,
		globalSearch,
		setGlobalSearch,
		handleClearAllFilters,
		handleFilterClear,
		handleFilterApply,
		handleUpdateControl,
		filtersFormGroup
	} = useDataTableFilters(initialFilters, initialFiltersFormGroup, getUpdatedFilters);
	const isMounted = useIsMounted();

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);
		setCurrentQuery(pageQuery);
		try {
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;
			const res = await ApiRequestV1.getUserPaged(pageQuery);
			setUsers(res);
			if (!isMounted) return;
			setIsLoading(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function getUpdatedFilters(field: string, value: any) {
		const filter: any = filters[field];
		const constraint = [
			{
				value: value,
				matchMode: filter?.constraints[0]?.matchMode
			}
		];
		if (field === FieldKeys.FIRST_NAME) {
			return {
				...filters,
				[FieldKeys.FIRST_NAME]: {
					...filter,
					constraints: constraint
				},
				[FieldKeys.LAST_NAME]: {
					...filter,
					constraints: constraint
				}
			};
		}
		return {
			...filters,
			[field]: {
				...filter,
				constraints: constraint
			}
		};
	}

	async function handleListUpdate() {
		await getData(currentQuery);
	}

	function handleRowClick({ data }: DataTableRowClickEventParams) {
		router.navigate('/user/details?ui=' + data.id).catch(console.error);
	}

	return (
		<Page className={'rsUserListPage'}>
			<PageHeader
				title={'Users'}
				rightNode={
					<Button
						look={'containedPrimary'}
						onClick={() => {
							popupController.open<CreateUserPopupProps>(CreateUserPopup, {
								onCreate: handleListUpdate
							});
						}}
					>
						Create User
					</Button>
				}
			/>
			<ListDataTable
				loading={isLoading}
				onClearAllFilters={handleClearAllFilters}
				onFilterClear={handleFilterClear}
				onFilterApply={handleFilterApply}
				filters={filters}
				data={users}
				getData={getData}
				globalFilter={globalSearch}
				globalFilterFields={[FieldKeys.FIRST_NAME, FieldKeys.LAST_NAME, FieldKeys.EMAIL, FieldKeys.ROLE]}
				onRowClick={handleRowClick}
				header={
					<TableSearchHeader
						searchValue={globalSearch}
						onChange={(value: string) => setGlobalSearch(value)}
						placeholder={'Search users'}
						title={'Users'}
					/>
				}
			>
				<Column
					className={classNames({ activeFilter: activeFilters.includes(FieldKeys.FIRST_NAME) })}
					field={FieldKeys.FIRST_NAME}
					sortField={FieldKeys.FIRST_NAME}
					header={<ColumnHeader label={'Name'} isActiveSort={activeSortColumn === FieldKeys.FIRST_NAME} />}
					sortable
					filter
					filterElement={
						<InputText
							inputMode={'text'}
							placeholder={'Name'}
							control={filtersFormGroup.get(FieldKeys.FIRST_NAME)}
							updateControl={handleUpdateControl}
						/>
					}
					body={(user: Api.V1.User.Paged.Get.Res) => `${user.firstName} ${user.lastName}`}
				/>
				<Column
					className={classNames({ activeFilter: activeFilters.includes(FieldKeys.EMAIL) })}
					field={FieldKeys.EMAIL}
					sortField={FieldKeys.EMAIL}
					header={<ColumnHeader label={'Email'} isActiveSort={activeSortColumn === FieldKeys.EMAIL} />}
					sortable
					filter
					filterElement={
						<InputText
							inputMode={'text'}
							placeholder={'Email'}
							control={filtersFormGroup.get(FieldKeys.EMAIL)}
							updateControl={handleUpdateControl}
						/>
					}
					body={(user: Api.V1.User.Paged.Get.Res) => user.email}
				/>
				<Column
					className={classNames({ activeFilter: activeFilters.includes(FieldKeys.ROLE) })}
					field={FieldKeys.ROLE}
					sortField={FieldKeys.ROLE}
					header={<ColumnHeader label={'Role'} isActiveSort={activeSortColumn === FieldKeys.ROLE} />}
					sortable
					filter
					filterElement={
						<Select<{ label: string; value: Model.User['role'] }>
							options={[
								{ label: 'Admin', value: 'admin' },
								{ label: 'Technician', value: 'technician' },
								{ label: 'NG Technician', value: 'ngTechnician' }
							]}
							placeholder={'Role'}
							control={filtersFormGroup.get(FieldKeys.ROLE)}
							updateControl={handleUpdateControl}
						/>
					}
					body={(user: Api.V1.User.Paged.Get.Res) => StringUtils.capitalizeFirst(user.role || '')}
				/>
			</ListDataTable>
		</Page>
	);
};

export default UserListPage;
