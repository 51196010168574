import * as React from 'react';
import './LinksPopup.scss';
import { Box, Label, Popup, popupController, PopupProps } from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import { Link } from '@redskytech/framework/996';
import colors from '../../themes/colors.scss?export';

export interface LinksPopupProps extends PopupProps {
	artifacts: { data: { fileUrl: string; fileSize: string }[] };
}

const LinksPopup: React.FC<LinksPopupProps> = (props) => {
	function renderArtifacts() {
		return props.artifacts.data.map((artifact, index) => {
			const fileName = artifact.fileUrl.split('/').pop();
			if (!fileName) return <Box></Box>;
			return (
				<Box>
					{fileName && fileName.endsWith('mp4') && (
						<video controls width="500">
							<source src={artifact.fileUrl} type={'video/mp4'} />
						</video>
					)}
					<Link
						key={index}
						path={
							fileName && fileName.includes('pdf')
								? `${artifact.fileUrl}?download=true`
								: artifact.fileUrl
						}
						external
						target={'blank'}
					>
						<Label variant={'body1'} weight={'regular'} color={colors.primaryBlue700} underline>
							{fileName}
						</Label>
					</Link>
				</Box>
			);
		});
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsLinksPopup'}>
				<PopupHeader
					title={'Links'}
					onClose={() => {
						popupController.close(LinksPopup);
					}}
				/>
				<Box className={'linkContainer'}>{renderArtifacts()}</Box>
			</Box>
		</Popup>
	);
};
export default LinksPopup;
