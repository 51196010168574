import * as React from 'react';
import './BoardCalibrationPage.scss';
import { Page } from '@redskytech/framework/996';
import { useState } from 'react';
import CalibrationTypeSelectorSection, { ConfigDetails } from './sections/CalibrationTypeSelectorSection';
import CalibrationTestSection from './sections/CalibrationTestSection';
import { CalibrationFixtureType } from '../../services/calibration/ICalibrationService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BoardCalibrationPageProps {}

const BoardCalibrationPage: React.FC<BoardCalibrationPageProps> = (_props) => {
	const [fixtureType, setFixtureType] = useState<CalibrationFixtureType>();
	const [calibrationDetails, setCalibrationDetails] = useState<ConfigDetails>();

	function renderCalibrationView() {
		if (!fixtureType || !calibrationDetails) {
			return (
				<CalibrationTypeSelectorSection
					onCalibrationTypeSelected={(fixtureType, calibrationDetails) => {
						setFixtureType(fixtureType);
						setCalibrationDetails(calibrationDetails);
					}}
				/>
			);
		}

		return (
			<CalibrationTestSection
				calibrationConfig={calibrationDetails.fullDetails}
				fixtureType={fixtureType}
				partData={calibrationDetails.partData}
				onDone={() => {
					setFixtureType(undefined);
					setCalibrationDetails(undefined);
				}}
			/>
		);
	}

	return <Page className={'rsBoardCalibrationPage'}>{renderCalibrationView()}</Page>;
};
export default BoardCalibrationPage;
