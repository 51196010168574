import { CageCodeFormModel, HwidFormModel, PrinterParamsModel } from '../../pages/labelPage/LabelPage';
import { Service } from '../Service';

export default class LabelService extends Service {
	// See the following website to help parse and under
	generatePartLabelZpl(hwidFormModel: HwidFormModel, printerParamsModel: PrinterParamsModel): string {
		const zplStrings: string[] = [];
		const labelColumnCount = printerParamsModel.size === 'NORMAL' ? 3 : 4;
		zplStrings.push(this.getZplPrinterSetup(printerParamsModel));

		for (let labelCount = 0; labelCount < +hwidFormModel.quantity; labelCount++) {
			if (labelCount % labelColumnCount === 0) {
				zplStrings.push(this.getZplLabelStart(printerParamsModel.size));
			}
			zplStrings.push(
				this.getZplDataMatrix(
					this.getHwidStrFromFormModel(hwidFormModel, labelCount),
					labelCount % labelColumnCount,
					printerParamsModel.size === 'NORMAL'
				)
			);
			zplStrings.push(
				this.getZplPartNumberSerialText(
					hwidFormModel.partNumber,
					this.getSerialNumberFromFormModel(hwidFormModel, labelCount),
					labelCount % labelColumnCount,
					printerParamsModel.doubleHorizontal,
					printerParamsModel.size === 'NORMAL'
				)
			);
			if (labelCount % labelColumnCount === labelColumnCount - 1) {
				zplStrings.push(this.getZplLabelEnd());
			}
		}

		// Add an extra ending if the last label was not a full sheet
		if (+hwidFormModel.quantity % labelColumnCount !== 0) {
			zplStrings.push(this.getZplLabelEnd());
		}

		return zplStrings.join('\n');
	}

	generateCageLabelZpl(cageCodeFormModel: CageCodeFormModel, printerParamsModel: PrinterParamsModel): string {
		const zplStrings: string[] = [];
		const labelColumnCount = printerParamsModel.size === 'NORMAL' ? 3 : 4;
		zplStrings.push(this.getZplPrinterSetup(printerParamsModel));

		for (let labelCount = 0; labelCount < +cageCodeFormModel.quantity; labelCount++) {
			if (labelCount % labelColumnCount === 0) {
				zplStrings.push(this.getZplLabelStart(printerParamsModel.size));
			}
			zplStrings.push(
				this.getZplCageCodeText(
					cageCodeFormModel.cageCode,
					cageCodeFormModel.date,
					labelCount % labelColumnCount,
					printerParamsModel.doubleHorizontal,
					printerParamsModel.size === 'NORMAL'
				)
			);
			if (labelCount % labelColumnCount === labelColumnCount - 1) {
				zplStrings.push(this.getZplLabelEnd());
			}
		}

		// Add an extra ending if the last label was not a full sheet
		if (+cageCodeFormModel.quantity % labelColumnCount !== 0) {
			zplStrings.push(this.getZplLabelEnd());
		}

		return zplStrings.join('\n');
	}

	getHwidStrFromFormModel(hwidFormModel: HwidFormModel, offset: number): string {
		return `PN1:${hwidFormModel.partNumber},REV1:${hwidFormModel.hwRev},SN1:${this.getSerialNumberFromFormModel(
			hwidFormModel,
			offset
		)}`;
	}

	getSerialNumberFromFormModel(hwidFormModel: HwidFormModel, offset: number): string {
		const numberPadLength = hwidFormModel.prefix.length > 0 ? 4 : 5;
		const serialNumber = +hwidFormModel.startingNumber + offset;
		const serialNumberPadded = serialNumber.toString().padStart(numberPadLength, '0');
		if (hwidFormModel.year.length === 4) hwidFormModel.year = hwidFormModel.year.slice(2);

		const weekPadded = hwidFormModel.week.padStart(2, '0');
		return `${hwidFormModel.location}-${hwidFormModel.prefix}${serialNumberPadded}-${weekPadded}${hwidFormModel.year}`;
	}

	/**
	 * Returns the ZPL code for the printer setup
	 * @param printerParamsModel - Printer parameters
	 * @returns ZPL code for the printer setup
	 * @private
	 */
	private getZplPrinterSetup(printerParamsModel: PrinterParamsModel): string {
		// Starts a label definition that sets the tear off length, back feed value, label shift, media type, etc
		return `
		^XA
		^FX Setup Printer
		~TA000
		~JSN
		^LT${printerParamsModel.verticalShift}
		^MNW
		^MTT
		^PON
		^PMN
		^LH0,0
		^JMA
		^PR${printerParamsModel.speed},${printerParamsModel.speed}
		~SD${printerParamsModel.darkness}
		^LS${printerParamsModel.horizontalShift}
		^JUS
		^LRN
		^CI0
		^XZ
		`;
	}

	/**
	 * Returns the ZPL code for a Data Matrix barcode
	 * @param hwid - Hardware ID
	 * @param index - (0-3) position on the 4 column label
	 * @returns ZPL code for a Data Matrix barcode
	 * @private
	 */
	private getZplDataMatrix(hwid: string, index: number, isNewLabelLayout: boolean): string {
		if (isNewLabelLayout) {
			return `
		^FO${index * 675 + 414},60
		^BXN,5,200,26,26 
		^FD${hwid}^FS
		`;
		}

		return `
		^FO${index * 375 + 50},55
		^BX N,6,200
		^FD${hwid}^FS
		`;
	}

	/**
	 * Returns the ZPL code for the start of a label
	 * @private
	 */
	private getZplLabelStart(size: PrinterParamsModel['size']): string {
		return `
		^XA
		^MMT
		^${size === 'SMALL' ? 'PW1462' : 'PW2025'}
		^${size === 'SMALL' ? 'LL0338' : 'LL375'}
		`;
	}

	/**
	 * Returns the ZPL code for the end of a label
	 * @private
	 */
	private getZplLabelEnd() {
		return `
		^PQ1,0,1,Y
		^XZ
		`;
	}

	/**
	 * Returns the ZPL code for the text on a label
	 * @param partNumber - Part number
	 * @param serialNumber - Serial number
	 * @param index - (0-3) position on the 4 column label
	 * @returns ZPL code for the text on a label
	 * @private
	 */
	private getZplPartNumberSerialText(
		partNumber: string,
		serialNumber: string,
		index: number,
		doubleHorizontal: boolean,
		isNewLabelLayout: boolean
	): string {
		if (isNewLabelLayout) {
			return `
			^FT${index * 675 + 30},130
			^A0N,48
			^FH
			^FD(17V) 850T4^FS
	
			^FT${index * 675 + 30},190
			^A0N,48
			^FH
			^FD(1P) ${partNumber}^FS
			
			^FT${index * 675 + 30},250
			^A0N,48
			^FH
			^FD(S) ${serialNumber}^FS`;
		}

		let zplText = `
		^FT${index * 375 + 10},262^A0N,35,34^FH\^FD${partNumber}^FS
		^FT${index * 375 + 10},293^A0N,30,29^FH\^FD${serialNumber}^FS`;
		if (doubleHorizontal) {
			zplText += `
			^FT${index * 375 + 11},262^A0N,35,34^FH\^FD${partNumber}^FS
			^FT${index * 375 + 11},293^A0N,30,29^FH\^FD${serialNumber}^FS`;
		}
		return zplText;
	}

	private getZplCageCodeText(
		cageCode: string,
		creationDate: string,
		index: number,
		doubleHorizontal: boolean,
		isNewLabelLayout: boolean
	): string {
		if (isNewLabelLayout) {
			return `
			^FT${index * 675 + 30},130
			^A0N,48
			^FH
			^FD(17V) 850T4^FS
	
			^FT${index * 675 + 30},190
			^A0N,48
			^FH
			^FD(1P) ${cageCode}^FS
			
			^FT${index * 675 + 30},250
			^A0N,48
			^FH
			^FD(S) ${creationDate}^FS`;
		}

		let zplText = `
		^FT${index * 375 + 10},150^A0N,40,39^FH\^FD${cageCode}^FS
		^FT${index * 375 + 10},200^A0N,40,39^FH\^FD${creationDate}^FS
		`;
		if (doubleHorizontal) {
			zplText += `
			^FT${index * 375 + 11},150^A0N,40,39^FH\^FD${cageCode}^FS
			^FT${index * 375 + 11},200^A0N,40,39^FH\^FD${creationDate}^FS
			`;
		}
		return zplText;
	}
}
