import * as React from 'react';
import './TesterStatus.scss';
import { Box, Button, Icon, Label, rsToastify } from '@redskytech/framework/ui';
import colors from '../../themes/colors.scss?export';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { TesterShimStatusData } from '../../services/socketio/SocketioService';
import { useEffect, useState } from 'react';
import ShimService, { ShimVersionDetails } from '../../services/shim/ShimService';
import useIsMounted from '../../customHooks/useIsMounted';
import { Link } from '@redskytech/framework/996';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TesterStatusProps {}

const TesterStatus: React.FC<TesterStatusProps> = () => {
	const user = useRecoilValue(globalState.user);
	const testerStatus = useRecoilValue(globalState.testerShimStatus);
	const [showOutOfDateBanner, setShowOutOfDateBanner] = useState<boolean>(false);
	const [hasOutOfDateBannerBeenDismissed, setHasOutOfDateBannerBeenDismissed] = useState<boolean>(false);
	const [latestShimVersionDetails, setLatestShimVersionDetails] = useState<ShimVersionDetails | undefined>(undefined);
	const isMounted = useIsMounted();

	useEffect(() => {
		// If the banner has been dismissed, we don't want to show it again until after some delay
		if (hasOutOfDateBannerBeenDismissed) return;
		if (showOutOfDateBanner) return;
		async function checkForLatestVersion() {
			try {
				// Always get the latest version details, even if the tester is not connected
				const versionDetails = await ShimService.getLatestVersion();
				// If the component has unmounted during our await, we don't want to set the state
				if (!isMounted) {
					return;
				}
				setLatestShimVersionDetails(versionDetails);

				if (testerStatus.status !== 'CONNECTED_DATA') return;
				const shimVersion = versionDetails.version.split('.').map((num) => parseInt(num));
				const testerVersion = testerStatus.version.split('.').map((num) => parseInt(num));
				if (shimVersion.length !== testerVersion.length) {
					rsToastify.error('Shim version syncing failed due to incompatibility', 'Shim Sync Check Failure');
					return;
				}
				for (let i = 0; i < shimVersion.length; i++) {
					if (testerVersion[i] > shimVersion[i] || user?.role === 'ngTechnician') {
						return;
					} else if (testerVersion[i] < shimVersion[i]) {
						setShowOutOfDateBanner(true);
					}
				}
			} catch (e) {
				rsToastify.error('Failed to get latest shim version from the cloud.', 'Error Getting Latest Version');
			}
		}

		checkForLatestVersion().catch(console.error);

		let intervalId: NodeJS.Timeout | undefined = undefined;
		const delayId = setTimeout(() => {
			intervalId = setInterval(() => {
				checkForLatestVersion().catch(console.error);
			}, 60 * 1000);
		}, 30 * 1000);
		return () => {
			if (delayId) {
				clearTimeout(delayId);
			}
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [testerStatus, showOutOfDateBanner, hasOutOfDateBannerBeenDismissed, isMounted]);

	useEffect(() => {
		// If the banner has been dismissed, we don't want to show it again until after some delay
		if (hasOutOfDateBannerBeenDismissed) {
			const timerId = setTimeout(() => {
				if (isMounted) {
					setHasOutOfDateBannerBeenDismissed(false);
				}
			}, 5 * 60 * 1000);
			return () => {
				if (timerId) {
					clearTimeout(timerId);
				}
			};
		}
	}, [hasOutOfDateBannerBeenDismissed, isMounted]);

	const textColors: { [key in TesterShimStatusData['status']]: string } = {
		CONNECTED_DATA: colors.accentSuccessLight,
		CONNECTED_NO_DATA: colors.accentWarningLight,
		DISCONNECTED: colors.accentErrorLight
	};

	const iconColors: { [key in TesterShimStatusData['status']]: string } = {
		CONNECTED_DATA: colors.accentSuccess,
		CONNECTED_NO_DATA: colors.accentWarning,
		DISCONNECTED: colors.accentError
	};

	function renderStatusText(): string {
		if (localStorage.getItem('quiet')) return 'Disabled';

		if (testerStatus.status === 'CONNECTED_DATA') {
			return `Connected - v${testerStatus.version}`;
		} else if (testerStatus.status === 'CONNECTED_NO_DATA') {
			return `No Data - ${testerStatus.version}`;
		} else {
			return `Not Connected`;
		}
	}

	function renderOutOfDateBanner() {
		return (
			<Box className={'outOfDateBanner'}>
				<Icon
					className={'closeBtn'}
					iconImg={'icon-close'}
					fontSize={16}
					onClick={() => {
						setHasOutOfDateBannerBeenDismissed(true);
						setShowOutOfDateBanner(false);
					}}
				/>
				<Icon iconImg={'icon-warning'} fontSize={64} color={colors.accentWarning} />
				<Label variant={'h1'} weight={'semiBold'} color={colors.accentWarningDark}>
					WARNING - Version Out of Date
				</Label>
				<Label variant={'subheader1'} weight={'regular'} textAlign={'center'} color={colors.accentWarningDark}>
					Download the latest version now. <br />
					Current version {testerStatus.version} - Latest version {latestShimVersionDetails?.version}
				</Label>
				<Link external path={latestShimVersionDetails?.url || ''} className={'linkWrapper'}>
					<Button look={'outlinedPrimary'}>Download</Button>
				</Link>
			</Box>
		);
	}

	return (
		<Box className={'rsTesterStatus'}>
			{showOutOfDateBanner && renderOutOfDateBanner()}
			<Box display={'flex'} gap={10} alignItems={'center'}>
				<Icon iconImg={'icon-tester'} fontSize={16} color={iconColors[testerStatus.status]} />
				<Box>
					<Label variant={'caption1'} weight={'regular'} color={textColors[testerStatus.status]}>
						Tester Status
					</Label>
					<Label variant={'body1'} weight={'regular'} color={textColors[testerStatus.status]}>
						{renderStatusText()}
					</Label>
					{testerStatus.status === 'DISCONNECTED' && (
						<Link external path={latestShimVersionDetails?.url || ''}>
							<Label
								variant={'caption1'}
								weight={'regular'}
								color={textColors[testerStatus.status]}
								underline
							>
								Download
							</Label>
						</Link>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default TesterStatus;
