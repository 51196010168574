import * as React from 'react';
import './CalFixtureAssemblySection.scss';
import { Box, Button, Label, popupController, RsFormControl, rsToastify } from '@redskytech/framework/ui';
import { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';
import PageHeader from '../../../components/pageHeader/PageHeader';
import ConfirmPopup, { ConfirmPopupProps } from '../../../popups/confirmPopup/ConfirmPopup';
import { Link } from '@redskytech/framework/996';
import SelectableInputText from '../../../components/selectableInputText/SelectableInputText';
import { calibrationAssemblies } from '../../../services/calibration/calibration.data';
import { useEffect, useState } from 'react';
import CarrierStatus, { SlotDetails } from '../../../components/carrierStatus/CarrierStatus';
import { CalibrationFixtureType, CarrierType } from '../../../services/calibration/ICalibrationService';
import serviceFactory from '../../../services/serviceFactory';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import AssignCarrierPopup, { AssignCarrierPopupProps } from '../../../popups/assignCarrierPopup/AssignCarrierPopup';
import { WebUtils } from '../../../utils/utils';

interface CalFixtureAssemblySectionProps {
	hwid: HardwareIdDecoded;
	onDone: () => void;
}

const CalFixtureAssemblySection: React.FC<CalFixtureAssemblySectionProps> = (props) => {
	const calibrationService = serviceFactory.get('CalibrationService');
	const assemblyInfo = calibrationAssemblies.CALIBRATION_FIXTURE_ASSEMBLY;
	const [mainControl] = useState<RsFormControl<string>>(
		new RsFormControl(
			'main',
			`PN1:${props.hwid.partNumber},REV1:${props.hwid.hardwareRevision},SN1:${props.hwid.serialNumber}`
		)
	);
	const [calFixtureData, setCalFixtureData] = useState<
		Omit<Api.V1.CalFixture.By.Numbers.Get.Res, 'createdOn' | 'modifiedOn'>
	>({
		id: 0,
		hardwareRev: '',
		partNumber: '',
		serialNumber: ''
	});
	const [carrierCardList, setCarrierCardList] = useState<Api.V1.Carrier.Get.Res[]>([]);
	const [isModified, setIsModified] = useState<boolean>(false);
	const [fixtureType, setFixtureType] = useState<CalibrationFixtureType | undefined>();

	useEffect(() => {
		if (!props.hwid) return;

		setFixtureType(calibrationService.getCalibrationFixtureTypeFromPartNumber(props.hwid.partNumber));

		(async function lookupPart() {
			// Here we need to go and look up into the database to see if this assembly has already been built
			const res = await calibrationService.lookupFixtureByNumbers(props.hwid.partNumber, props.hwid.serialNumber);
			if (!res) {
				setCalFixtureData({
					id: 0,
					hardwareRev: props.hwid.hardwareRevision,
					partNumber: props.hwid.partNumber,
					serialNumber: props.hwid.serialNumber
				});
				return;
			}

			setCalFixtureData(res);

			// Request all carrier cards for this fixture
			const carrierLookupIds: number[] = [];
			const resAsAny = res as any;
			for (let i = 1; i <= 3; i++) {
				if (resAsAny[`imu${i}Id`]) carrierLookupIds.push(resAsAny[`imu${i}Id`]);
				if (resAsAny[`sib${i}Id`]) carrierLookupIds.push(resAsAny[`sib${i}Id`]);
				if (resAsAny[`magBaro${i}Id`]) carrierLookupIds.push(resAsAny[`magBaro${i}Id`]);
			}

			const carrierPromises = Array.from(carrierLookupIds).map((item) => {
				return ApiRequestV1.getCarrier({ id: item });
			});

			const carrierDetails = await Promise.all(carrierPromises);
			setCarrierCardList(carrierDetails);
		})();
	}, []);

	function handleDiscard() {
		if (!isModified) {
			props.onDone();
			return;
		}

		popupController.open<ConfirmPopupProps>(ConfirmPopup, {
			title: 'Discard Changes?',
			message: 'Are you sure you want to discard your unsaved changes?',
			confirmButtonText: 'Discard',
			onConfirm: () => {
				props.onDone();
			}
		});
	}

	async function handleSave(): Promise<boolean> {
		const calFixtureRequest: CustomTypes.CalFixtureRequest = {
			...(calFixtureData.id && { id: calFixtureData.id }),
			hardwareRev: calFixtureData.hardwareRev,
			partNumber: calFixtureData.partNumber,
			serialNumber: calFixtureData.serialNumber,
			imu1Id: calFixtureData.imu1Id || null,
			imu2Id: calFixtureData.imu2Id || null,
			imu3Id: calFixtureData.imu3Id || null,
			magBaro1Id: calFixtureData.magBaro1Id || null,
			magBaro2Id: calFixtureData.magBaro2Id || null,
			magBaro3Id: calFixtureData.magBaro3Id || null,
			sib1Id: calFixtureData.sib1Id || null,
			sib2Id: calFixtureData.sib2Id || null,
			sib3Id: calFixtureData.sib3Id || null
		};

		try {
			await ApiRequestV1.postCalFixture(calFixtureRequest);
			rsToastify.success('Successfully saved calibration fixture!', 'Saved Cal Fixture');
			setIsModified(false);
			return true;
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error saving calibration fixture.'), 'Cal Fixture Error');
			return false;
		}
	}

	async function handleSaveAndClose() {
		if (!(await handleSave())) return;
		props.onDone();
	}

	function handleClearAll() {
		setCalFixtureData((prevState) => {
			delete prevState.imu1Id;
			delete prevState.imu2Id;
			delete prevState.imu3Id;
			delete prevState.sib1Id;
			delete prevState.sib2Id;
			delete prevState.sib3Id;
			delete prevState.magBaro1Id;
			delete prevState.magBaro2Id;
			delete prevState.magBaro3Id;
			return prevState;
		});

		setCarrierCardList([]);
		setIsModified(true);
	}

	function renderRightHeaderNode() {
		return (
			<Box display={'flex'} gap={8}>
				<Button look={'outlinedPrimary'} onClick={handleDiscard}>
					{isModified ? 'Discard' : 'Done'}
				</Button>
				{isModified && (
					<>
						<Button look={'outlinedPrimary'} onClick={handleSave}>
							Save
						</Button>
						<Button look={'containedPrimary'} onClick={handleSaveAndClose}>
							Save & Close
						</Button>
					</>
				)}
			</Box>
		);
	}

	function renderTopAssemblyInfo() {
		return (
			<>
				<Link path={assemblyInfo.workInstructionsLink} external target={'blank'}>
					<Label
						variant={'body1'}
						weight={'regular'}
						className={'workInstructionsLink'}
						mb={32}
						display={'inline-block'}
					>
						Work Instructions
					</Label>
				</Link>
				<SelectableInputText
					labelTitle={assemblyInfo.label || ''}
					control={mainControl}
					isSelected={false}
					isFixed
					onClick={() => {}}
					updateControl={() => {}}
					onBlurOrEnter={async () => {
						return 'VALID';
					}}
				/>
				<Box className={'dividerLine'} margin={'32px 0'} />
				<Button look={'outlinedPrimary'} className={'clearBtn'} onClick={handleClearAll} ml={'auto'} mb={32}>
					CLEAR ALL
				</Button>
			</>
		);
	}

	function renderCarrierTray(row: number, carrierType: CarrierType) {
		if (fixtureType === 'SIX_AXIS' && carrierType === 'MAG_BARO') return;
		// remove all underscores from string
		let convertedCarrierType = '';
		switch (carrierType) {
			case 'IMU':
				convertedCarrierType = 'imu';
				break;
			case 'SIB':
				convertedCarrierType = 'sib';
				break;
			case 'MAG_BARO':
				convertedCarrierType = 'magBaro';
				break;
		}

		let foundCarrier: Api.V1.Carrier.Get.Res | undefined = undefined;
		const filledSlots: SlotDetails[] = [];

		const carrierId = (calFixtureData as any)[`${convertedCarrierType}${row}Id`];
		if (carrierId) {
			foundCarrier = carrierCardList.find((item) => item.id === carrierId);

			if (foundCarrier) {
				for (let i = 1; i <= 16; i++) {
					const slotName = `slot${i}Id`;
					if ((foundCarrier as any)[slotName]) {
						filledSlots.push({
							slotNumber: i,
							status: 'GOOD'
						});
					}
				}
			}
		}

		return (
			<CarrierStatus
				type={carrierType}
				slots={filledSlots}
				hwid={
					foundCarrier
						? {
								partNumber: foundCarrier.partNumber,
								hardwareRevision: foundCarrier.hardwareRev,
								serialNumber: foundCarrier.serialNumber
						  }
						: undefined
				}
				onConfigure={() => {
					popupController.open<AssignCarrierPopupProps>(AssignCarrierPopup, {
						carrierPosition: row,
						carrierType,
						...(!!foundCarrier && {
							hwid: `PN1:${foundCarrier.partNumber},REV1:${foundCarrier.hardwareRev},SN1:${foundCarrier.serialNumber}`
						}),
						onDone: async (carrier: Api.V1.Carrier.By.Numbers.Get.Res | undefined) => {
							setCalFixtureData((prevState) => {
								return {
									...prevState,
									[`${convertedCarrierType}${row}Id`]: carrier ? carrier.id : undefined
								};
							});

							setIsModified(true);

							if (
								carrier &&
								!carrierCardList.some((item) => {
									return item.id === carrier.id;
								})
							) {
								const existingCarrier = await ApiRequestV1.getCarrier({ id: carrier.id });
								setCarrierCardList([...carrierCardList, existingCarrier]);
							}
						}
					});
				}}
			/>
		);
	}

	function renderTitle(): string {
		let titleType = '';
		if (fixtureType === 'TEMPERATURE') titleType = 'Temperature ';
		else if (fixtureType === 'SIX_AXIS') titleType = 'Six Axis ';
		return `Assemble ${titleType}Calibration Fixture`;
	}

	return (
		<>
			<PageHeader title={renderTitle()} isModified={isModified} rightNode={renderRightHeaderNode()} />
			<Box className={'rsCalFixtureAssemblySection'}>
				{renderTopAssemblyInfo()}
				<Box className={'trayGroup'}>
					<Box className={'groupHeader'}>
						<Label textAlign={'center'} variant={'subheader1'} weight={'regular'}>
							Tray 1
						</Label>
					</Box>
					{renderCarrierTray(1, 'IMU')}
					{renderCarrierTray(1, 'SIB')}
					{renderCarrierTray(1, 'MAG_BARO')}
				</Box>
				<Box className={'trayGroup'}>
					<Box className={'groupHeader'}>
						<Label textAlign={'center'} variant={'subheader1'} weight={'regular'}>
							Tray 2
						</Label>
					</Box>
					{renderCarrierTray(2, 'IMU')}
					{renderCarrierTray(2, 'SIB')}
					{renderCarrierTray(2, 'MAG_BARO')}
				</Box>
				<Box className={'trayGroup'}>
					<Box className={'groupHeader'}>
						<Label textAlign={'center'} variant={'subheader1'} weight={'regular'}>
							Tray 3
						</Label>
					</Box>
					{renderCarrierTray(3, 'IMU')}
					{renderCarrierTray(3, 'SIB')}
					{renderCarrierTray(3, 'MAG_BARO')}
				</Box>
			</Box>
		</>
	);
};

export default CalFixtureAssemblySection;
