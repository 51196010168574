import * as React from 'react';
import { Box, Button, Icon, Label, RsDataTable, RsDataTableProps } from '@redskytech/framework/ui';
import {
	PaginatorCurrentPageReportOptions,
	PaginatorNextPageLinkOptions,
	PaginatorPrevPageLinkOptions,
	PaginatorTemplateOptions
} from 'primereact/paginator';
import colors from '../../themes/colors.scss?export';
import NoResultsMessage from './noResultsMessage/NoResultsMessage';
import { Column, ColumnFilterClearTemplateOptions, ColumnFilterApplyTemplateOptions } from 'primereact/column';
import classNames from 'classnames';

interface ListDataTableProps extends RsDataTableProps<any> {
	onClearAllFilters: () => void;
	onFilterClear: (field: string) => void;
	onFilterApply: (field: string) => void;
	checkForPageChange?: () => Promise<boolean>;
	currentPageQuery?: RedSky.PageQuery;
	isEmptyDataMessage?: boolean;
	initialRowsPerPage?: number;
}

const ListDataTable: React.FC<ListDataTableProps> = (props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { onClearAllFilters, onFilterClear, onFilterApply, ...baseProps } = props;
	const paginatorTemplate = {
		layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink',
		CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => (
			<Label
				variant={'caption1'}
				weight={'regular'}
				margin={'auto 64px'}
			>{`${options.first}-${options.last} of ${options.totalRecords}`}</Label>
		),
		PrevPageLink: (options: PaginatorPrevPageLinkOptions) => {
			return (
				<Icon
					color={options.disabled ? colors.neutralGrey400 : colors.neutralGrey900}
					fontSize={12}
					iconImg={'icon-chevron-left'}
					onClick={async (event) => {
						if (props.checkForPageChange && !(await props.checkForPageChange())) {
							return;
						}
						options.onClick(event);
					}}
					className={options.disabled ? 'notSelectable' : 'chevronSelect'}
				/>
			);
		},
		NextPageLink: (options: PaginatorNextPageLinkOptions) => (
			<Icon
				color={options.disabled ? colors.neutralGrey400 : colors.neutralGrey900}
				fontSize={12}
				iconImg={'icon-chevron-right'}
				cursorPointer={!options.disabled}
				onClick={async (event) => {
					if (props.checkForPageChange && !(await props.checkForPageChange())) {
						return;
					}
					options.onClick(event);
				}}
				className={options.disabled ? 'notSelectable' : 'chevronSelect'}
			/>
		)
	};

	function renderChildren() {
		return React.Children.map(props.children, (child: any) => {
			if (child?.props.filter) {
				return (
					<Column
						{...child.props}
						showFilterOperator={false}
						showFilterMatchModes={false}
						showFilterMenuOptions={false}
						showAddButton={false}
						filter={true}
						filterHeader={
							<Box display={'flex'} justifyContent={'center'} className={'filterHeader'}>
								<Label variant={'subheader1'} weight={'semiBold'} mb={8}>
									Filter Results
								</Label>
							</Box>
						}
						filterClear={(options: ColumnFilterClearTemplateOptions) => (
							<Button
								look={'outlinedPrimary'}
								onClick={() => {
									onFilterClear(options.field);
									options.filterClearCallback();
								}}
								fullWidth
							>
								Clear
							</Button>
						)}
						onFilterApplyClick={(options) => {
							onFilterApply(options.field);
						}}
						filterElement={(options: ColumnFilterApplyTemplateOptions) => {
							return (
								<child.props.filterElement.type
									{...child.props.filterElement.props}
									onKeyUp={(event: any) => {
										if (event.key === 'Enter') {
											onFilterApply(options.field);
											options.filterApplyCallback();
											const escapeKeyDownEvent = new KeyboardEvent('keydown', {
												key: 'Escape',
												bubbles: true,
												cancelable: true
											});
											event.target.dispatchEvent(escapeKeyDownEvent);
										}
									}}
								/>
							);
						}}
						filterApply={(options: ColumnFilterApplyTemplateOptions) => (
							<Button
								look={'containedPrimary'}
								onClick={() => {
									options.filterApplyCallback();
								}}
								fullWidth
							>
								Apply
							</Button>
						)}
					/>
				);
			}
			return child;
		});
	}

	return (
		<RsDataTable
			{...baseProps}
			className={classNames(props.className, { loading: baseProps.loading })}
			onFilter={() => {}}
			globalFilterDebounceMs={600}
			initialRowsPerPage={props.initialRowsPerPage}
			responsiveLayout={'scroll'}
			emptyMessage={<NoResultsMessage onClear={props.onClearAllFilters} />}
			paginatorTemplate={props.data.total ? (paginatorTemplate as PaginatorTemplateOptions) : undefined}
			rowClassName={() => 'tableRow'}
			paginatorLeft={
				props.data.total ? (
					<Label variant={'caption1'} weight={'regular'} mr={4}>
						Rows per page:
					</Label>
				) : undefined
			}
			paginatorClassName={props.data.total ? '' : 'hidden'}
			scrollable
			scrollHeight={props.scrollHeight || 'calc(100vh - 300px'}
		>
			{renderChildren()}
		</RsDataTable>
	);
};

export default ListDataTable;
