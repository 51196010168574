import * as React from 'react';
import './TestDetailsPage.scss';
import { Page } from '@redskytech/framework/996';
import colors from '../../themes/colors.scss?export';
import TestSelectorSection from './sections/TestSelectorSection';
import TestRunnerSection from './sections/TestRunnerSection';
import { IconProps } from '@redskytech/framework/ui';
import { Fixture } from '../../services/testFixture/ITestFixtureService';
import { useState } from 'react';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TestDetailsPageProps {}

export type TestStatus = 'ERROR' | 'SUCCESS' | 'WARNING' | 'INFO' | 'PENDING' | 'INCOMPLETE' | 'COMPLETE' | 'AUDITPASS';

export const StatusIconMap: { [key in TestStatus]: IconProps } = {
	ERROR: { iconImg: 'icon-fail', color: colors.accentErrorDark },
	SUCCESS: { iconImg: 'icon-check', color: colors.accentSuccess },
	WARNING: { iconImg: 'icon-warning', color: colors.accentWarning },
	INFO: { iconImg: 'icon-info', color: colors.accentInfo },
	PENDING: { iconImg: 'icon-pending', color: colors.neutralGrey700 },
	COMPLETE: { iconImg: 'icon-check', color: colors.accentSuccess },
	INCOMPLETE: { iconImg: 'icon-warning', color: colors.accentWarning },
	AUDITPASS: { iconImg: 'icon-check-circle', color: colors.accentSuccess }
};

const TestDetailsPage: React.FC<TestDetailsPageProps> = (_props) => {
	const [testFixture, setTestFixture] = useState<Fixture | undefined>();
	const [simulationMode, setSimulationMode] = useState<boolean>(false);
	const [assemblyHwid, setAssemblyHwid] = useState<HardwareIdDecoded | undefined>();
	const [parentAssemblyHwid, setParentAssemblyHwid] = useState<HardwareIdDecoded | undefined>(undefined);

	return (
		<Page className={'rsTestDetailsPage'}>
			{!testFixture || !assemblyHwid ? (
				<TestSelectorSection
					onTestFixtureSelected={(fixture, simulationModeEnabled, hardwareId, parentHwid) => {
						setTestFixture(fixture);
						setSimulationMode(simulationModeEnabled);
						setAssemblyHwid(hardwareId);
						setParentAssemblyHwid(parentHwid);
					}}
				/>
			) : (
				<TestRunnerSection
					testFixture={testFixture}
					assemblyHwid={assemblyHwid}
					simulationMode={simulationMode}
					parentAssemblyHwid={parentAssemblyHwid}
					onDone={() => {
						setTestFixture(undefined);
						setAssemblyHwid(undefined);
						setParentAssemblyHwid(undefined);
					}}
				/>
			)}
		</Page>
	);
};
export default TestDetailsPage;
