import * as React from 'react';
import {
	Box,
	LabelInputText,
	RsFormControl,
	RsFormGroup,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { ITestCriteria, validateTestCriteria } from '../../../../utils/testCriteria';

interface WindSpeedProps {
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	WIND_SPEED = 'windSpeedObserved'
}

const WindSpeed: React.FC<WindSpeedProps> = (props) => {
	const TEST_NAME: TestKey = 'windSpeed';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);
	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<string>(FormKeys.WIND_SPEED, res ? res.data[FormKeys.WIND_SPEED] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					// Check that the form key has a test criteria
					const testCriteria = props.testCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.WIND_SPEED
					);
					if (!testCriteria) {
						console.error(`Test criteria not found for ${FormKeys.WIND_SPEED}`);
						return false;
					}
					return validateTestCriteria(FormKeys.WIND_SPEED, control.value, props.testCriteria);
				})
			])
		]);
	}, [props.testCriteria, res]);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	useEffect(() => {
		setFormGroup(defineFormGroup());
	}, [defineFormGroup]);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME, undefined, undefined, props.testCriteria);
	}

	return (
		<Box className={'rsWindSpeed'}>
			<LabelInputText
				labelTitle={'Enter Observed Windspeed'}
				inputMode={'text'}
				type={'text'}
				control={formGroup.get(FormKeys.WIND_SPEED)}
				updateControl={handleUpdateControl}
				onBlur={() => {
					testFixtureService.validateFlightTest(
						formGroup,
						TEST_NAME,
						undefined,
						undefined,
						props.testCriteria
					);
				}}
			/>
		</Box>
	);
};

export default WindSpeed;
