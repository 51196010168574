import * as React from 'react';
import './RtspVideoPlayer.scss';
import { Box, Label, rsToastify } from '@redskytech/framework/ui';
import { useEffect, useRef, useState } from 'react';
import serviceFactory from '../../services/serviceFactory';

export interface JsMpegPlayer {
	play: () => void;
	pause: () => void;
	stop: () => void;
	nextFrame: () => boolean;
	volume: number;
	currentTime: number;
	paused: boolean;
	destroy: () => void;
}

export interface RtspVideoPlayerProps {
	url: string;
	xRes: number;
	yRes: number;
	play: boolean;
	onDisconnected: () => void;
	onClick: (x: number, y: number) => void;
}

// We have to put this so high because FLIR camera only sends out iframes every 8 seconds
const MAX_CONNECT_TIMEOUT_SECS = 15;

const RtspVideoPlayer: React.FC<RtspVideoPlayerProps> = (props) => {
	const player = useRef<JsMpegPlayer | null>(null);
	const container = useRef<HTMLDivElement>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		let lastPlayerTime = 0;
		let identicalPlayerTimes = 0;
		const intervalId = setInterval(() => {
			if (!player || !player.current) return;

			if (player.current?.currentTime === lastPlayerTime) {
				if (++identicalPlayerTimes === MAX_CONNECT_TIMEOUT_SECS) {
					identicalPlayerTimes = 0;
					props.onDisconnected();
				}
			} else {
				identicalPlayerTimes = 0;
				lastPlayerTime = player.current?.currentTime;
			}
		}, 1000);

		return () => {
			clearInterval(intervalId);

			// Destroy player if component is unmounting and it was playing
			if (player.current) {
				player.current.destroy();
				player.current = null;
			}
		};
	}, []);

	function handleClick(this: any, event: MouseEvent) {
		// Retrieve the position of the click relative to the canvas
		const rect = this.getBoundingClientRect();
		const x = event.clientX - rect.left;
		const y = event.clientY - rect.top;
		props.onClick(x, y);
	}

	useEffect(() => {
		if (!props.play && player.current) {
			player.current.destroy();
			player.current = null;

			// Remove canvas element created by JSMpeg
			const canvasElements = container.current!.querySelectorAll('canvas');
			canvasElements.forEach((canvas) => {
				canvas.removeEventListener('click', handleClick);
				canvas.parentNode!.removeChild(canvas);
			});

			setIsLoading(false);
			return;
		}

		if (!props.play) return;

		if (props.play && player.current) return;

		(async function StartStream() {
			try {
				const socketioService = serviceFactory.get('SocketioService');
				const url = await socketioService.launchVideoStream(props.url);

				// The player will delete the canvas element when it is destroyed, so we need to create a new one each time
				const newCanvas = document.createElement('canvas');
				container.current!.appendChild(newCanvas);

				// Add onclick event handler to the canvas
				newCanvas.addEventListener('click', handleClick);

				player.current = new window.JSMpeg.Player(url, {
					// for performance reasons, only record last packet Rx time if onDisconnect is specified
					canvas: newCanvas,
					reconnectInterval: 0,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					onVideoDecode: (data: any, elapsedTime: any) => {
						setIsLoading(false);
					}
				});
				setIsLoading(true);
			} catch (e: any) {
				rsToastify.error(e.message || 'Unable to load video stream', 'Error loading video');
			}
		})();
	}, [props.play]);

	return (
		<Box className={'rsRtspVideoPlayer'} width={props.xRes} height={props.yRes}>
			<Box className={'videoContainer'} elementRef={container} />
			{isLoading && (
				<Box className={'loadingIndicator'}>
					<Label variant={'h4'} weight={'semiBold'} color={'white'}>
						Loading...
					</Label>
				</Box>
			)}
		</Box>
	);
};

export default RtspVideoPlayer;
