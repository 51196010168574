import * as React from 'react';
import './TestList.scss';
import { Box, Button, RsFormControl, RsFormGroup, Select } from '@redskytech/framework/ui';
import { useEffect, useRef, useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import TestItem, { RunOption } from './testItem/TestItem';
import { PythonTest } from '../../services/testFixture/ITestFixtureService';

interface TestListProps {
	onRunTest: (runType: RunType) => void;
	onPauseTest: () => void;
	onAbortTest: () => void;
	testList: PythonTest[];
	currentRunningIndex?: number;
	currentSelectedIndex: number;
	onRunOptionClick: (option: RunOption, stepIndex: number, runType: RunType) => void;
	onTestItemClick: (stepIndex: number) => void;
	isPaused: boolean;
	isConnected: boolean;
}

export type RunType = 'firstFail' | 'continueFail';

const TestList: React.FC<TestListProps> = (props) => {
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl('testSelect', 'firstFail')])
	);
	const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);
	const listRef = useRef<HTMLDivElement>(null);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function renderTestList() {
		return props.testList.map((test, index) => {
			return (
				<TestItem
					key={index}
					test={test}
					isSelected={props.currentSelectedIndex === index}
					isRunning={props.currentRunningIndex === index}
					onRunOptionClick={(option) =>
						props.onRunOptionClick(option, index, formGroup.get<string>('testSelect').value as RunType)
					}
					onTestItemClick={() => props.onTestItemClick(index)}
					scrollbarWidth={scrollbarWidth}
					isMenuDisabled={!props.isConnected || props.currentRunningIndex !== undefined}
				/>
			);
		});
	}

	useEffect(() => {
		const container = listRef.current;
		if (!container) return;
		const hasScrollbar = container.scrollHeight > container.clientHeight;
		if (hasScrollbar) {
			setScrollbarWidth(container.offsetWidth - container.clientWidth);
		}
	}, []);

	return (
		<Box className={'rsTestList'} elementRef={listRef}>
			<Box padding={16}>
				<Select<{ label: string; value: RunType }>
					options={[
						{ label: 'Run to First Fail', value: 'firstFail' },
						{ label: 'Continue with Fails', value: 'continueFail' }
					]}
					control={formGroup.get('testSelect')}
					updateControl={handleUpdateControl}
				/>
				{props.isPaused || props.currentRunningIndex !== undefined ? (
					<Box display={'flex'} gap={16} margin={`16px 0 8px`}>
						<Button
							look={'outlinedPrimary'}
							fullWidth
							onClick={() => {
								props.onAbortTest();
							}}
						>
							Abort
						</Button>
						<Button
							look={props.isPaused ? 'containedPrimary' : 'outlinedPrimary'}
							fullWidth
							onClick={() => {
								props.onPauseTest();
							}}
						>
							{props.isPaused ? 'Continue' : 'Pause'}
						</Button>
					</Box>
				) : (
					<Button
						look={'containedPrimary'}
						onClick={() => {
							props.onRunTest(formGroup.get('testSelect').value as RunType);
						}}
						fullWidth
						margin={`16px 0 8px`}
						disabled={!props.isConnected}
					>
						Run Tests
					</Button>
				)}
			</Box>
			{renderTestList()}
		</Box>
	);
};
export default TestList;
