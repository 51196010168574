import * as React from 'react';
import './CreateUserPopup.scss';
import {
	Box,
	Button,
	Popup,
	popupController,
	PopupProps,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import UserDetailsForm, { UserDetailsFormKeys } from '../../components/userDetailsForm/UserDetailsForm';
import { useEffect, useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { WebUtils } from '../../utils/utils';

export interface CreateUserPopupProps extends PopupProps {
	onCreate: () => void;
}

const CreateUserPopup: React.FC<CreateUserPopupProps> = (props) => {
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(UserDetailsFormKeys.FIRST_NAME, '', [
				new RsValidator(RsValidatorEnum.REQ, 'First name is required.')
			]),
			new RsFormControl<string>(UserDetailsFormKeys.LAST_NAME, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Last name is required.')
			]),
			new RsFormControl<string>(UserDetailsFormKeys.EMAIL, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Email is required.'),
				new RsValidator(RsValidatorEnum.EMAIL, 'Email is invalid.')
			]),
			new RsFormControl<string>(UserDetailsFormKeys.ROLE, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Role is required.')
			]),
			new RsFormControl<string>(UserDetailsFormKeys.PASSWORD, '', [
				new RsValidator(RsValidatorEnum.REQ, 'Password is required.')
			])
		])
	);

	useEffect(() => {
		(async () => {
			const isValid = await formGroup.isValid(true);
			setIsDisabled(!isValid);
		})().catch(console.error);
	}, [formGroup]);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	async function handleCreate() {
		const updatedValues = formGroup.toModel<Api.V1.User.Post.Req>();
		updatedValues.password = await WebUtils.sha256Encode(updatedValues.password);
		try {
			await ApiRequestV1.postUser(updatedValues);
			setFormGroup(formGroup.clone().updateInitialValues());
			props.onCreate();
			popupController.close(CreateUserPopup);
			rsToastify.success('User created successfully.');
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsCreateUserPopup'}>
				<PopupHeader
					title={'Create User'}
					onClose={() => {
						popupController.close(CreateUserPopup);
					}}
				/>
				<UserDetailsForm formGroup={formGroup} onUpdateControl={handleUpdateControl} isCreate />
				<Box className={'buttonGroup'}>
					<Button
						look={'outlinedPrimary'}
						onClick={() => {
							popupController.close(CreateUserPopup);
						}}
						fullWidth
					>
						Cancel
					</Button>
					<Button look={'containedPrimary'} onClick={handleCreate} fullWidth disabled={isDisabled}>
						Create
					</Button>
				</Box>
			</Box>
		</Popup>
	);
};
export default CreateUserPopup;
