import * as React from 'react';
import './CalibrationTestSection.scss';
import { Box, Button, Label, popupController, rsToastify } from '@redskytech/framework/ui';
import PageHeader from '../../../components/pageHeader/PageHeader';
import { ObjectUtils, StringUtils, WebUtils } from '../../../utils/utils';
import ResultsList from '../../../components/resultsList/ResultsList';
import serviceFactory from '../../../services/serviceFactory';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState, { getRecoilExternalValue } from '../../../state/globalState';
import {
	CalibrationFixtureType,
	CalibrationOutput,
	FileData,
	FullCalibrationFixtureDetails,
	FullCalibrationTrayDetails,
	PartData,
	SlotResultStatus
} from '../../../services/calibration/ICalibrationService';
import { TestPrompt } from '../../../services/testFixture/ITestFixtureService';
import { useEffect, useRef, useState } from 'react';
import PromptItem from '../../../components/promptItem/PromptItem';
import ConfirmPopup, { ConfirmPopupProps } from '../../../popups/confirmPopup/ConfirmPopup';
import CarrierStatus, { SlotDetails } from '../../../components/carrierStatus/CarrierStatus';
import { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import LoadingPopup, { LoadingPopupProps } from '../../../popups/loadingPopup/LoadingPopup';
import useWarnOnUnsavedChanges from '../../../customHooks/useWarnOnUnsavedChanges';
import FileUploadProgressPopup, {
	FileUploadProgressPopupProps
} from '../../../popups/fileUploadProgressPopup/FileUploadProgressPopup';

interface CalibrationTestSectionProps {
	calibrationConfig: FullCalibrationFixtureDetails;
	fixtureType: CalibrationFixtureType;
	partData: PartData[];
	onDone: () => void;
}
let interval: NodeJS.Timeout | undefined;

const CalibrationTestSection: React.FC<CalibrationTestSectionProps> = (props) => {
	const socketioService = serviceFactory.get('SocketioService');
	const isCalibrationConnected = useRecoilValue<boolean>(globalState.isCalibrationConnected);
	const [calibrationOutput, setCalibrationOutput] = useRecoilState<CalibrationOutput[]>(
		globalState.calibrationOutput
	);
	const [testPrompts, setTestPrompts] = useRecoilState<TestPrompt[]>(globalState.testPrompts);
	const [isModified, setIsModified] = useState<boolean>(false);
	const [isRunning, setIsRunning] = useState<boolean>(false);
	const [slotStatuses, setSlotStatuses] = useRecoilState<SlotResultStatus[]>(globalState.slotResultStatuses);
	const [connectionGuid] = useState<string>(() => StringUtils.generateGuid());
	const [uploadedFileData, setUploadedFileData] = useRecoilState<
		{ partId: number; tray: number; position: number; fileUrl: string; fileSize: string }[]
	>(globalState.uploadedFileData);
	const sentFileData = useRef<{ partData: { partId: number; tray: number; position: number }; filePath: string }[]>(
		[]
	);
	const hasUpdated = useRef<boolean>(false);
	const resultId = useRef<number>();
	const fileUploadProgressPopupId = useRef<number>();

	useWarnOnUnsavedChanges(isModified);

	useEffect(() => {
		if (!isModified) return;
		interval = setInterval(async () => {
			if (!hasUpdated.current) return;
			saveTestOutput(getRecoilExternalValue(globalState.calibrationOutput)).catch(console.error);
			hasUpdated.current = false;
		}, 5000);

		return () => clearInterval(interval);
	}, [isModified]);

	useEffect(() => {
		if (!isModified || hasUpdated.current) return;
		hasUpdated.current = true;
	}, [calibrationOutput]);

	useEffect(() => {
		if (!isCalibrationConnected) {
			setIsRunning(false);
			setTestPrompts([]);
			setCalibrationOutput((prev) => {
				return [
					{
						name: 'log',
						severity: 'trace',
						message: 'Test was exited',
						timeStamp: new Date().toLocaleTimeString([], {
							hour: '2-digit',
							minute: '2-digit',
							second: '2-digit',
							hour12: true
						})
					},
					...prev
				];
			});
		}
	}, [isCalibrationConnected]);

	useEffect(() => {
		// Reset when we first mount
		setCalibrationOutput([]);
		setTestPrompts([]);
		setSlotStatuses(getInitialFilledSlots());
		setUploadedFileData([]);
	}, []);

	useEffect(() => {
		if (!slotStatuses.length) return;
		const fileData: { partData: FileData['partData']; filePath: string }[] = [];
		slotStatuses.forEach((status) => {
			status.files.forEach((filePath) => {
				const data = {
					partData: {
						partId: getPartId(status),
						tray: status.tray,
						type: status.type,
						position: status.position,
						connectionGuid
					},
					filePath
				};
				if (
					!sentFileData.current.some(
						(sentData) =>
							sentData.partData.partId === data.partData.partId && sentData.filePath === data.filePath
					)
				) {
					fileData.push(data);
					sentFileData.current.push(data);
				}
			});
		});
		if (!fileData.length) return;
		socketioService.getFileData(fileData);
	}, [slotStatuses]);

	useEffect(() => {
		if (isRunning || !slotStatuses.length) return;

		const totalFilesInSlotStatuses = slotStatuses.reduce(
			(accumulator, current) => accumulator + current.files.length,
			0
		);

		if (totalFilesInSlotStatuses === uploadedFileData.length) {
			if (fileUploadProgressPopupId.current) {
				popupController.closeById(fileUploadProgressPopupId.current);
				fileUploadProgressPopupId.current = undefined;
			}
			return;
		}

		if (!fileUploadProgressPopupId.current) {
			fileUploadProgressPopupId.current = popupController.open<FileUploadProgressPopupProps>(
				FileUploadProgressPopup,
				{
					totalFiles: totalFilesInSlotStatuses
				}
			);
		}
	}, [slotStatuses, uploadedFileData, isRunning]);

	function getInitialFilledSlots(): SlotResultStatus[] {
		const positions: SlotResultStatus[] = [];
		props.calibrationConfig.trays.forEach((tray) => {
			tray.slots?.forEach((slot) => {
				slot.boards?.forEach((board) => {
					if (!board.empty) {
						positions.push({
							tray: tray.position,
							type: StringUtils.convertCarrierType(slot.type),
							position: board.position,
							status: 'GOOD',
							files: []
						});
					}
				});
			});
		});
		return positions;
	}

	function getPartId(slotStatus: SlotResultStatus): number {
		const part = props.partData.find((part) => {
			return (
				slotStatus.tray === part.tray && slotStatus.type === part.type && slotStatus.position === part.position
			);
		});
		if (!part) return 0;
		return part.partId;
	}

	async function saveTestOutput(output: CalibrationOutput[]) {
		if (!output.length || !resultId.current) return;
		await ApiRequestV1.patchResult({
			id: resultId.current,
			data: JSON.stringify(output)
		});
	}

	async function handleRunTest() {
		popupController.open<LoadingPopupProps>(LoadingPopup, {});
		try {
			// initialize test results for each part
			const res = await ApiRequestV1.postResult({ data: JSON.stringify([]) });
			resultId.current = res.id;
			const partPromises = props.partData.map((part) => {
				return ApiRequestV1.postTestResult({
					partId: part.partId,
					connectionGuid: `${connectionGuid}_${part.partId}-${part.tray}-${part.position}`,
					testName: `Board Calibration - ${StringUtils.convertEnumToHuman(props.fixtureType)}`,
					status: 'INCOMPLETE',
					isComplete: false,
					hasPassed: false,
					resultId: res.id
				});
			});
			await Promise.all(partPromises);
			setIsModified(true);
			socketioService.sendCalibrationInput({
				name: 'command',
				command: 'configure',
				data: StringUtils.convertObjectValuesToStrings(props.calibrationConfig)
			});
			setIsRunning(true);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown'), 'Server Error');
		}
		popupController.close(LoadingPopup);
	}

	function handleDisconnectTest() {
		socketioService.sendCalibrationInput({
			name: 'command',
			command: 'abort'
		});
		setTestPrompts([]);
		setCalibrationOutput([]);
		setSlotStatuses([]);
		props.onDone();
	}

	function handleAbort() {
		popupController.open<ConfirmPopupProps>(ConfirmPopup, {
			title: 'Abort?',
			onConfirm: () => {
				socketioService.sendCalibrationInput({
					name: 'command',
					command: 'abort'
				});
				popupController.close(ConfirmPopup);
			},
			message: 'Are you sure you want to stop testing?',
			onCancel: () => {
				popupController.close(ConfirmPopup);
			},
			confirmButtonText: 'Abort'
		});
	}

	async function handleDisconnectAndSave() {
		try {
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			await saveTestOutput(calibrationOutput);
			rsToastify.success('Test results saved successfully.', 'Test Results Saved');
			handleDisconnectTest();
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown'), 'Server Error');
		}
		popupController.close(LoadingPopup);
	}

	function renderPrompts() {
		if (!ObjectUtils.isArrayWithData(testPrompts)) return;
		return testPrompts
			.sort((a, b) => {
				return a.position - b.position;
			})
			.map((prompt) => {
				return <PromptItem key={Math.random() * 1000} {...prompt} disabled={false} />;
			});
	}

	function renderHeaderButtons() {
		if (isModified) {
			return (
				<Box display={'flex'} gap={16}>
					<Button look={'outlinedPrimary'} onClick={handleAbort}>
						Abort
					</Button>
					<Button look={'containedPrimary'} onClick={handleDisconnectAndSave} disabled={isRunning}>
						Disconnect & Save
					</Button>
				</Box>
			);
		}
		return (
			<Box display={'flex'} gap={16}>
				<Button look={'outlinedPrimary'} onClick={handleDisconnectTest}>
					Disconnect
				</Button>
				<Button look={'containedPrimary'} onClick={handleRunTest}>
					Run Test
				</Button>
			</Box>
		);
	}

	function renderCalibrationStatuses(tray: FullCalibrationTrayDetails) {
		if (!tray.slots || !tray.slots.length) {
			const carriers = [
				<CarrierStatus key={`${tray.position}-IMU`} type={'IMU'} slots={[]} />,
				<CarrierStatus key={`${tray.position}-SIB`} type={'SIB'} slots={[]} />
			];
			if (props.fixtureType !== 'SIX_AXIS') {
				carriers.push(<CarrierStatus key={`${tray.position}-MAG_BARO`} type={'MAG_BARO'} slots={[]} />);
			}
			return carriers;
		}
		return tray.slots.map((slot) => {
			const type = StringUtils.convertCarrierType(slot.type);
			if (type === 'MAG_BARO' && props.fixtureType === 'SIX_AXIS')
				return <React.Fragment key={`${tray.position}-MAG_BARO`}></React.Fragment>;

			const slots: SlotDetails[] = [];
			slotStatuses.forEach((status) => {
				if (status.tray === tray.position && status.type === type)
					slots.push({ slotNumber: status.position, status: status.status });
			});

			let hwid: HardwareIdDecoded | undefined;
			if (slot.partNumber && slot.serialNumber && slot.hardwareRev) {
				hwid = {
					partNumber: slot.partNumber,
					serialNumber: slot.serialNumber,
					hardwareRevision: slot.hardwareRev
				};
			}

			return <CarrierStatus key={`${tray.position}-${type}`} type={type} slots={slots} hwid={hwid} />;
		});
	}

	return (
		<Box className={'rsCalibrationTestSection'}>
			<PageHeader
				title={'Board Calibration Test'}
				centerNode={isRunning && `${'x'} minute${'s'} remaining`}
				rightNode={renderHeaderButtons()}
				isModified={isModified}
			/>
			<Box margin={32}>
				<Label variant={'h3'} weight={'semiBold'} mb={32}>
					{`Test ${StringUtils.convertEnumToHuman(props.fixtureType)}`}
				</Label>
				<Box maxWidth={1214}>
					{!!testPrompts.length && <Box className={'messageContainer'}>{renderPrompts()}</Box>}
					{props.calibrationConfig.trays.map((tray) => {
						return (
							<Box className={'trayGroup'} key={`tray_${tray.position}`}>
								<Box className={'groupHeader'}>
									<Label textAlign={'center'} variant={'subheader1'} weight={'regular'}>
										Tray {tray.position}
									</Label>
								</Box>
								{renderCalibrationStatuses(tray)}
							</Box>
						);
					})}
				</Box>
				{!!calibrationOutput.length && (
					<ResultsList
						results={calibrationOutput}
						maxWidth={1214}
						maxHeight={500}
						mt={32}
						showRawData
						showTestCriteria
					/>
				)}
			</Box>
		</Box>
	);
};
export default CalibrationTestSection;
