import * as React from 'react';
import { Box, Checkbox, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState, useMemo, useCallback } from 'react';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { ITestCriteria } from '../../../../utils/testCriteria';

interface MagCorrectionValidationProps {
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	MAG_CORRECTION_VALIDATION = 'verifyMagCorrectionVisually'
}

const MagCorrectionValidation: React.FC<MagCorrectionValidationProps> = (props) => {
	const TEST_NAME: TestKey = 'magCorrectionValidation';
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);
	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<boolean>(
				FormKeys.MAG_CORRECTION_VALIDATION,
				res ? res.data[FormKeys.MAG_CORRECTION_VALIDATION] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			)
		]);
	}, [res]);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME, undefined, undefined, props.testCriteria);
	}

	return (
		<Box className={'rsMagCorrectionValidation'}>
			<Checkbox
				labelText={'Visually confirm that the landmark is in the center 3rd of the un-zoomed video.'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.MAG_CORRECTION_VALIDATION)}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default MagCorrectionValidation;
