import { Service } from '../Service';
import globalState, { getRecoilExternalValue, setRecoilExternalValue } from '../../state/globalState';
import { PythonTest, TestFixtureType, TestResult } from './ITestFixtureService';
import { testFixtures, testFixtureTestMap } from './testFixture.data';
import { RsFormControl, RsFormGroup, rsToastify } from '@redskytech/framework/ui';
import { TestKey } from '../../pages/flightTestPage/sections/FlightTestSection';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { EvaluationOptions, EvaluationGroup } from '../../components/evaluationItem/EvaluationItem';
import { ITestCriteria } from '../../utils/testCriteria';
import { PartAssemblyType } from '../assembly/IAssemblyService';

export default class TestFixtureService extends Service {
	getTestFixtureTypeFromPartNumber(partNumber: string): TestFixtureType | undefined {
		let testFixtureKey: TestFixtureType;
		for (testFixtureKey in testFixtures) {
			if (testFixtures[testFixtureKey].partNumbers.includes(partNumber)) return testFixtureKey;
		}
		return undefined;
	}

	getTestSessionNameFromTestKey(testName: string, assemblyType: PartAssemblyType): string {
		let testSessionName = '';
		for (const testFixtureKey in testFixtureTestMap[assemblyType]) {
			if (
				testFixtureTestMap[assemblyType][testFixtureKey as TestFixtureType]!.tests.some(
					(test: PythonTest) => test.testName === testName
				)
			) {
				testSessionName = testFixtureTestMap[assemblyType][testFixtureKey as TestFixtureType]!.name;
			}
		}
		return testSessionName;
	}

	getListOfTestsByTestName(testName: string): string[] {
		const testList: string[] = [];
		let assemblyKey: keyof typeof testFixtureTestMap;
		for (assemblyKey in testFixtureTestMap) {
			let testFixtureKey: keyof typeof testFixtureTestMap[typeof assemblyKey];
			for (testFixtureKey in testFixtureTestMap[assemblyKey]) {
				if (testFixtureTestMap[assemblyKey][testFixtureKey]!.name === testName) {
					testFixtureTestMap[assemblyKey][testFixtureKey]!.tests.forEach((test) => {
						testList.push(test.testName);
					});
				}
			}
		}
		return testList;
	}

	nextTest() {
		setRecoilExternalValue(globalState.testFixtureStatus, (prev) => {
			return {
				...prev,
				currentTestIndex: prev.currentTestIndex + 1
			};
		});
	}

	isNumber(str: string) {
		const numberRegex = /^[-+]?[0-9]{1,3}(,[0-9]{3})*(\.[0-9]*)?$/;
		return numberRegex.test(str);
	}

	controlValueToNumber(control: RsFormControl<IRsFormControl>): number | undefined {
		if (typeof control.value === 'number') {
			// control.value is a number
			return control.value;
		} else if (typeof control.value === 'string') {
			// if the string contains extra info that isn't a number then we should return false
			if (!this.isNumber(control.value)) return undefined;

			const value = parseFloat(control.value);
			// Check for Nan, which shouldn't really ever happen at this point.
			if (isNaN(value)) {
				return undefined;
			}
			return value;
		}
	}

	// Check if a number is in a range ( inclusive )
	validateResultInRange(control: RsFormControl<IRsFormControl>, minValue: number, maxValue: number, absVal = false) {
		//
		// default to an invalid value.  This will be overridden if the value is valid
		let value = -1;

		// the value is allowed to be zero, so we need to be more explicit here.
		if (control.value !== undefined && control.value !== '') {
			if (typeof control.value === 'number') {
				// control.value is a number

				value = control.value;
			} else if (typeof control.value === 'string') {
				// if the string contains extra info that isn't a number then we should return false
				if (!this.isNumber(control.value)) return false;
				value = parseFloat(control.value);
				// Check for Nan, which shouldn't really ever happen at this point.
				if (isNaN(value)) {
					return false;
				}
			} else {
				rsToastify.error('Value must be a number', 'Test Value type error.');
			}

			if (absVal) {
				value = Math.abs(value);
			}
			return !(value < minValue || value > maxValue);
		}
		return false;
	}

	// In this code Worst means a higher abs value, and best means a lower abs value
	// Check if a number is in a range ( inclusive )
	validateAbsResultRssiInRangeWithRelative(
		currentValue: number,
		absBestValueMax: number,
		absWorstValueMax: number,
		neighborValue: number,
		absMaxNeighborDelta: number,
		aligned = false
	): boolean {
		const valueAbs = Math.abs(currentValue);
		const neighborValueAbs = Math.abs(neighborValue);
		// If the aircraft is aligned we have a minimum threshold
		if (aligned) {
			// If there is a gap between the value and the neighbor value that is greater than the max delta, return false
			if (Math.abs(valueAbs - neighborValueAbs) > absMaxNeighborDelta) {
				return false;
			}
		} else {
			if (Math.abs(valueAbs - neighborValueAbs) < absMaxNeighborDelta) {
				return false;
			}
		}
		// Identify the correct threshold, if we are the biggest value, use the worst value threshold, otherwise use the best value threshold
		const threshold = valueAbs <= neighborValueAbs ? absBestValueMax : absWorstValueMax;

		return valueAbs <= threshold;
	}

	hasPropertyValue(value: EvaluationOptions | undefined, evaluations: EvaluationGroup) {
		for (const key in evaluations) {
			if (evaluations[key] === value) {
				return true;
			}
		}
		return false;
	}

	validateEvaluationTest(
		testName: string,
		evaluations: EvaluationGroup,
		overrideField?: undefined | string,
		criteria?: undefined | ITestCriteria[]
	) {
		const prevResult = getRecoilExternalValue(globalState.testResults).findIndex(
			(prevRes) => prevRes.testName === testName
		);
		if (evaluations === undefined) return;

		const evalKeys = Object.keys(evaluations);

		// exclude the override Field from the validation exclusion list
		const nonOverrideEvaluations = evalKeys.filter((evalKey) => evalKey !== overrideField);
		let hasFail = false;
		if (overrideField !== undefined) {
			if (nonOverrideEvaluations.some((evalKey) => evaluations[evalKey] === undefined)) return;
			hasFail = nonOverrideEvaluations.some((evalKey) => evaluations[evalKey] === 'FAILURE');
		} else {
			if (this.hasPropertyValue(undefined, evaluations)) return;
			hasFail = this.hasPropertyValue('FAILURE', evaluations);
		}

		if (overrideField && overrideField in evaluations) {
			// This allows us to say that the item has passed no matter what other items in the group say!
			if (evaluations[overrideField] !== undefined) {
				hasFail = evaluations[overrideField] === 'FAILURE';
			}
		}

		// make the output structure, but replace undefined items with ''
		const evals: { [key: string]: string } = {};
		evalKeys.forEach((key) => {
			evals[key] = evaluations[key] || '';
		});

		setRecoilExternalValue(globalState.testResults, (prevState) => {
			const updatedState = [...prevState];
			if (prevResult < 0) {
				const result: TestResult = {
					testName: testName,
					data: evals,
					testCriteria: criteria || [],
					timeStamp: new Date().toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
						hour12: true
					}),
					passed: !hasFail,
					continuable: true
				};
				return [...updatedState, result];
			}
			updatedState[prevResult] = {
				...updatedState[prevResult],
				data: evals,
				testCriteria: criteria || [],
				timeStamp: new Date().toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
					hour12: true
				}),
				passed: !hasFail
			};
			return updatedState;
		});
	}

	async validateFlightTest(
		formGroup: RsFormGroup,
		testName: TestKey,
		validateAll = false,
		overrideField?: undefined | string,
		criteria?: undefined | ITestCriteria[]
	) {
		const controls = formGroup.getControls();
		const prevResult = getRecoilExternalValue(globalState.testResults).findIndex(
			(prevRes) => prevRes.testName === testName
		);

		// exclude the override Field from the validation exclusion list
		const nonOverrideControls = controls.filter((control) => control.key !== overrideField);
		if (
			!validateAll &&
			nonOverrideControls.some((control) => {
				return typeof control.value === 'string' && control.value.trim() === '';
			}) &&
			prevResult < 0
		) {
			return;
		}

		let isValid = true;

		if (overrideField) {
			await Promise.all(
				// We can use the global isValid in the case where overrides are needed
				// so we do them one at a time, excluding the override field.
				nonOverrideControls.map(async (control) => {
					let currentItem = false;
					currentItem = await control.validate(true);
					if (!currentItem) {
						isValid = false;
					}
				})
			);
		} else {
			isValid = await formGroup.isValid(true);
		}

		if (overrideField) {
			// Regardless of the other results, if we have met sufficient citeria to validate the test, we can override the result
			// the default field is an empty string, if it is not empty, apply the override, otherwise,
			// the results of the rest of the test will stand.
			try {
				const overrideValue = formGroup.get(overrideField).value;
				if (overrideValue !== undefined && overrideValue !== '') {
					isValid = formGroup.get(overrideField).value === true;
				}
			} catch (e) {
				console.log('unable to find required override field in formgroup', e);
			}
		}

		// All of the data is saved, if override field was an empty string and it passed then
		// no override was needed to pass.
		setRecoilExternalValue(globalState.testResults, (prevState) => {
			const updatedState = [...prevState];
			if (prevResult < 0) {
				const result: TestResult = {
					testName: testName,
					data: formGroup.toModel(),
					testCriteria: criteria || [],
					timeStamp: new Date().toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
						hour12: true
					}),
					passed: isValid,
					continuable: true
				};
				return [...updatedState, result];
			}
			updatedState[prevResult] = {
				...updatedState[prevResult],
				data: formGroup.toModel(),
				testCriteria: criteria || [],
				timeStamp: new Date().toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
					hour12: true
				}),
				passed: isValid
			};
			return updatedState;
		});
	}
}
