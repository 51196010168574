import * as React from 'react';
import { Box, Icon, Label } from '@redskytech/framework/ui';
import StatusChip from '../statusChip/StatusChip';
import { ObjectUtils } from '../../utils/utils';
import { useState } from 'react';
import { TestStatus } from '../../pages/testDetailsPage/TestDetailsPage.js';
import { ITestCriteria } from '../../utils/testCriteria';
import './ResultItem.scss';

interface ResultItemProps {
	name: string;
	hasNotRan?: true;
	passed?: boolean;
	timeStamp: string;
	comment?: string;
	error?: any;
	data?: any;
	rawData?: any;
	testCriteria?: ITestCriteria[];
	other?: any[];
}

const ResultItem: React.FC<ResultItemProps> = (props) => {
	const [isRawDataExpanded, setIsRawDataExpanded] = useState<boolean>(false);
	const [isTestCriteriaExpanded, setIsTestCriteriaExpanded] = useState<boolean>(false);

	function translateOperator(operator: string): string {
		if (operator === 'lt') return '<';
		if (operator === 'lte') return '<=';
		if (operator === 'eq') return '==';
		if (operator === 'gt') return '>';
		if (operator === 'gte') return '>=';
		if (operator === 'exists') return 'exists';
		if (operator === 'not exists') return 'not exists';
		if (operator === 'neq') return '!=';

		return '';
	}

	function getTestCriteriaString(data: ITestCriteria[]): string {
		// the data is a list of objects , we need to pull out and make a list of the field names, and all of the operators and values, then output strings for each field name
		let criteriaString = '';
		if (!ObjectUtils.isArrayWithData(data)) return data;
		// for each testCriteria in the object print out the field name, operator, and value
		data.forEach((criteria) => {
			criteriaString += `${criteria.fieldName} ${translateOperator(criteria.operator)} ${JSON.stringify(
				criteria.value
			)}\n`;
		});

		return criteriaString;
	}

	function getDataString(data: any): string {
		if (!ObjectUtils.isObject(data)) return data;
		// extract every key from the JSON
		const entryList: string[] = [];
		for (const key in data) {
			const valueString = JSON.stringify(data[key]);
			const spacedString = valueString.replaceAll(',', ', ');
			entryList.push(`${key}: ${spacedString}`);
		}

		return entryList.join('\n');
	}

	function convertStatusType(): TestStatus {
		if (props.hasNotRan) return 'INCOMPLETE';
		if (props.passed) return 'SUCCESS';
		return 'ERROR';
	}

	function renderOtherData() {
		if (!props.other?.length) return <></>;
		return props.other.map((dataSet, index) => (
			<Label key={`label${index}`} variant={'body1'} weight={'regular'} padding={8} whiteSpace={'pre-line'}>
				{getDataString(dataSet)}
			</Label>
		));
	}

	function renderRawData() {
		if (!props.rawData) return;
		if (props.rawData.length === 0) return;
		return (
			<>
				<Box
					onClick={() => {
						setIsRawDataExpanded(!isRawDataExpanded);
					}}
					className={'rawDataDropdown'}
				>
					<Label variant={'body1'} weight={'semiBold'}>
						Raw Data
					</Label>
					<Icon iconImg={isRawDataExpanded ? 'icon-chevron-down' : 'icon-chevron-up'} fontSize={12} />
				</Box>
				{isRawDataExpanded && (
					<Label variant={'body1'} weight={'regular'} px={8} whiteSpace={'pre-line'}>
						{getDataString(props.rawData)}
					</Label>
				)}
			</>
		);
	}

	function renderTestCriteria() {
		if (!props.testCriteria) return;
		if (props.testCriteria.length === 0) return;
		return (
			<>
				<Box
					onClick={() => {
						setIsTestCriteriaExpanded(!isTestCriteriaExpanded);
					}}
					className={'testCriteriaDropdown'}
				>
					<Label variant={'body1'} weight={'semiBold'}>
						Test Criteria
					</Label>
					<Icon iconImg={isTestCriteriaExpanded ? 'icon-chevron-down' : 'icon-chevron-up'} fontSize={12} />
				</Box>
				{isTestCriteriaExpanded && (
					<Label variant={'body1'} weight={'regular'} px={8} whiteSpace={'pre-line'}>
						{getTestCriteriaString(props.testCriteria)}
					</Label>
				)}
			</>
		);
	}

	function renderCommentData() {
		if (!props.comment) return <></>;

		return (
			<Label variant={'body1'} weight={'regular'} padding={8} whiteSpace={'pre-line'}>
				Comment: {props.comment}
			</Label>
		);
	}

	return (
		<Box className={'rsResultItem'}>
			<Box position={'relative'}>
				<Label variant={'body1'} weight={'semiBold'} pt={8} textAlign={'center'}>
					{props.name}
				</Label>
				<Box className={'statusTimeContainer'}>
					<StatusChip status={convertStatusType()} />
					<Label variant={'caption1'} weight={'regular'}>
						{props.timeStamp}
					</Label>
				</Box>
			</Box>
			{props.error && (
				<Label variant={'body1'} weight={'regular'} padding={8} whiteSpace={'pre-line'}>
					{getDataString(props.error)}
				</Label>
			)}
			{props.data && (
				<Label variant={'body1'} weight={'regular'} padding={8} whiteSpace={'pre-line'}>
					{getDataString(props.data)}
				</Label>
			)}
			{renderCommentData()}
			{renderOtherData()}
			{renderRawData()}
			{renderTestCriteria()}
			{!props.error && !props.data && !props.rawData && !props.testCriteria && !props.other?.length && (
				<Label variant={'body1'} weight={'regular'} padding={8} whiteSpace={'pre-line'}>
					No Data
				</Label>
			)}
		</Box>
	);
};
export default ResultItem;
