import { Box, Checkbox, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import * as React from 'react';
import { useState, useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import ServiceFactory from '../../../../services/serviceFactory';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { TestKey } from '../FlightTestSection';
import { ITestCriteria } from '../../../../utils/testCriteria';

interface MagCalibrationProps {
	testCriteria: ITestCriteria[];
}

enum FormKeys {
	MAG_CHECK = 'ranR1GCS',
	PIXHAWK_CHECK = 'ranPixhawk',
	POWER_CYCLE_CHECK = 'ranPowerCycle'
}

const MagCalibration: React.FC<MagCalibrationProps> = (props) => {
	const TEST_NAME = 'runMagCalibration';
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);

	const defineFormGroup = useCallback(() => {
		return new RsFormGroup([
			new RsFormControl<boolean>(FormKeys.MAG_CHECK, res ? res.data[FormKeys.MAG_CHECK] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			]),
			new RsFormControl<boolean>(FormKeys.PIXHAWK_CHECK, res ? res.data[FormKeys.PIXHAWK_CHECK] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			]),
			new RsFormControl<boolean>(FormKeys.POWER_CYCLE_CHECK, res ? res.data[FormKeys.POWER_CYCLE_CHECK] : false, [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return !!control.value;
				})
			])
		]);
	}, [res]);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(defineFormGroup());

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(
			formGroup,
			TEST_NAME as TestKey,
			undefined,
			undefined,
			props.testCriteria
		);
	}

	return (
		<Box className={'rsMagCalibration'}>
			<Checkbox
				labelText={'Run R1GCS magnetometer calibration'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.MAG_CHECK)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Run Pixhawk Calibration from R1GCS'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.PIXHAWK_CHECK)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Power cycle the vehicle'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.POWER_CYCLE_CHECK)}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default MagCalibration;
