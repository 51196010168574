import * as React from 'react';
import './TestSelectorSection.scss';
import { Box, Button, Label, RsFormControl, rsToastify } from '@redskytech/framework/ui';
import SelectableInputText from '../../../components/selectableInputText/SelectableInputText';
import { useState } from 'react';
import serviceFactory from '../../../services/serviceFactory';
import { Fixture, TestFixtureType } from '../../../services/testFixture/ITestFixtureService';
import { testFixtureTestMap } from '../../../services/testFixture/testFixture.data';
import cloneDeep from 'lodash.clonedeep';
import PageHeader from '../../../components/pageHeader/PageHeader';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import { PartAssemblyType } from '../../../services/assembly/IAssemblyService';
import { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';
import { partAssemblies } from '../../../services/assembly/assembly.data';

interface TestSelectorSectionProps {
	onTestFixtureSelected: (
		fixture: Fixture,
		simulation: boolean,
		assemblyHwid: HardwareIdDecoded,
		parentAssemblyHwid?: HardwareIdDecoded
	) => void;
}

const TestSelectorSection: React.FC<TestSelectorSectionProps> = (props) => {
	const [assemblyType, setAssemblyType] = useState<PartAssemblyType | undefined>();
	const [testFixtureType, setTestFixtureType] = useState<TestFixtureType | undefined>();
	const [assemblyIdControl, setAssemblyIdControl] = useState<RsFormControl<string>>(
		new RsFormControl('ASSEMBLY_HWID', '')
	);
	const [testFixtureIdControl, setTestFixtureIdControl] = useState<RsFormControl<string>>(
		new RsFormControl('TEST_HWID', '')
	);
	const [isAssemblySelected, setIsAssemblySelected] = useState<boolean>(true);
	const testerShimStatus = useRecoilValue(globalState.testerShimStatus);

	function areInputsValid(): boolean {
		if (!assemblyType || !testFixtureType) return false;

		if (
			assemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY' &&
			Object.prototype.hasOwnProperty.call(testFixtureTestMap['AIR_VEHICLE_ASSEMBLY'], testFixtureType)
		)
			return true;

		return !(
			!Object.prototype.hasOwnProperty.call(testFixtureTestMap, assemblyType) ||
			!Object.prototype.hasOwnProperty.call(testFixtureTestMap[assemblyType], testFixtureType)
		);
	}

	async function handleClick() {
		// Now go look up the assembly from the database
		// Here we need to go and look up into the database to see if this assembly has already been built
		const assemblyService = serviceFactory.get('AssemblyService');
		const hardwareIdDecoded = assemblyService.decodeHardwareId(assemblyIdControl.value);

		const testFixtureHardwareIdDecoded = assemblyService.decodeHardwareId(testFixtureIdControl.value);

		let simulationMode = false;
		if (!testFixtureHardwareIdDecoded) {
			rsToastify.error('Unable to decode testFixture Hardware ID.', 'Invalid Hardware ID');
			return;
		}

		simulationMode = testFixtureHardwareIdDecoded?.partNumber.endsWith('-SIM');

		if (!hardwareIdDecoded) {
			rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
			return;
		}

		const res = await assemblyService.lookupPartByNumbers(
			hardwareIdDecoded.partNumber,
			hardwareIdDecoded.serialNumber
		);
		if (!res) {
			rsToastify.error('Unable to find assembly in database.', 'Assembly Not Found');
			return;
		}

		// Now see if the shim is connected
		if (testerShimStatus.status !== 'CONNECTED_DATA') {
			rsToastify.error('Tester shim is not connected.', 'Tester Shim Not Connected');
			return;
		}

		// Check if there is a conflict with this assembly type, and the assemblyType is Military, if it is, then let's override the things before submission.
		const discoveredAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(hardwareIdDecoded.partNumber);
		if (!discoveredAssemblyType) {
			rsToastify.error('Unable to determine Part Type.', 'Invalid Part Type');
			return;
		}

		const fixture = cloneDeep(testFixtureTestMap[discoveredAssemblyType][testFixtureType!]);
		if (!fixture) {
			if (discoveredAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
				// Check if they selected an Air Vehicle Test Fixture
				const commercialAssemblyType = 'AIR_VEHICLE_ASSEMBLY';
				const commercialFixture = cloneDeep(testFixtureTestMap[commercialAssemblyType][testFixtureType!]);
				if (commercialFixture) {
					// this means that we found a commercial fixture on a military air vehicle, let's now operate on the air vehicle child part.
					const child = res.children.find((child) =>
						partAssemblies.AIR_VEHICLE_ASSEMBLY.partNumbers.includes(child.partNumber)
					);
					if (!child) {
						rsToastify.error(
							'Unable to adjust to Air Vehicle test fixture for this assembly. Assembly Incomplete.',
							'Invalid Test Fixture'
						);
						return;
					}
					const airChild = await assemblyService.lookupPartByNumbers(child.partNumber, child.serialNumber);
					if (!airChild) {
						rsToastify.error(
							'Unable to adjust to Air Vehicle test fixture for this assembly. Assembly Incomplete.',
							'Invalid Test Fixture'
						);
						return;
					}
					const commercialHwidDecoded: HardwareIdDecoded = {
						partNumber: airChild.partNumber,
						serialNumber: airChild.serialNumber,
						hardwareRevision: airChild.hardwareRev
					};
					rsToastify.info(
						'Redirecting to the correct Sub Assembly part Number automatically to allow vehicle bench testing.',
						'Air Vehicle Test Redirect'
					);

					commercialFixture.partId = airChild.id;
					props.onTestFixtureSelected(
						commercialFixture,
						simulationMode,
						commercialHwidDecoded,
						hardwareIdDecoded
					);
					return;
				} else {
					rsToastify.error(
						'Unable to find a compatible test fixture for this assembly.',
						'Invalid Test Fixture'
					);
				}
			}
		} else {
			fixture.partId = res.id;
			props.onTestFixtureSelected(fixture, simulationMode, hardwareIdDecoded);
		}
	}

	return (
		<Box className={'rsTestSelectorSection'}>
			<PageHeader title={'Test'} />
			<Box className={'content'}>
				<Label variant={'subheader1'} weight={'regular'} mb={16} textAlign={'center'}>
					Scan the hardware identifier of the <span style={{ fontWeight: 700 }}>assembly</span> you want to
					test.
				</Label>
				<SelectableInputText
					control={assemblyIdControl}
					updateControl={(update) => setAssemblyIdControl(update)}
					labelTitle={'Assembly'}
					isSelected={isAssemblySelected}
					onBlurOrEnter={async (value, _enterPressed) => {
						// Clearing out is ok
						if (!value) return 'VALID';

						const assemblyService = serviceFactory.get('AssemblyService');
						const hardwareIdDecoded = assemblyService.decodeHardwareId(value);
						if (!hardwareIdDecoded) {
							rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
							return 'Invalid Hardware ID';
						}

						const discoveredAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(
							hardwareIdDecoded.partNumber
						);

						if (!discoveredAssemblyType) {
							rsToastify.error('Unable to recognize this assembly.', 'Unknown Assembly Type');
							return 'Unknown Assembly Type';
						}

						setAssemblyType(discoveredAssemblyType);

						if (!testFixtureType) {
							setIsAssemblySelected(false);
							return 'VALID';
						}

						const fixture = testFixtureTestMap[discoveredAssemblyType][testFixtureType];
						if (!fixture) {
							// If we didn't find a fixture, and the currently selected assembly is a military air vehicle, let's also check if the test fixture is a commercial test fixture,
							if (discoveredAssemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
								// Check if they selected an Air Vehicle Test Fixture
								const commercialAssemblyType = 'AIR_VEHICLE_ASSEMBLY';
								const commercialFixture = testFixtureTestMap[commercialAssemblyType][testFixtureType];

								if (commercialFixture) {
									setIsAssemblySelected(false);
									return 'VALID';
								}
							}
						}

						if (!fixture) {
							rsToastify.error(
								'This assembly is not compatible with the selected test fixture.',
								'Incompatible Assembly Type'
							);
							return 'Invalid assembly for test fixture type';
						}

						setIsAssemblySelected(false);
						return 'VALID';
					}}
					onClick={() => setIsAssemblySelected(true)}
				/>
				<Label variant={'subheader1'} weight={'regular'} mb={16} mt={48} textAlign={'center'}>
					Scan the hardware identifier of the <span style={{ fontWeight: 700 }}>test fixture</span> you want
					to use.
				</Label>
				<SelectableInputText
					control={testFixtureIdControl}
					updateControl={(update) => setTestFixtureIdControl(update)}
					labelTitle={'Test Fixture'}
					isSelected={!isAssemblySelected}
					onBlurOrEnter={async (value, _enterPressed) => {
						// Clearing out is ok
						if (!value) return 'VALID';

						const assemblyService = serviceFactory.get('AssemblyService');
						const hardwareIdDecoded = assemblyService.decodeHardwareId(value);
						if (!hardwareIdDecoded) {
							rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
							return 'Invalid Hardware ID';
						}

						const testFixtureService = serviceFactory.get('TestFixtureService');
						const discoveredTestFixtureType = testFixtureService.getTestFixtureTypeFromPartNumber(
							hardwareIdDecoded.partNumber
						);

						if (!discoveredTestFixtureType) {
							rsToastify.error('Unable to recognize this test fixture.', 'Unknown Test Fixture Type');
							return 'Unknown Test Fixture Type';
						}

						setTestFixtureType(discoveredTestFixtureType);

						if (!assemblyType) {
							setIsAssemblySelected(true);
							return 'VALID';
						}

						const fixture = testFixtureTestMap[assemblyType][discoveredTestFixtureType];
						if (!fixture) {
							// If we didn't find a fixture, and the currently selected assembly is a military air vehicle, let's also check if the test fixture is a commercial test fixture,
							if (assemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
								// Check if they selected an Air Vehicle Test Fixture
								const commercialAssemblyType = 'AIR_VEHICLE_ASSEMBLY';
								const commercialFixture =
									testFixtureTestMap[commercialAssemblyType][discoveredTestFixtureType];

								if (commercialFixture) {
									setIsAssemblySelected(true);
									return 'VALID';
								}
							}
						}

						if (!fixture) {
							rsToastify.error(
								'Unable to recognize this test fixture for this assembly.',
								'Unknown Test Fixture Type'
							);
							return 'Invalid test fixture type for assembly';
						}

						setIsAssemblySelected(true);
						return 'VALID';
					}}
					onClick={() => setIsAssemblySelected(false)}
				/>
				<Button look={'containedPrimary'} disabled={!areInputsValid()} onClick={handleClick}>
					Start
				</Button>
			</Box>
		</Box>
	);
};

export default TestSelectorSection;
